import { COMPANY_MAIN_TYPE, OPERATORS } from '@/common/enums';
import { ICompanyRecord } from '@/common/types/company';
import { FilterModel } from '@/common/types/filterModel';
import { getCompanyDisplayName } from '@/common/utils/utils';
import { getCompanyBasicDetails } from '@/services/company';
import { Autocomplete, TextField } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';

const LocationFreeTextSelect = (props: any) => {
	const [options, setOptions] = useState<string[]>([]);

	const getValueCase = (value: string) => {
		if (props?.keepDefaultCase || !props?.convertValueToUpperCase) return value;
		return value?.toUpperCase();
	};
	
	const onInputChange = (value: string) => {
		const filters = new FilterModel([
			{
				property: 'CompanyNames.Name',
				value: value,
				operator: OPERATORS.CONTAINS,
				isCaseSensitive: false,
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.EQUAL,
				property: 'CompanyType.MainType',
				value: COMPANY_MAIN_TYPE.LOCATION,
			},
		]);

		getCompanyBasicDetails(filters).then((res) => {
			if (res?.status == HttpStatusCode.Ok) {
				const companyNames = res?.data?.records?.map((record: ICompanyRecord) =>
					getValueCase(getCompanyDisplayName(record?.companyNames))
				);
				setOptions(companyNames);
			}
		});
	};

	useEffect(() => {
		onInputChange('');
	}, []);

	return (
		<>
			<Autocomplete
				{...props}
				freeSolo
				aria-label={props?.name}
				options={options}
				onChange={(_, value) => {
					props?.onChange && props?.onChange(value);
				}}
				onInputChange={(_, value) => onInputChange(value)}
				renderInput={(params) => (
					<TextField
						{...params}
						onChange={(e) => {
							props?.onChange &&
								props?.onChange(getValueCase(e?.target?.value || ''));
						}}
						placeholder={!props?.isPrint ? 'Place of Engagement' : ''}
					/>
				)}
			/>
		</>
	);
};

export default LocationFreeTextSelect;
