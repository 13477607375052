import useNotification from '@/common/hooks/useNotification';
import { notificationMessageForFormOpenState } from '@/common/utils/utils';
import RenewalTerms from '@/modules/elements/components/tabs/renewalTerms/renewalTerms';
import RightsAcquiredPage from '@/modules/elements/components/tabs/RightsAcquired/RightsAcquiredPage';
import store, { IStore } from '@/store';
import { setFormName, setIsEditOpen } from '@/store/app/reducer';
import { useSelector } from 'react-redux';

interface Props {
	isRenewalSection: boolean;
}

const ContractUseRightTerritoryAndRenewal = (props: Props) => {
	const notification = useNotification();
	const appContext = useSelector((state: IStore) => state.appContext);

	// Helper functions
	const showEditSectionNotification = () => {
		notification.notify({
			type: 'error',
			message: notificationMessageForFormOpenState(appContext?.formName),
		});
	};

	const setIsEdit = (value: boolean, formAction = 'Add') => {
		if (!appContext?.isEditOpen || !value) {
			if (value) {
				store.dispatch(
					setFormName(
						formAction +
							' ' +
							`${
								props?.isRenewalSection ? 'Renewal Terms' : 'Rights Contracted'
							}`
					)
				);
			} else {
				store.dispatch(setFormName(''));
			}
			store.dispatch(setIsEditOpen(value));
		} else {
			showEditSectionNotification();
		}
	};

	return (
		<>
			{!props.isRenewalSection ? (
				<RightsAcquiredPage
					isRenewalSection={props.isRenewalSection}
					elementData={undefined}
					showNotification={showEditSectionNotification}
					onFormOpen={(formAction: string) => setIsEdit(true, formAction)}
					onFormClose={() => setIsEdit(false, 'Rights contracted')}
					isSectionVisible={!appContext?.isEditOpen}
					for='CONTRACT'
				/>
			) : (
				<RenewalTerms
					elementData={undefined}
					showNotification={showEditSectionNotification}
					onFormOpen={(formAction: string) => setIsEdit(true, formAction)}
					onFormClose={() => setIsEdit(false, 'Renewal terms')}
					isSectionVisible={!appContext?.isEditOpen}
					for='CONTRACT'
				/>
			)}
		</>
	);
};

export default ContractUseRightTerritoryAndRenewal;
