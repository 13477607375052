import { COMPANY_MAIN_TYPE, OPERATORS } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import useScrollToField from '@/common/hooks/useScrollToField';
import { IBaseContract, IPacketControlForm } from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import { getBaseUrl, getNotificationText, mutateContractAdditionalFormsData } from '@/common/utils/utils';
import { addMultipleContract } from '@/services/contract';
import { HttpStatusCode } from 'axios';
import { useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

/**
 * Custom hook `useContractMutation` for managing contract mutations and related state.
 *
 * @template TForm - The type of the contract form extending `IBaseContract`.
 *
 * @returns {Object} An object containing various utilities and state for contract mutations:
 * - `addMultipleContractDetails`: Function to mutate and add multiple contract details.
 * - `notification`: Notification utility for displaying messages.
 * - `scroll`: Function to scroll to a specific error field.
 * - `packetFormState`: State representing the packet form.
 * - `setPacketFormState`: Function to update the packet form state.
 * - `setParams`: Function to set search parameters.
 * - `params`: Current search parameters.
 * - `DEFAULT_COUNTRY_CODE`: Default country code constant.
 * - `AD_AGENCY_FILTER`: Filter options for ad agencies.
 * - `ADVERTISER_FILTER`: Filter options for advertisers.
 * - `PRODUCTION_COMPANY_FILTER`: Filter options for production companies.
 * - `handlePreContractSubmissionTasks`: Async function to handle pre-submission tasks for a contract form.
 * - `onSubmitError`: Function to handle form submission errors.
 */
const useContractMutation = <TForm extends IBaseContract>() => {
	// #region State variable & hooks.
	const notification = useNotification();
	const { scroll } = useScrollToField();
	const [packetFormState, setPacketFormState] = useState<IPacketControlForm>(
		{}
	);
	const [params, setParams] = useSearchParams();
	// #endregion

	// #region constants and other variables.
	const DEFAULT_COUNTRY_CODE = 'US';
	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: COMPANY_MAIN_TYPE.AD_AGENCY,
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: COMPANY_MAIN_TYPE.ADVERTISER,
		},
	];

	const PRODUCTION_COMPANY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: COMPANY_MAIN_TYPE.PRODUCTION_COMPANY,
		},
	];
	// #endregion

	// #region Helper functions.
	/**
	 * Handles pre-submission tasks for a contract form.
	 * 
	 * This function performs checks and updates the contract's form data.
	 * > It handles contract 'Packet forms' related logics and updates the final form data with updates packet Id's (ex: w4Form, w8BenShortForm, etc) using the `mutateContractAdditionalFormsData` API.
	 * 
	 * @param data - The form data to be processed and submitted.
	 * @param handleSubmit - A callback function get's processed final form `data` to handle the final submission.
	 *  It is used performer the contract specific data update after pre-processing main contract data.
	 * 
	 * @returns A promise that resolves when the pre-submission tasks are complete.
	 * 
	 * @throws Will notify with an error message if the mutation of additional form data fails.
	 */
	const handlePreContractSubmissionTasks = async (
		data: TForm,
		handleSubmit: (submitData: TForm) => void
	) => {
		if (!packetFormState?.isW4shortForm) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetFormState?.isW8BenShort) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetFormState?.isW9BenShort) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetFormState?.isMinorTrust) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetFormState?.isNYTheftPreventionForm) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetFormState?.isCATheftPreventionForm) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			handleSubmit(data);
		}
	};

	const onSubmitError = (errors: FieldErrors<TForm>) => {
		scroll(errors);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};
	// #endregion

	// #region API logics
	const { mutate: addMultipleContractDetails } = useMutation({
		mutationKey: ['contract-multi-add'],
		mutationFn: (payload: any) => addMultipleContract(payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				const baseURL = getBaseUrl(window.location.href);
				const contractIds: any = res?.data || [];
				const urls =
					contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

				urls.forEach((url) => {
					const a = document.createElement('a');
					a.href = url;
					a.target = '_blank';
					const evt = document.createEvent('MouseEvents');
					evt.initMouseEvent(
						'click',
						true,
						true,
						window,
						0,
						0,
						0,
						0,
						0,
						false,
						false,
						false,
						false,
						0,
						null
					);
					a.dispatchEvent(evt);
				});
				notification.notify({
					message: getNotificationText('Contract', 'CREATE'),
					type: 'success',
				});
			} else
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});
	// #endregion
	
	return {
		addMultipleContractDetails,
		notification,
		scroll,
		packetFormState,
		setPacketFormState,
		setParams,
		params,
		DEFAULT_COUNTRY_CODE,
		AD_AGENCY_FILTER,
		ADVERTISER_FILTER,
		PRODUCTION_COMPANY_FILTER,
		handlePreContractSubmissionTasks,
		onSubmitError,
	};
};

export default useContractMutation;
