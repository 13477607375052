import {
	LocalizationProvider,
	TimePicker,
	TimePickerProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styles from './timePicker.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props extends Omit<TimePickerProps<Dayjs>, 'onChange' | 'value'> {
	onChange?: CallableFunction;
	value?: Date | string;
}

const NabuTimePicker = (props: Props) => {
	const defaultDate = new Date();
	defaultDate.setUTCHours(0, 0, 0, 0);
	
	return (
		<>
			<div className={styles.timePicker}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<TimePicker
						ampm={false}
						{...props}
						value={props?.value ? dayjs(props.value) : undefined}
						defaultValue={props?.defaultValue ? dayjs(props?.defaultValue) : undefined}
						onChange={(event: Dayjs | null) =>{
							if (event?.toDate() && !isNaN(event?.toDate()?.getDate())){
								props?.onChange &&
						props.onChange(new Date(event?.toDate()?.toUTCString() || '').toISOString() as any);
							}
						}
						}
					/>
				</LocalizationProvider>
			</div>
		</>
	);
};

export default NabuTimePicker;
