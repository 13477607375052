import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { IBaseContract } from '@/common/types/contract';
import { FormControl, FormHelperText, Link } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import styles from '../forms/forms.module.scss';
import { CONTRACT_REF_ID } from '@/common/constants/constants';

interface Props {
	contractForm: UseFormReturn<IBaseContract | any, any, undefined>;
	isPrint?: boolean;
}

const ContractMinorTrustForm = (props: Props) => {
	const contractForm = props?.contractForm as UseFormReturn<IBaseContract, any, undefined>;

	return (
		<>
			<div id={CONTRACT_REF_ID.MINOR_TRUST_FORM} className={styles.contractFormBlock} style={{padding:'20px'}}>
				<div className={styles.bgWhite}>
					<h1 className={styles.nyFormTitle}>
          Minor Trust Information Form  </h1>
					<p>
						<b>
						Attach a true and accurate photocopy of any information received from the financial institution confirming 
the creation of the minor’s trust account. Contact <Link>minortrust@extremereach.com</Link> with any questions.
						</b>
					</p><br></br>

					<table className={styles.contractForms}  border={1} cellPadding={0} cellSpacing={0} width={'100%'} >
						<tbody>
							<tr style={{background:'#00b0b9'}}>
								<td colSpan={3} style={{color:'#FFF'}}>
                MINOR’S INFORMATION
								</td>

							</tr>
							<tr style={{background:'#ededed'}}>
								<td colSpan={3}>
									<b>Minor’s Full Legal Name
									</b>
								</td>
							</tr>
							<tr>
								<td >
									<p>First</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.firstName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.firstName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.firstName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td >
									<p>Middle</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.middleName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.middleName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.middleName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td >
									<p>Last</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.lastName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.lastName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.lastName?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={2} >
									<p>Social Security Number</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.ssn'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.ssn?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.ssn?.message
											}
										</FormHelperText>
									)}
								</td>
								<td >
									<p>Date of Birth</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.dateOfBirth'
											render={({ field }) => (
												<>
													<NabuDatepicker {...field} isDOB={true} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.dateOfBirth?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.dateOfBirth?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>

							<tr>
								<td>
								Minor lives in
								</td>
								<td colSpan={2}>
									<div className={styles.minorTrustSection}>
										<div className={styles.minorTrustInnerSection}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isLivesInCalifornia'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>California</span>
													</div>
												)}
											/>
										</div>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap',gap:'5px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isLivesInNewYork'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>New York</span>
													</div>
												)}
											/>
										</div>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap',gap:'5px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isLivesInNeither'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>Neither</span>
													</div>
												)}
											/>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td valign='middle'>
								Minor worked in
								</td>
								<td colSpan={2}>
									<div style={{display:'flex', gap:'10%', flexWrap:'wrap'}}>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap', gap:'5px', width:'150px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isWorkedInCalifornia'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>California</span>
													</div>
												)}
											/>
										</div>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap', gap:'5px', width:'150px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isWorkedInLouisiana'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>Louisiana</span>
													</div>
												)}
											/>
										</div>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap', gap:'5px', width:'150px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isWorkedInNewMexico'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>New Mexico</span>
													</div>
												)}
											/>
										</div>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap', gap:'5px', width:'150px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isWorkedInNewYork'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>New York</span>
													</div>
												)}
											/>
										</div>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap', gap:'5px', width:'150px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isWorkedInNorthCarolina'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>North Carolina</span>
													</div>
												)}
											/>
										</div>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap', gap:'5px', width:'150px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isWorkedInPennsylvania'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>Pennsylvania</span>
													</div>
												)}
											/>
										</div>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap', gap:'5px', width:'150px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isWorkedInTennessee'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>Tennessee</span>
													</div>
												)}
											/>
										</div>
										<div style={{display:'flex', alignItems:'center',flexWrap:'wrap', gap:'5px', width:'150px' }}>
											<Controller
												control={contractForm.control}
												name='contractMinorTrustForm.isWorkedInNoneOfThese'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>None of these</span>
													</div>
												)}
											/>
										</div>
									</div>
								</td>
							</tr>
							<tr style={{background:'#ededed'}}>
								<td colSpan={3}>
									<b>Trustee Full Legal Name</b>
								</td>
							</tr>
							<tr>
								<td >
									<p>First</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.trusteeLegalFirstName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.trusteeLegalFirstName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.trusteeLegalFirstName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td >
									<p>Middle</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.trusteeLegalMiddleName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.trusteeLegalMiddleName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.trusteeLegalMiddleName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td >
									<p>Last</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.trusteeLegalLastName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.trusteeLegalLastName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.trusteeLegalLastName?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<br></br>
					<table className={styles.contractForms}  border={1} cellPadding={0} cellSpacing={0} width={'100%'} >
						<tbody>
							<tr style={{background:'#00b0b9'}}>
								<td style={{color:'#FFF'}} colSpan={2}>
								FINANCIAL INSTITUTION 
								</td>

							</tr>
							<tr>
								<td>
									<p>Name(s) as listed on Account</p>
									
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.nameAsInAccount'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.nameAsInAccount?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.nameAsInAccount?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td>
									<p>Name of Financial Institution</p>
									
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.financialInstituteName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.financialInstituteName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.financialInstituteName?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td>
									<p>Financial Institution Address</p>
									
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.financialInstituteAddress'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.financialInstituteAddress?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.financialInstituteAddress?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td>
									<p>Account Number</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.accountNumber'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.accountNumber?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.accountNumber?.message
											}
										</FormHelperText>
									)}
								</td>
								<td>

									<p>Phone</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.phone'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint} type='number'/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractMinorTrustForm?.phone?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractMinorTrustForm?.phone?.message
											}
										</FormHelperText>
									)}

								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
										<Controller
											control={contractForm.control}
											name='contractMinorTrustForm.isInformationCorrect'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
													<span>I declare under penalty of perjury that the above information is true and correct.</span>
												</div>
											)}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p>Signature</p>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled={true}/> 
									</FormControl>
								</td>
								<td>
									<p>Date</p>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled={true}/> 
									</FormControl>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default ContractMinorTrustForm;
