import { httpClient } from './httpClient';

export const getAllTODOs = () => {
	return httpClient.post('/Dashboard/TODO/GetAll', {
		pageNumber: 0,
		sortField: 'Id',
		pageSize: 50,
		sortDescending: true,
		filters: [
		]
	});
};

export const addTODO = (payload: any) => {
	return httpClient.post('/Dashboard/TODO/Save', payload);
};

export const disableAlert = (payload: any) => {
	return httpClient.post('/Dashboard/Disable', payload);
};

export const sendEmail = (payload: any) => {
	const formData = new FormData();
	
	Object.keys(payload).forEach((key) => {
		if (key !== 'attachments' && payload[key] !== undefined) {
			formData.append(key, payload[key]);
		}
	});

	if (payload.attachments && payload.attachments.length > 0) {
		payload.attachments.forEach((attachment) => {
			formData.append('attachments', attachment);
		});
	}

	if (payload.contractIdList && payload.contractIdList.length > 0) {
		payload.contractIdList.forEach((contractId) => {
			formData.append('contractIds', contractId);
		});
	}

	if (payload.toMails && payload.toMails.length > 0) {
		payload.toMails.forEach((toMail) => {
			formData.append('toMails', toMail);
		});
	}
	
	return httpClient.postFormData('/Dashboard/SendEmail', formData);
};

export const sendEmailNotification = (payload: any) => {
	const formData = new FormData();
	formData.append('toEmail', payload.toEmail);
	formData.append('content', payload.content);

	if (payload.attachments && payload.attachments.length > 0) {
		for (let i = 0; i < payload.attachments.length; i++) {
			formData.append('attachments', payload.attachments[i]);
		}
	}
    
	if (payload.alertsEmailDataModels && payload.alertsEmailDataModels.length > 0) {
		for (let i = 0; i < payload.alertsEmailDataModels.length; i++) {
			const alertModel = payload.alertsEmailDataModels[i];
			Object.keys(alertModel).forEach((key) => {
				if (key !== 'attachments' && alertModel[key] !== undefined) {
					formData.append(`alertsEmailDataModels[${i}].${key}`, alertModel[key]);
				}
				payload.attachments.forEach(attachment => {
					formData.append(`alertsEmailDataModels[${i}].attachments`, attachment);
				});
			});
		}
	}

	if (payload?.toMails && payload?.toMails?.length > 0) {
		payload.toMails.forEach((toMail) => {
			formData.append('toMails', toMail);
		});
	}
	
	return httpClient.postFormData('/Dashboard/SendEmailNotification', formData);
};