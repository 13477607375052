import { PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import { PersonEmailModel, PersonNameModel, PersonSaveModel } from '@/modules/people/shared/models/person';
import * as schema from 'yup';

export const formSchema = schema.object<Partial<Record<keyof PersonSaveModel, schema.AnySchema>>>({
	personName: schema.object<Partial<Record<keyof PersonNameModel, schema.AnySchema>>>({
		firstName: schema.string().required('First Name is required'),
		// .matches(/^$|^[a-zA-Z\s]+$/, 'Invalid value'),
		middleName: schema.string(),
		lastName: schema.string()
	}),
	aliasName: schema.object<Partial<Record<keyof PersonNameModel, schema.AnySchema>>>({
		firstName: schema.string(),
		middleName: schema.string(),
		lastName: schema.string()
	}),
	ssn: schema.string().matches(/^(\d{3}-\d{2}-\d{4})?$/, 'Enter 9 Digits SSN in the format of XXX-XX-XXXX ')
		.test('is-nine-digits', 'SSN must be 9 characters long', function(value) {
			if (value && value.trim() !== '') {
				return value.replace(/-/g, '').length === 9;
			}
			return true;
		}),
	isMinor: schema.boolean().required('Required Field'),
	website: schema.string().url('Enter valid URL Ex: https://domain.com/rest'),
	personType: schema.array().test('array-validation', 'Person Type is required', function (value: any) {
		if(value?.length === 0) return false;
		return true;
	}),
	phoneNumbers: schema.array().of(schema.object<Partial<Record<keyof PhoneNumberFormModel, schema.AnySchema>>>({
		countryCode: schema.string(),
		number: schema.string().matches(/^$|^(?![[\]\- ]*$)[()\d\- ]{10}$/,'Invalid value'),
		extension: schema.string().matches(/^$|^[0-9]+$/,'Invalid value')
	})),
	emails: schema.array().of(schema.object<Partial<Record<keyof PersonEmailModel, schema.AnySchema>>>({
		emailId: schema.string().email('Invalid Email'),
		emailLabel: schema.string()
	}))
});

export const personNameSchema = schema.object<Partial<Record<keyof PersonSaveModel, schema.AnySchema>>>({
	personName: schema.object<Partial<Record<keyof PersonNameModel, schema.AnySchema>>>({
		firstName: schema.string().required('Required Field'),
		lastName: schema.string().required('Required Field')
	})
});

export const userSchema = schema.object({
	userName: schema.string().required('Username is required').min(8, 'User name must be at least 8 characters'),
	password: schema.string().required('Password is required').matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,}$/,'Password must be 8 characters or more and include at least one uppercase letter, one lowercase letter, one number, and one special character.'),
});