import NabuDialog from '@/common/components/molecules/dialog/dialog';
import { Box, Button, FormControl, FormHelperText, Grid } from '@mui/material';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { IContractTalentRecord } from '@/common/types/contract';
import { yupResolver } from '@hookform/resolvers/yup';
import LookAheadSelect, { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { DeleteForever, Save } from '@mui/icons-material';
import NabuButton from '@/common/components/atoms/button/button';
import { TalentDetailsFormSchema } from './contarctFormSchema';
import { getByWithFullNameSearch, getPersonAgentDetails } from '@/services/person';
import { IFilterOption } from '@/common/types/filterModel';
import { OPERATORS } from '@/common/enums';
import { IPersonRecord } from '@/services/models/person';
import { getCompanyDisplayName, getPersonFullName } from '@/common/utils/utils';
import { PerformerCategoryOptions } from '@/common/constants/constants';

interface IProps {
	noOfTalentsSelected: boolean;
	setNoOfTalentsSelected: (value: boolean) => void;
	handleTalentDetailsForm: (value: any) => void;
	performerCategory?: ILookAheadSelectOption[];
	isUnion?:boolean;
}

const TalentDetailsForm = (props: IProps) => {
	const {noOfTalentsSelected, setNoOfTalentsSelected, handleTalentDetailsForm} = props;

	//TalentDetailsForm
	const talentDetailsForm = useForm<{talentRecords: IContractTalentRecord[]}>({
		defaultValues: { talentRecords: [
			{ agent: { value: '', label: ''}, perfCategory: '', compensation: '', role: '', talentName: { value: '', label: ''}},
			{ agent: { value: '', label: ''}, perfCategory: '', compensation: '', role: '', talentName: { value: '', label: ''}},
			{ agent: { value: '', label: ''}, perfCategory: '', compensation: '', role: '', talentName: { value: '', label: ''}},
		] },
		resolver: yupResolver(TalentDetailsFormSchema),
		mode: 'onChange',
	});

	const talentDetailsArray = useFieldArray({
		control: talentDetailsForm.control,
		name: 'talentRecords',
	});

	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const getSelectedTalentDetails = (selectedTalent: {value: string, label: string}, index : number) => {
		getPersonAgentDetails(selectedTalent?.value).then((response) => {
			talentDetailsForm.setValue(`talentRecords.${index}.agent`, {
				value: response?.data?.agentInformation[0]?.talentAgency?.id, 
				label: getCompanyDisplayName(response?.data?.agentInformation[0]?.talentAgency?.companyNames)});
		}).catch((error) => {
			console.error('error is:', error);
		});
	};

	const companyOptionGenerator = (responseData: any) => {
		const agentInformation = responseData?.agentInformation;
		return agentInformation?.map((d) => ({
			value: d?.talentAgency?.id,
			label: getCompanyDisplayName(d?.talentAgency?.companyNames),
		}));
	};

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const getPerformerAdAgencyFilter = (): IFilterOption[] => {
		return [
			...AD_AGENCY_FILTER,
		];
	};

	const handleTalentDetailsFormSubmit = (data) => {
		handleTalentDetailsForm(data);
	};

	const handleTalentDetailsFormClose = () => {
		setNoOfTalentsSelected(false);
		talentDetailsForm.reset();
	};
  
	return(
		<NabuDialog
			open={noOfTalentsSelected}
			handleClose={() => setNoOfTalentsSelected(false)}
			maxWidth='xl'
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			title='Enter Talent Details' 
			dialogActions={[]}
			fullWidth
		>
			<Grid item md={12}>
				<form onSubmit={talentDetailsForm.handleSubmit(handleTalentDetailsFormSubmit)}>
					<div className={styles.multiContract}>
						<div>
							<table width={'100%'} border={1} cellPadding={0} cellSpacing={0}>
								<thead>
									<tr style={{ background: '#808080' }}>
										<th align='left' style={{ paddingLeft: '6px' }}>Talent Name</th>
										{(props?.isUnion) && (<th align='left' style={{ paddingLeft: '6px' }}>Perf Category</th>)}
										<th align='left' style={{ paddingLeft: '6px' }}>Role</th>
										<th align='left' style={{ paddingLeft: '6px' }}>Agent</th>
										{(props?.isUnion) && (<th align='left' style={{ paddingLeft: '6px' }}>Compensation</th>)}
										<th></th>
									</tr>
								</thead>
								<tbody>
									{talentDetailsArray.fields.map((talentRecord, index) => (
										<tr key={index}>
											<td>
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={talentDetailsForm.control}
														name={`talentRecords.${index}.talentName`}
														render={({ field }) => (
															<LookAheadSelect
																data-cy='name_select'
																{...field}
																key={`talentName${index}`}
																placeholder=''
																filterProperty='PersonName.FirstName'
																APICaller={(
																	filterModel,
																	searchText
																) =>
																	getByWithFullNameSearch(
																		filterModel,
																		searchText
																	)
																}
																defaultFilter={getPerformerFilter()}
																isSearchText={true}
																isClearable={true}
																dropDownsize={50}
																isDisabled={false}
																optionGenerator={
																	performerOptionGenerator
																}
																onChange={(e: any) =>{
																	field.onChange(e);
																	getSelectedTalentDetails(e, index);
																}
																}
																cacheOptions
																defaultOptions
															/>
														)}
													/>
													{talentDetailsForm?.formState?.errors?.talentRecords?.length && (
														<FormHelperText error={true}>
															{talentDetailsForm?.formState?.errors?.talentRecords?.[index]?.talentName?.value?.message}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											{(props?.isUnion) && (<td>
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={talentDetailsForm.control}
														name={`talentRecords.${index}.perfCategory`}
														render={({ field }) => (
															<NabuSelect
																{...field}
																options={props?.performerCategory || PerformerCategoryOptions}
																convertValueToUpperCase={false}
																placeholder='Select Performer Category'
															/>
														)}
													/>
													{talentDetailsForm?.formState?.errors?.talentRecords && (
														<FormHelperText error={true}>
															{talentDetailsForm?.formState?.errors?.talentRecords?.[index]?.perfCategory?.message}
														</FormHelperText>
													)}
												</FormControl>
											</td>)}
											<td>
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={talentDetailsForm.control}
														name={`talentRecords.${index}.role`}
														render={({ field }) => (
															<NabuTextbox type="text" {...field} placeholder={'Role'} />
														)}
													/>
													{talentDetailsForm?.formState?.errors?.talentRecords?.length && (
														<FormHelperText error={true}>
															{talentDetailsForm?.formState?.errors?.talentRecords?.[index]?.role?.message}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td>
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={talentDetailsForm.control}
														name={`talentRecords.${index}.agent`}
														render={({ field }) => (
															<>
																<LookAheadSelect
																	data-cy='ad_agency'
																	{...field}
																	placeholder={'Talent Agent'}
																	key={`agent-${talentDetailsForm.watch(`talentRecords.${index}.talentName`)?.value}`}
																	filterProperty='CompanyNames.Name'
																	isDisabled={!talentDetailsForm.watch(`talentRecords.${index}.talentName.value`)}
																	APICaller={() => getPersonAgentDetails(talentDetailsForm.watch(`talentRecords.${index}.talentName.value`) ? talentDetailsForm.watch(`talentRecords.${index}.talentName.value`) : false)}
																	optionGenerator={companyOptionGenerator}
																	// defaultFilter={getPerformerAdAgencyFilter()}
																	isClearable={true}
																	onChange={(e: any) => {
																		field.onChange(e);
																	}}
																	cacheOptions
																	defaultOptions
																/>
															</>
														)}
													/>
													{talentDetailsForm?.formState?.errors?.talentRecords?.length && (
														<FormHelperText error={true}>
															{talentDetailsForm?.formState?.errors?.talentRecords[index]?.agent?.value?.message}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											{(props?.isUnion) && (<td>
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={talentDetailsForm.control}
														name={`talentRecords.${index}.compensation`}
														render={({ field }) => (
															<NabuSelect
																{...field}
																options={[
																	{ label: 'SCALE', value: 'SCALE' },
																	{
																		label: 'DOUBLE SCALE',
																		value: 'DOUBLE SCALE',
																	},
																]}
																convertValueToUpperCase={false}
																placeholder={'Compensation'}
															/>
														)}
													/>
													{talentDetailsForm?.formState?.errors?.talentRecords?.length && (
														<FormHelperText error={true}>
															{talentDetailsForm?.formState?.errors?.talentRecords?.[index]?.compensation?.message}
														</FormHelperText>
													)}
												</FormControl>
											</td>)}
											<td>
												<Button variant="text" color="error" onClick={() => talentDetailsArray.remove(index)}><DeleteForever color="error" /></Button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							<Box dir="rtl">
								<NabuButton variant='text' color='primary' onClick={() => talentDetailsArray.append({agent: {value: '', label: ''},
									perfCategory: '',
									compensation: '',
									role: '',
									talentName: { value: '', label: ''}})}>Talent + </NabuButton>
							</Box>
						</div>
					</div>
					<Grid xs={12} md={12} dir='rtl'>
						<NabuButton
							variant='outlined'
							data-cy='cancel_btn'
							shouldHandleConfirmAction={talentDetailsForm.formState.isDirty}
							onClick={handleTalentDetailsFormClose}
						>
						Cancel
						</NabuButton>{' '}
						&nbsp;
						<NabuButton
							variant='filled'
							type='submit'
							data-cy='save_btn'
						>
							Save
							<Save />
						</NabuButton>
					</Grid>
				</form>
			</Grid>
		</NabuDialog>
	);
};

export default TalentDetailsForm;