import React, { useEffect, useState } from 'react';
import {
	FormControl,
	Input,
	MenuItem,
	Select,
	Checkbox,
	ListItemText,
	FormHelperText,
} from '@mui/material';
import styles from './multiselect.module.scss';
import { IconButton } from '@mui/joy';
import { CloseRounded } from '@mui/icons-material';

const NabuMultiselect = (props: any) => {
	const [selectedItems, setSelectedItems] = useState(props.selectedItems ?? [] as any);
	const [searchValue, setSearchValue] = useState('');

	const handleChange = (event: any) => {
		if(props?.isAllOptionNeeded){
			const selectedOptions = props?.options.map(e => e.value);
			if(event.target.value.includes('all')){
				if(selectedOptions?.length != event?.target?.value?.length && (event?.target.value?.length != 1 && selectedItems.includes('all'))){
					const optionsExludeAll = event?.target?.value.filter(e => e != 'all') || [];
					setSelectedItems(optionsExludeAll);
					props.onChange(optionsExludeAll);
				}
				else{
					setSelectedItems(selectedOptions);
					props.onChange(selectedOptions);
				}
			}
			else{
				if(selectedItems.includes('all')){
					setSelectedItems([]);
					props.onChange([]);
				}
				else{
					if(selectedOptions?.length == event?.target?.value?.length+1){
						setSelectedItems(selectedOptions);
						props.onChange(selectedOptions);
					}
					else{
						setSelectedItems(event.target.value);
						props.onChange(event.target.value);
					}
				}
			}
		}
		else{
			const newValue = event?.target?.value?.filter(d => d?.toLowerCase() !== 'all');
			setSelectedItems(newValue);
			props.onChange(newValue);	
		}
	};

	useEffect(() => {
		setSelectedItems(props.selectedItems || []);
	}, [props.selectedItems]);

	useEffect(() => {
		if(props.dynamicOptions)
			setSelectedItems([]);
	}, [props.dynamicOptions, props.options]);

	const handleSearchChange = (event: any) => {
		setSearchValue(event.target.value);
	};

	const filteredOptions = props?.options?.filter((option: any) =>
		option.label.toLowerCase().includes(searchValue.toLowerCase())
	);

	const selectOptions = (props?.shouldOverrideDefaultSort ? props?.options : filteredOptions?.sort((a, b) => a?.label?.toString()?.localeCompare(b?.label?.toString()))) || [];
	
	const getValueCase = (value: string) => {
		if(props?.keepDefaultCase|| props?.convertValueToUpperCase === false)
			return value || '';
		return value?.toUpperCase() || '';
	};

	// NOTE: This is to select the first option by default. 
	// 'handleDefaultSelection' prop is a callback, that excepts the boolean value in return to select the 1st option on options loaded.
	// It provides the available 'options' as callbacks argument. You can also select using custom logic and return false or undefined to not select the first option by default.
	useEffect(() => {
		if(props?.handleDefaultSelection && props?.options && props?.options.length > 0) {
			const shouldSelectFirstOne = props?.handleDefaultSelection(props?.options) ?? false;
			if(shouldSelectFirstOne && props?.options[0]?.value) {
				props?.onChange && props.onChange([props?.options[0]?.value]);
			}
		}
	}, [props?.options]);

	useEffect(() => {
		if(selectedItems?.length === props?.options?.length-1){
			setSelectedItems(prev => {
				if(props?.isAllOptionNeeded) return [...prev, 'all'];
				return [...(prev?.filter(v => v?.toLowerCase() != 'all') || [])];
			});
		}
	}, []);	

	return (
		<FormControl fullWidth error={props.error} data-cy={props.automation_id}>
			{/* <FormLabel data-cy={`${props.label}`}>{props.label}</FormLabel> */}
			<Select className={styles.multiSelectWithSearch}
				multiple
				aria-label={props?.name}
				{...props}
				value={selectedItems || []}
				onChange={handleChange}
				input={<Input />}
				displayEmpty={true}
				placeholder={props.placeholder}
				renderValue={() => (
					<div>
						{props?.options?.filter((option: any) => {
							return selectedItems.filter((item: any) => item === getValueCase(option?.value || '')).length > 0;
						}).map((option: any) => option.label).join(', ') || props.placeholder}
					</div>
				)}
				{...(props?.isClearable && props?.value?.length && ({
					endAdornment:<IconButton
						size='sm'
						variant='soft'
						onMouseDown={(event) => {
							event.stopPropagation();
						}}
						onClick={() => {
							props?.onChange && props.onChange([]);
							props?.onClear && props.onClear();
						}}
					>
						<CloseRounded />
					</IconButton>
				}))}
			>
				{props.isSearchNeeded && <Input
					placeholder="Search..."
					value={searchValue || '' }
					onChange={handleSearchChange}
				/>}
				<MenuItem disabled value=''>{props.placeholder}</MenuItem>
				{selectOptions?.map((option: any) => (
					<MenuItem key={option?.value} value={getValueCase(option?.value || '')} data-cy={`${option?.value}_menuItem`}>
						<Checkbox data-cy={`${option?.value}_checkbox`} checked={option?.label && selectedItems?.indexOf(getValueCase(option?.value || '')) > -1} />
						<ListItemText data-cy={`${option?.label}_listItem`} primary={getValueCase(option?.label || '')} />
					</MenuItem>
				))}
			</Select>
			{props.error && <FormHelperText>{props.helperText}</FormHelperText>}
		</FormControl>
	);
};

export default NabuMultiselect;