import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import NabuDialog from '@/common/components/molecules/dialog/dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { Save } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { expiryDateFormSchema } from './contarctFormSchema';

interface IProps {
  handleExpiryDateForm: (value: any) => void;
  isOpen: boolean;
  handleFormClose: (value: boolean) => void;
  contractExpiryDate: Date | undefined | null;
}
const ExpiryDateForm = (props: IProps) => {
	const { handleExpiryDateForm, isOpen, handleFormClose } = props;

	const expiryDateForm = useForm<{expirationDate: Date | undefined | null}>({
		defaultValues: {
			expirationDate: props.contractExpiryDate
		},
		resolver: yupResolver(expiryDateFormSchema),
		mode: 'onChange'
	});

	const onSubmit = (data) => {
		handleExpiryDateForm(data);
	};

	const onClickFormClose = () => {
		handleFormClose(false);
		expiryDateForm.setValue('expirationDate', undefined);
	};

	return (
		<NabuDialog
			open={isOpen}
			handleClose={onClickFormClose}
			title='Enter Expiry Date'
			maxWidth='md'
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			dialogActions={[]}
		>
			<Grid item lg={12} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
				<FormControl fullWidth>
					<FormLabel>
								Expiry Date <sup />
					</FormLabel>
					<Controller
						control={expiryDateForm.control}
						name='expirationDate'
						defaultValue={props.contractExpiryDate}
						render={({ field }) => (
							<>
								<NabuDatepicker
									{...field}
									placeholder='Expiration Date'
									onChange={(e) => field.onChange(e)}
								/>
							</>
						)}
					/>
				</FormControl>
				<div key={`error_${expiryDateForm.watch('expirationDate')}`}>
					{expiryDateForm?.formState?.errors?.expirationDate && (
						<FormHelperText error={true}>
							{expiryDateForm?.formState?.errors?.expirationDate?.message}
						</FormHelperText>
					)}
				</div>
			</Grid>
			<Grid item lg={12} dir='rtl'>
				<div >
					<NabuButton shouldHandleConfirmAction={expiryDateForm.formState.isDirty} variant='outlined' onClick={onClickFormClose}>Cancel</NabuButton>
						&nbsp;
					<NabuButton variant='filled' onClick={expiryDateForm.handleSubmit(onSubmit)}>
						{'Save'}
						<Save />
					</NabuButton>
				</div>
			</Grid>
		</NabuDialog>
	);
};

export default ExpiryDateForm;