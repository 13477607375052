import { IBusinessTaxInfo, ICompanyRecord } from '@/common/types/company';
import { IAssetSignatoryForm } from '@/modules/asset/components/tabs/AssetSignatory/assetSignatoryForm';
import { EmergencyContactModel } from '@/modules/people/shared/models/person';
import { IPersonRecord } from '@/services/models/person';

export class CompanyName {
	name: string = '';
	isDisplayName: string = '';
	nameType: string = '';
}

export class CompanyModel {
	id: string = '';
	is7MilesClient: boolean = false;
	isNabuClient: boolean = false;
	isUSCompany: boolean = false;
	isJPCAuthorizer: boolean = false;
	logo?: string;
	website: string = '';
	createdAt: string = '';
	createdBy: string = '';
	companyNames: CompanyName[] = [];
	constructor (input: CompanyModel) {
		Object.assign(this, input);
	}
}

export class AddressFormModel {
	id?: string;
	addressType: string = '';
	line1: string = '';
	line2: string = '';
	zipCode: string = '';
	city: string = '';
	country: string = 'US';
	state: string = '';
	addressName: string = '';
	isTemporary: boolean = false;
	phoneNumbers: PhoneNumberFormModel[] = [];
	effectiveDate: any;
	terminationDate: any;
	emails: EmailFormModel[] = [];
	isPrimary?: boolean = false;
	constructor(isPrimary: boolean = false) {
		this.isPrimary = isPrimary;
	}
}

export class AddressEntityModel {
	id?: string = '';
	addressType: string = '';
	line1: string = '';
	line2: string = '';
	zipCode: string = '';
	city: string = '';
	country: string = 'US';
	state: string = '';
	addressName: string = '';
	contacts: any[] = [];
	emergencyContacts: EmergencyContactModel[] = [];
	isTemporary: boolean = false;
	isPrimary?: boolean;
	phoneNumbers: PhoneNumberFormModel[] = [new PhoneNumberFormModel()];
	effectiveDate: any;
	terminationDate: any;
	emails: EmailFormModel[] = [new EmailFormModel()];
}

export class AddressModel {
	address: AddressFormModel[] = [new AddressFormModel()];
}

export interface IContactModel{
	contactType: string;
	contactSubType: string;
	effectiveDate: any;
	terminationDate: any;
	isPrimary: boolean;
	staff: any;
}

export class StaffModel{
	staffName: string = '';
	jobTitle: string = '';
	isFreeLancer: string = '';
	isPrimaryStaff: boolean = false;
	terminationDate: any;
	effectiveDate: any;
	status: string = '';
	address: AddressFormModel = new AddressFormModel();
}

export class ContactModel {
	contacts: ContactFormModel[] = [{...new ContactFormModel()}];
}

export class ContactFormModel {
	staffId: string = '';
	contactType: string = '';
	contactSubType: string[] = [];
	effectiveDate: any;
	terminationDate: any;
	isPrimary: boolean = false;
}

export class VendorModel {
	vendors: PackagingVendorModel[] = [{...new PackagingVendorModel()}];
}

export class PackagingVendorModel {
	id?: any;
	packagingVendorName: string = '';
	name: any = {
		label: '',
		value: ''
	};
	companyId: string = '';
	addressId: any = undefined;
	contacts: any = undefined;
	description: string = '';
}

export class PackagingVendorViewModel {
	companyName: string = '';
	address: any;
	contact: any;
	description: any;
}

export class DivisionModel {
	isNewDivision: boolean = false;
	divisionName: string = '';
	id: any = null;
}

export class BrandModel {
	isNewBrand: boolean = false;
	brandName: string = '';
	id: any = null;
}

export class ProductModel {
	products: ProductFormModel[] = [{...new ProductFormModel()}];
}

export class ProductFormModel {
	productName: string = '';
	exclusivityCategories: string[] = [];
	isNewBrand: boolean = false;
	isNewDivision: boolean = false;
	packagingRequirements: string = '';
	divisionId: any = undefined;
	brandId: any = undefined;
	vendors: PackagingVendorModel[] = [];
	brandName: string = '';
	divisionName: string = '';
}

export class ContactViewModel {
	staffName: string = '';
	emailId: string = '';
	phoneNumber: string = '';
	contactType: string = '';
	contactSubType: string = '';
	effectiveDate: any;
	terminationDate: any;
	isPrimary: boolean = false;
}

export class PhoneNumberFormModel {
	countryCode?: string = '1';
	number?: string = '';
	extension?: string = '';
	phoneNumberLabel: string = '';
	isPrimary?: boolean = true;
}

export class EmailFormModel {
	emailId?: string = '';
	emailLabel?: string = '';
	isPrimary?: boolean = true;
}

export interface ICompanyTypeModel {
	id: string;
	mainType: string;
	subType: any[];
	loanoutId?:string;
}

export class CompanyProfileModel {
	addresses: any[] = [];
	companyNames: any[] = [];
	companyType?: ICompanyTypeModel;
	createdAt: string = '';
	createdBy: string = '';
	id: string = '';
	is7MilesClient: boolean = false;
	isJPCAuthorizer: boolean = false;
	isIp360Client: boolean = false;
	isUSCompany: boolean = false;
	logo: string = '';
	logoUrl: string = '';
	products: any[] = [];
	contacts: any[] = [];
	website: string = '';
	divisions?: { divisionName: string}[];
	brands?: { brandName: string }[];
	clientAdvertisers?: { advertiser: any, brands?: string[], advertiserData?: any }[];
	businessTaxInfo?: IBusinessTaxInfo;
	socialMedias?: any[] = [];
	signatories?: any[] = [];
	note?: string = '';
	internalNote?: string = '';
}

export class HierarchyFormModel {
	parentCompanyName: any = {
		parentCompany: '',
		id: null
	};
	division: any = {
		divisionName: '',
		id: null
	};
	brand: any = {
		brandName: '',
		id: null
	};
}

export interface ISocialMedia {
	id?: string;
    type: string;
    link?: string;
	createdBy?: string;
	createdAt?: string;
}

export interface ISignatoryRecord extends Omit<IAssetSignatoryForm, 'staffId' | 'vendorId' | 'contractYear'>{
	id?: string;
	name: string;
	unionName: string;
	unionAgreement: string;
	contractYear?: number | string;
	signatoryCode?: string;
	pensionStudioCode?: string;
	document?: string;
	documentName?: string;
	createdBy?: string;
	createdAt?: string;
	vendor: ICompanyRecord;
	company: ICompanyRecord;
	staffId?: string;
	vendorId?: string;
	addressData?: AddressFormModel;
	staffData?: IPersonRecord;
	rowIndex?: number;
}

export class SignatoryFormModel {
	id?: string = '';
	name: string = '';
	unionName: string = '';
	unionAgreement: string = '';
	contractYear?: number | string = '';
	signatoryCode?: string = '';
	pensionStudioCode?: string = '';
	document?: string = '';
	documentName?: string = '';
	createdBy?: string = '';
	createdAt?: string = '';
}
