import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import NabuDynamicInputDropdown from '@/common/components/atoms/dynamicInputDropdown/dynamicInputDropdown';
import LookAheadSelect, { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddPhoneNumberInline from '@/common/components/templates/pages/add/addPhoneNumberInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { CONTRACT_GUARDIAN, ContractStatus, ContractUnionType, DefaultPageSize, TeamSagAftraAudioCommercialsPerformerTypeOptions } from '@/common/constants/constants';
import { COMPANY_MAIN_TYPE, OPERATORS, UNION_TYPE } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useScrollToField from '@/common/hooks/useScrollToField';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import {
	IBaseContract,
	ICommercialDetail,
	IContractSagAftraSdAudTeamComForm, IPacketControlForm,
} from '@/common/types/contract';
import { FilterModel, FilterOption, IFilterOption } from '@/common/types/filterModel';
import {
	addressShortner,
	displayAddress,
	displayOnlyEmail,
	displayPhoneNumberWithoutLabel,
	getCompanyDisplayName,
	getLocaleDateTime,
	getNotificationText,
	getPersonFullName,
	sortEmailOrPhoneNumberForDropDown
} from '@/common/utils/utils';
import { EmailFormModel, PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getAllAssetForBasicDetail, getAllSignatory, retriveAssetProducts } from '@/services/asset';
import { addNewCompanyEmail, createCompanyAddressPhoneNumber, getAllAddressByCompany, getAllProducts, getBusinessTaxInfo, getCompanyBasicDetails } from '@/services/company';
import {
	contractAddTeamSagAftraSdAudCom,
	updateContractTeamSagAftraSdAudCom
} from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import { GetByWithFullNameSearchForBasicDetail, createPersonEmail, createPersonPhoneNumber, getAddressBasedOnPinCode, getAgentInfo, getAllAddressByPerson } from '@/services/person';
import store from '@/store';
import { setDisableLoader } from '@/store/app/reducer';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, DeleteForever, HelpOutline } from '@mui/icons-material';
import {
	Box,
	FormControl, FormHelperText,
	Grid,
	Tooltip
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import TeamCompanies from '../../forms/img/teamCompanies.png';
import { StandardTeamSagAftraSdAudComSchema } from '../contarctFormSchema';
import ContractMutationWrapper from '../contractMutationWrapper/contractMutationWrapper';
import useContractMutation from '../contractMutationWrapper/useContractMutation';
import LocationFreeTextSelect from './locationFreeTextSelect';
import { IContractFormState } from './standardSagAftraEmployment2022';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	defaultValue?: IBaseContract,
	initialValue?: IContractSagAftraSdAudTeamComForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	companies?: ICompanyRecord[];
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?: IAssetRecord[];
}

const TeamACSAudioContract = (props: Props) => {
	const { 
		handlePreContractSubmissionTasks,
		addMultipleContractDetails, 
		notification, 
		setPacketFormState, 
		params, 
		setParams, 
		onSubmitError ,
		ADVERTISER_FILTER, 
		AD_AGENCY_FILTER, 
		DEFAULT_COUNTRY_CODE, 
	} = useContractMutation<IContractSagAftraSdAudTeamComForm>();



	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);


	const contractForm = useForm<IContractSagAftraSdAudTeamComForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}],
			}
			: {
				...(props?.initialValue || {}),
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials : [{}], 
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
			},
		resolver: yupResolver(StandardTeamSagAftraSdAudComSchema),
		shouldFocusError: false
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const personIdContract = contractForm.watch('performerId.value') || '';

	const [locTrigger] = useState<boolean>(false);

	const [state, setStateData] = useState({
		locFilterModel:	{
			filters: [
				{
					value: personIdContract,
					property: 'Person.Id',
					operator: OPERATORS.EQUAL,
					isCaseSensitive: false
				}
			]
		}
	});

	const [companyOptions, setCompanyOptions] = useState<ICompanyRecord[]>(props?.companies  || []);
	const [selectedAddress, setSelectedAddress] = useState<{id:any, type:any}>();
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(
		props?.assets || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});	
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});
	const [phoneNumberPopOver, setPhoneNumberInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const COMPENSATION_OPTIONS = [ 'SCALE', 'DOUBLE SCALE' ];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];

	const getLoanOutCorpFilter = (): IFilterOption[] => {
		const personIdContract = contractForm.watch('performerId.value') || '';
		const defaultFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.EQUAL,
				property: 'CompanyType.MainType',
				value: COMPANY_MAIN_TYPE.LOAN_OUT_CORPORATION?.toUpperCase(),
			}
		];

		defaultFilter.push({
			isCaseSensitive: false,
			operator: OPERATORS.IN,
			property: 'LoanoutPeople.Id',
			value: JSON.stringify([personIdContract]),
		});

		return [...defaultFilter];
	};

	// helper functions
	const getCommercialFilters = () => {
		const assetFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return assetFilter;
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};

			workDayFieldArray.append({
				...previousRow,
				date: addDays(new Date(previousRow?.date || ''), 1),
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};

	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	useEffect(() => {
		if(selectedAddress?.type !== ''){
			onAddressChange(selectedAddress?.id, selectedAddress?.type);
		}
	}, [addressOptions]);

	const getProducts = async () => {
		const commercials = contractForm.watch('commercials');
		let totalProducts: any = [];
		
		if(commercials){
			for(let i = 0; i < commercials.length; i++){
				const filterModel = new FilterModel([new FilterOption(commercials[i].assetId?.value ?? '', 'Asset.Id')], 'CreatedAt');
				
				const res: any = await retriveAssetProducts(filterModel);
		
				if(res?.data?.records){
					const products: any = res?.data?.records?.map(product => {
						return { value: product.id, label: product.productName };
					});
					
					totalProducts = [...totalProducts, ...products];
				}
			}

			contractForm.setValue('productId', totalProducts, { shouldDirty: true, shouldValidate: true });
		}
	};

	const onRemoveCommercial = (index: number) => {
		if (commercialFieldArray.fields.length > 1) {
			commercialFieldArray.remove(index);
			getProducts();
		}
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${packetControlForm.watch(key) ? 'Added to' : 'Removed from'} Packet.`,
			type: 'info',
		});
	};

	const onAddressChange = (addressId: string, field: 'performer' | 'adAgency' | 'performerAdAgency' | 'producer') => {
		setSelectedAddress({type: field, id: addressId});
		const address = addressOptions?.find( ad => ad?.id === addressId);
		if(address) {
			if(field !== 'adAgency')
				contractForm.setValue(`${field}Zip`, address?.zipCode, {shouldDirty: true});
			contractForm.setValue(`${field}State`, address?.state, {shouldDirty: true});
			contractForm.setValue(`${field}City`, address?.city?.toUpperCase(), {shouldDirty: true});
		} else {
			contractForm.setValue(`${field}State`, '', {shouldDirty: true});
			contractForm.setValue(`${field}City`, '', {shouldDirty: true});
			if(field !== 'adAgency')
				contractForm.setValue(`${field}Zip`, '', {shouldDirty: true});
		}
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const getAssetAdIdByAssetIndex = (index: number) => {
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.title || '';
	};

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase()
			})) || []
			: [];

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');
		const address = responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');
		
		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const referToOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');

		const options: ILookAheadSelectOption[] = [];
		responseData?.forEach((d: IPersonRecord) => {
			d?.emails?.forEach((e) => {
				!!e?.emailId?.trim() &&
					options.push({
						value: e?.id,
						label: `${getPersonFullName(d?.personName)}(${e?.emailId})` || '-',
					});
			});
		});
		return options;
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const performerAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};
	const performerAdAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};

	const producerAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.adId || '',
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const getUnions = (): any[] =>
		[UNION_TYPE.AFM, UNION_TYPE.SAG_AFTRA].map((value) => ({
			label: value,
			value: value,
		})) || [];

	const getUnionAgreement = (unionType: string): any[] =>
		ContractUnionType[unionType]?.agreements?.map((op) => ({
			label: op?.toUpperCase(),
			value: op?.toUpperCase(),
		})) || [];

	const onZipCodeChange = (zipCode: string,field: 'performer' | 'performerAdAgency' | 'producer' | 'service') => {
		if (zipCode) {
			store.dispatch(setDisableLoader(true));
			getAddressBasedOnPinCode(zipCode)
				.then((res) => {
					if (res?.data?.['country abbreviation'] === 'US') {
						if(res.data){
							const place = res.data.places[0];
							contractForm.setValue(`${field}State`, place['state abbreviation'], { shouldDirty: true });
							contractForm.setValue(`${field}City`, place['place name']?.toUpperCase() ?? '', { shouldDirty: true });
						}
					}
				})
				.catch((e) => console.error(e?.message))
				.finally(() => {
					store.dispatch(setDisableLoader(false));
				});
		}
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];

		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerPhoneNumberOptions = () => {
		const unsortedPhoneNumbers =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];		
		
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find(
				(a) => a?.id === addressId
			)?.emails || [];
		
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};
	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => contractAddTeamSagAftraSdAudCom(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave) {
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractTeamSagAftraSdAudCom(
					props?.contractId || '',
					payload
				),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave) {
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});
	
	useQuery({
		queryKey: ['company', 'loan-out-corp', contractForm.watch('loanOutId.value'), props?.isPrint],
		queryFn: () => getBusinessTaxInfo(contractForm.watch('loanOutId.value') || ''),
		refetchOnWindowFocus: false,
		enabled: !!contractForm.watch('loanOutId.value') && !props?.isPrint,
		onSuccess: (res: any) => {
			const ein = res?.data?.ein;
			
			!props?.isPrint && contractForm.setValue('fedId', ein, { shouldDirty: true});
				
		},
		onError: () => contractForm.setValue('fedId', '', { shouldDirty: true}),
	});

	const handleContractSubmission = async (
		submitData: IContractSagAftraSdAudTeamComForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		// NOTE: These linked form's handled separately because the payload size is huge.
		handlePreContractSubmissionTasks(submitData, (data) => {

			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const workDays = data?.workDays?.filter((d) => d?.date) || [];

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractTeamSagAftraSdAudCom: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				returnToEmail: data?.returnToEmail?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				productId: data?.productId?.map(product => product?.value),
				performerAddressId: data?.performerAddressId?.value,
				performerAdAgencyId: data?.performerAdAgencyId?.value,
				performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				producerCompanyAddressId: data?.producerCompanyAddressId?.value,
				loanOutId: data?.loanOutId?.value,
				commercials,
				workDays
			};
						
			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		});
	};

	
	const onSubmit = (data: IContractSagAftraSdAudTeamComForm) => {
		handleContractSubmission(data);
	};

	const [contractFormData, setContractFormData] = useState<IContractSagAftraSdAudTeamComForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractSagAftraSdAudTeamComForm) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partPlayed: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerType:talent?.perfCategory?.toUpperCase(),
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						performerAdAgencyAddressId:undefined,
						performerAdAgencyZip: undefined,
						performerAdAgencyState: undefined,
						performerAdAgencyCity: undefined,
						loanOutId:undefined,
						fedId:undefined,
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddTeamSagAftraSdAudComModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	useEffect(()=> {
		if(props?.isEdit && commercialFieldArray.fields.length === 0){
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const onAdAgencyAddressCreated = (createdAddress: ICompanyAddress) => {
		setCompanyOptions((prev) => {
			const company = companyOptions.find(
				(op) => op.id === contractForm.watch('adAgencyId.value')
			);

			!company?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				company?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === company?.id ? company : c));
		});
		contractForm.setValue('adAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: addressShortner(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
		onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
	};

	const PerformerAdAgencyAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerAdAgencyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAdAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const onProducerAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('producerCompanyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('producerCompanyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const closePhoneNumberInlinePopOver = () => {
		setPhoneNumberInlinePopOver({ isOpen: false });
	};

	const openPhoneNumberInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setPhoneNumberInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const onPerformerPhoneNumberInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonPhoneNumber({ personId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(pn => (pn?.id == contractForm.watch('performerId.value') ? ({
						...pn,
						phoneNumbers: [
							{ id: res?.data?.id, ...formData },
							...(pn?.phoneNumbers || {}) as any
						]
					}) : pn))
				));	
				
				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerPhoneId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	const onPhoneNumberInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('performerAdAgencyAddressId.value') || '';
		createCompanyAddressPhoneNumber({ addressId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();
				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('performerAdAgencyAddressId.value')
							? {
								...ad,
								phoneNumbers: [
									{ id: res?.data?.id, ...formData },
									...(ad?.phoneNumbers || {}),
								],
							}
							: ad
					)
				);
				contractForm.trigger('performerAdAgencyAddressId', { shouldFocus: true });
				contractForm.setValue('performerAdAgencyPhoneId', res.data.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const onProducerEmailInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('producerCompanyAddressId.value') || '';
		addNewCompanyEmail(addressId, {...formData, isPrimary: true}).then((res: any) =>{
			if(res?.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('producerCompanyAddressId.value')
							? {
								...ad,
								emails: [
									{ id: res?.data?.id, ...formData },
									...(ad?.emails || []),
								],
							}
							: ad
					)
				);
				contractForm.trigger('producerCompanyAddressId', { shouldFocus: true });
				contractForm.setValue('producerCompanyEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const handleCommercialChange = async (id: string) => {
		const filterModel = {
			pageSize: DefaultPageSize,
			sortDescending: false,
			sortField: 'CreatedAt',
			pageNumber: 0,
			filters: [{
				property: 'Asset.Id',
				value: id,
				operator: OPERATORS.EQUAL,
				isCaseSensitive: false
			}]
		};

		const res: any = await retriveAssetProducts(filterModel);

		if(res?.data?.records){
			const existingProducts: ILookAheadSelectOption[] = contractForm.getValues('productId') ?? [];
			let products: any = res?.data?.records?.map(product => {
				return { value: product.id, label: product.productName, assetId: id };
			});

			products = products.filter(product => {
				const foundProduct = existingProducts.find(eProduct => eProduct.value === product.value);
				if(foundProduct) return false;
				else return true;
			});

			contractForm.setValue('productId', [...products, ...existingProducts], { shouldDirty: true, shouldValidate: true });
		}
	};

	const handleProducerChange = (index ?: any)=>{
		if(index ==0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
		}	
		if(contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
		}
	};

	return (
		<>
			<ContractMutationWrapper
				isEdit={props?.isEdit}
				isPrint={props?.isPrint}
				contractForm={contractForm}
				contractFormState={contractFormState}
				setContractFormState={setContractFormState}
				handleFormClose={handleFormClose}
				handleSaveAndDuplicate={handleSaveAndDuplicate}
				handleTalentDetailsForm={handleTalentDetailsForm}
				isAddingContract={isAddingContract}
				isEditingContract={isEditingContract}
				onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}
				setPacketFormState={setPacketFormState}
				performerCategory={TeamSagAftraAudioCommercialsPerformerTypeOptions}
				isUnion={true}
			>
				{/* Main Contract Form Start */}
				<>
					<div className={styles.contractFormBlock}>
						<div className={styles.bgWhite} style={{padding:'20px'}}>
							<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
							<Grid item container columnSpacing={3}>
								<Grid item md={2}>
									<img src={TeamCompanies} width={'100%'}/>
								</Grid>
								<Grid item md={6} >
									<Box sx={{pt:4}}>
										<h2 style={{textAlign:'center', fontSize:'28px'}}>STANDARD SAG-AFTRA <br></br>EMPLOYMENT CONTRACT FOR</h2>
										<h4 style={{textAlign:'center'}}>AUDIO COMMERCIALS</h4>
									</Box>
								</Grid>
								<Grid item md={4}>
									<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
										<tr>
											<td>Date</td>
											<td><FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='producerDate'
													render={({ field }) => (
														<NabuDatepicker
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Date' : ''}
														/>
													)}
												/>
												{contractForm.formState?.errors
													?.producerDate?.message && (
													<FormHelperText
														data-cy='errorHelper_first_session_date'
														error={true}
													>
														{
															contractForm.formState?.errors
																?.producerDate.message
														}
													</FormHelperText>
												)}
											</FormControl></td>
										</tr>
										<tr>
											<td>Est. #</td>
											<td> <FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='est'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															data-cy='est_name_textbox'
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Est' : ''}
														/>
													)}
												/>
												{contractForm.formState.errors?.jobTitle
													?.message && (
													<FormHelperText
														data-cy='errorHelper-jobTitle'
														error={true}
													>
														{
															contractForm.formState?.errors?.jobTitle
																.message
														}
													</FormHelperText>
												)}
											</FormControl></td>
										</tr>
										<tr>
											<td>Job #</td>
											<td><FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='jobTitle'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															data-cy='job_name_textbox'
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Job' : ''}
														/>
													)}
												/>
												{contractForm.formState.errors?.jobTitle
													?.message && (
													<FormHelperText
														data-cy='errorHelper-jobTitle'
														error={true}
													>
														{
															contractForm.formState?.errors?.jobTitle
																.message
														}
													</FormHelperText>
												)}
											</FormControl>
											</td>
										</tr>
									</table>
									<div style={{float:'right'}}>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isJPCAuthorizer'
												render={({ field }) => (
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															key={`jpc-${contractForm.watch(
																'adAgencyId.value'
															)}-${contractForm.watch(
																'advertiserId.value'
															)}`}
															value={`${field.value}`}
															checked={field.value || false}
															onChange={undefined}
															type='checkbox'
															style={{
																marginTop: '3px',
															}}
														/>
														<span> JPC AUTHORIZER </span>
													</Box>
												)}
											/>
										</Box>
									</div>
								</Grid>
							</Grid>
							<hr></hr>

							<table width={'100%'} border={0} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td width={60}><span>Between</span> </td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<Controller
												key={`commercial-assetId
																	-${contractForm.watch(`commercials.${0}.assetId.value`)}
																	`}
												control={contractForm.control}
												name='producerCompanyId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															key={`commercial-assetId
																				-${contractForm.watch(`commercials.${0}.assetId.value`)}
																				`}
															{...field}
															placeholder={!props?.isPrint ? 'Producer' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
															optionGenerator={companyOptionGenerator}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('producerCompanyAddressId', null, { shouldDirty: true});
																contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true});
																contractForm.trigger('producerCompanyAddressId');
																contractForm.trigger('producerCompanyEmailId');
																onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');
															}}
															isDisabled={props?.isPrint}
															cacheOptions
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{/* {!props?.isPrint && (
																				<Box sx={{mt:0.5}}>
																					<AddCompanyInline
																						title='Company' />
																				</Box>
																			)} */}
													</>
												)}
											/>
											{contractForm.formState.errors?.producerCompanyId
												?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper-producerCompanyId'
												>
													{
														contractForm.formState?.errors
															?.producerCompanyId?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td width={100}>
										, <span>Producer{ !props?.isPrint && (
											<Tooltip
												placement='right-end'
												title={'Signatory'}
											>
												<HelpOutline fontSize='inherit' />
											</Tooltip>)
										}, and</span>
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='performerId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															data-cy='name_select'
															{...field}
															key={`performer-name
																									-${contractForm.watch('performerId.value')}
																									-${contractForm.watch('performerPhoneId')}-${contractForm.watch('performerEmailId')}`
															}
															isClearable={true}
															placeholder={!props?.isPrint ? 'Select Performer' : ''}
															filterProperty='PersonName.FirstName'
															APICaller={(
																filterModel,
																searchText
															) =>
																GetByWithFullNameSearchForBasicDetail(
																	filterModel,
																	searchText
																)
															}
															dropDownsize={50}
															isSearchText={true}
															isDisabled={props?.isPrint}
															optionGenerator={
																performerOptionGenerator
															}
															defaultFilter={getPerformerFilter()}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.trigger('performerEmailId');
																contractForm.setValue('performerEmailId', '', { shouldDirty: true});
																contractForm.setValue('performerPhoneId', '', { shouldDirty: true});
																contractForm.setValue('performerPrintName', contractForm.watch('performerId.label'),{ shouldDirty: true });
																contractForm.setValue('performerAddressId', null, { shouldDirty: true});
																contractForm.setValue('performerZip', '', { shouldDirty: true});
																contractForm.setValue('performerState', '', { shouldDirty: true});
																contractForm.setValue('performerCity', '', { shouldDirty: true});
																contractForm.setValue('loanOutId', null, { shouldDirty: true});
																contractForm.trigger('loanOutId');
																contractForm.setValue('performerAdAgencyId',null,{shouldDirty:true});
																contractForm.setValue('performerAdAgencyAddressId', null, { shouldDirty: true});
																contractForm.setValue('performerAdAgencyPhoneId', '', { shouldDirty: true});
																contractForm.setValue('performerAdAgencyZip', '', { shouldDirty: true});
																contractForm.setValue('performerAdAgencyState', '', { shouldDirty: true});
																contractForm.setValue('performerAdAgencyCity', '', { shouldDirty: true});

															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!props?.isPrint && (
															<Box sx={{marginBottom:'-22px'}}>
																<AddPersonInline
																	title='Person'
																	onPersonCreated={() => ({})}
																	initialValues={{personType: ['TALENT'], isMinor: false}}
																/>
															</Box>
														)}
																								
													</>
												)}
											/>
											{contractForm.formState?.errors?.performerId
												?.message && (
												<FormHelperText
													data-cy='errorHelper_first_session_date'
													error={true}
												>
													{
														contractForm.formState?.errors
															?.performerId?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<span>Performer.</span> 
									</td>
								</tr>
							</table>

							<br></br>

							<p><b>Producer engages Performer and Performer agrees to perform services for Producer in Audio Commercials as follows:</b></p>
							<br></br>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td width={150}>For Agency</td>
									<td><FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='adAgencyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														data-cy='ad_agency'
														{...field}
														placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
														filterProperty='CompanyNames.Name'
														APICaller={getCompanyBasicDetails}
														optionGenerator={
															companyOptionGenerator
														}
														defaultFilter={AD_AGENCY_FILTER}
														isClearable={true}
														isDisabled={props?.isPrint}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('adAgencyAddressId', null, { shouldDirty: true });
														}}
														cacheOptions
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{!props?.isPrint && (
														<Box sx={{mt:0.5}}>
															<AddCompanyInline
																title='Company'
																companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
														</Box>
													)}
																				
												</>
											)}
										/>
										{contractForm.formState.errors?.adAgencyId
											?.message && (
											<FormHelperText
												error={true}
												data-cy='errorHelper_ad_agency'
											>
												{
													contractForm.formState?.errors
														?.adAgencyId?.message
												}
											</FormHelperText>
										)}
									</FormControl> </td>
									<td width={130}>Engagement Date:</td>
									<td><FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='engagementDate'
											render={({ field }) => (
												<NabuDatepicker
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Date' : ''}
												/>
											)}
										/>
										{contractForm.formState?.errors
											?.engagementDate?.message && (
											<FormHelperText
												data-cy='errorHelper_first_session_date'
												error={true}
											>
												{
													contractForm.formState?.errors
														?.engagementDate.message
												}
											</FormHelperText>
										)}
									</FormControl> </td>
								</tr>
								<tr>
									<td style={{lineHeight:'10px'}}>
                Agency Address: 
										<span style={{fontSize:'10px'}}>(acting as agent for)</span>
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												key={`agency-address-cr-${contractForm.watch(
													'adAgencyId.value'
												)}`}
												name='adAgencyAddressId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															placeholder={!props?.isPrint ? 'Select Address' : ''}
															key={`agency-address-${contractForm.watch(
																'adAgencyId.value'
															)}`}
															filterProperty='Line1'
															sortField='IsPrimary'
															sortDescending={true}
															onOptionLoaded={(options) => {
																if(options?.length && !contractForm.watch('adAgencyAddressId.value')){
																	contractForm.setValue('adAgencyAddressId', options[0], { shouldDirty: true});
																	contractForm.trigger('adAgencyAddressId', { shouldFocus: true});																						
																}
															}}
															APICaller={(filterModel) =>
																getAllAddressByCompany(
																	filterModel,
																	contractForm.watch('adAgencyId.value') ||
																					''
																)
															}
															optionGenerator={
																adAgencyAddressOptionGenerator
															}
															isDisabled={
																!contractForm.watch('adAgencyId.value') ||
																			props?.isPrint
															}
															dropDownsize={50}
															isClearable={true}
															isSearchText={true}
															onChange={(e: any) => {
																field.onChange(e);
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{contractForm.watch('adAgencyId.value')&& (!props.isPrint) && <div style={{float:'right'}}>
															<Box sx = {{mt: 0.9}}>
																<AddCompanyAddressInline
																	companyId= {contractForm.watch('adAgencyId.value')}
																	title='ADDRESS'
																	isOpen = {addressPopOver?.isOpen && addressPopOver?.for =='AdAgency-address'}
																	event={addressPopOver?.event}
																	onAddressCreated={(d) => onAdAgencyAddressCreated(d)}
																	onClose={() => setAddressPopOver({isOpen: false})}
																	onPopOverOpen={(e) => setAddressPopOver({
																		isOpen: true,
																		event: e?.currentTarget,
																		for: 'AdAgency-address'
																	})}
																	isPersistent={true} />
															</Box>
														</div>}
													</>
												)}
											/>
											{contractForm.formState.errors?.adAgencyAddressId?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper_ad_agency'
												>
													{
														contractForm.formState?.errors
															?.adAgencyAddressId.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
                Place
									</td>
									<td style={{verticalAlign:'top'}}>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='placeOfEngagement'
												render={({ field }) => (
													<LocationFreeTextSelect
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Place of Engagement' : ''}
													/>
												)}
											/>
											{contractForm.formState.errors?.placeOfEngagement
												?.message && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors
															?.placeOfEngagement.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
								</tr>
								<tr>
									<td>
                Advertiser
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='advertiserId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															placeholder={!props?.isPrint ? 'Advertiser' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={companyOptionGenerator}
															defaultFilter={ADVERTISER_FILTER}
															isDisabled={props?.isPrint}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('commercials', [{} as any], { shouldDirty: true});
																contractForm.setValue(`commercials.${0}.assetId`,null,{shouldDirty:true});
																contractForm.setValue(`commercials.${0}.tags`,'',{shouldDirty:true});
																contractForm.setValue(`commercials.${0}.demos`,'',{shouldDirty:true});
																contractForm.setValue('productId', null, { shouldDirty: true});
																contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
																contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
																contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
																contractForm.setValue('producerCompanyPhoneId','',{shouldDirty:true});
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!props?.isPrint && (
															<Box sx={{mt:0.5}}>
																<AddCompanyInline
																	title='Company'
																	companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
															</Box>
														)}
													</>
												)}
											/>
											{contractForm.formState.errors?.advertiserId?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper_ad_agency'
												>
													{
														contractForm.formState?.errors?.advertiserId.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td colSpan={2}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<div>
                     Zip
												<FormControl fullWidth>
													<Controller
														control={contractForm.control}
														name='serviceZip'
														defaultValue=''
														render = {({field}) => (
															<NabuTextbox
																{...field}
																disabled={props?.isPrint}
																placeholder={!props?.isPrint ? 'Zip Code' : ''}
																onChange={(e) => {
																	field.onChange(e);
																	onZipCodeChange(
																		contractForm.watch('serviceZip') || '',
																		'service'
																	);
																}}
															/>	
														)}
													/>
																				
																				
												</FormControl>
											</div>
											<div>
                     City
												<FormControl fullWidth>
													<Controller
														control={contractForm.control}
														name='serviceCity'
														defaultValue=''
														render={({ field }) => (
															<NabuSelect
																{...field}
																disabled={props?.isPrint}
																options={getCityOptions(
																	contractForm.watch('serviceState')
																)}
																convertValueToUpperCase={true}
																placeholder={!props?.isPrint ? 'Select City' : ''}
															/>
														)}
													/>
													{contractForm.formState?.errors?.serviceCity
														?.message && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.serviceCity
																	.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</div>
										
										</div>
										<div>
                     State
											<FormControl fullWidth>
												<Controller
													control={contractForm.control}
													name='serviceState'
													defaultValue=''
													render={({ field }) => (
														<NabuSelect
															{...field}
															disabled={props?.isPrint}
															options={getStateOptions()}
															convertValueToUpperCase={false}
															placeholder={!props?.isPrint ? 'Select State' : ''}
														/>
													)}
												/>
												{contractForm.formState?.errors?.serviceState
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.serviceState
																.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</div>
									</td>
								</tr>
								<tr>
									<td>
                Product
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='productId'
												render={({ field }) => (
													<LookAheadSelect
														{...field}
														isMulti
														placeholder={!props?.isPrint ? 'Select Product' : ''}
														key={`product-${contractForm.watch(
															'advertiserId.value'
														)}`}
														filterProperty='ProductName'
														APICaller={(filterModel) =>
															getAllProducts(
																filterModel,
																contractForm.watch('advertiserId.value')
															)
														}
														optionGenerator={productOptionGenerator}
														isDisabled={
															!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
														}
														defaultFilter={PRODUCT_FILTER}
														dropDownsize={20}
														isClearable={true}
														onChange={(e: any) => {
															field.onChange(e);
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
												)}
											/>	
										</FormControl>
									</td>
									<td>
                Time From: 
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='timeFrom'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>
											{contractForm.formState?.errors
												?.timeFrom?.message && (
												<FormHelperText
													data-cy='errorHelper_first_session_date'
													error={true}
												>
													{
														contractForm.formState?.errors
															?.timeFrom.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
								</tr>
								<tr>
									<td>
                Compensation{ !props?.isPrint && (
											<Tooltip
												placement='right-end'
												title={'You can manually Enter the required option'}
											>
												<HelpOutline fontSize='inherit' />
											</Tooltip>)
										}
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='compensation'
												defaultValue='SCALE'
												render={({ field }) => (
													<NabuDynamicInputDropdown
														{...field}
														disabled={props?.isPrint}
														options={COMPENSATION_OPTIONS}
														placeholder={!props?.isPrint ? 'Compensation' : ''}
													/>
												)}
											/>
											{contractForm.formState.errors?.compensation
												?.message && (
												<FormHelperText
													data-cy='errorHelper-job-title-name'
													error={true}
												>
													{
														contractForm.formState?.errors?.compensation
															.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
                  Time To:
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='termTo'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>
											{contractForm.formState?.errors
												?.termTo?.message && (
												<FormHelperText
													data-cy='errorHelper_first_session_date'
													error={true}
												>
													{
														contractForm.formState?.errors
															?.termTo.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
								</tr>
								<tr>
									<td>
                Part Played
									</td>
									<td colSpan={3}>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='partPlayed'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														data-cy='job_name_textbox'
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Part Played' : ''}
													/>
												)}
											/>
											{contractForm.formState.errors?.partPlayed
												?.message && (
												<FormHelperText
													data-cy='errorHelper-job-title-name'
													error={true}
												>
													{
														contractForm.formState?.errors?.partPlayed
															.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
								</tr>
							</table>
							<br></br>
							<p><b>Employer of Record for income tax and unemployment insurance:</b> Talent Entertainment and Media Services, LLC dba <b>The Team Companies, LLC, 2300 Empire Avenue,5th Floor, Burbank, CA 91504</b></p>

							<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr style={{ background: '#808080' }}>
									<th>
                Ad-ID (s)<sup>*</sup>
									</th>
									<th>
                Title (s)
									</th>
									<th style={{width:'100px'}}>
                Tags
									</th>
									<th style={{width:'100px'}}>
                Demos
									</th>
									{!props?.isPrint && (
										<th style={{width:'80px'}}>
                  
										</th>
									)}
								</tr>
								{commercialFieldArray.fields.map((field, index) => (
									<tr key={`commercial-${field.id}`}>
										<td>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name={`commercials.${index}.assetId`}
													render={({ field }) => (
														<>
															<LookAheadSelect
																data-cy='source-asset'
																{...field}
																placeholder={!props?.isPrint ? 'Ad-ID' : ''}
																filterProperty='Title'
																isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
																defaultFilter={getCommercialFilters()}
																APICaller={(filterModel) => getAllAssetForBasicDetail( filterModel, false)}
																optionGenerator={
																	lookAheadAssetOptionGenerator
																}
																onChange={(e: any) => { 
																	field.onChange(e);
																	handleCommercialChange(e?.value);
																	handleProducerChange(index); 
																	contractForm.setValue(`commercials.${index}.tags`,'',{shouldDirty:true});
																	contractForm.setValue(`commercials.${index}.demos`,'',{shouldDirty:true});
																}}
																isClearable={true}
																cacheOptions
																defaultOptions
																dropDownsize={25}
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{!props?.isPrint && (
																<Box sx={{mt: 1}}>
																	<AddAssetInline
																		title='Asset' />
																</Box>
															)}
														</>
													)}
												/>
												{contractForm.formState?.errors?.commercials
													?.length && (
													<FormHelperText error={true}>
														{
															contractForm.formState.errors?.commercials[
																index
															]?.assetId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<NabuTextbox
													value={getAssetAdIdByAssetIndex(index)}
													disabled={true}
												/>
											</FormControl>
										</td>
										<td>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name={`commercials.${index}.tags`}
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															{...field}
															type='number'
															placeholder={!props?.isPrint ? 'Tags' : ''}
															disabled={props?.isPrint}
														/>
													)}
												/>
												{contractForm.formState?.errors?.commercials
													?.length && (
													<FormHelperText error={true}>
														{
															contractForm.formState.errors?.commercials[
																index
															]?.tags?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name={`commercials.${index}.demos`}
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															{...field}
															placeholder={!props?.isPrint ? 'Demos' : ''}
															disabled={props?.isPrint}
														/>
													)}
												/>
												{contractForm.formState?.errors?.commercials
													?.length && (
													<FormHelperText error={true}>
														{
															contractForm.formState.errors?.commercials[
																index
															]?.demos?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										{!props?.isPrint && (
											<>
												<td style={{ display: 'flex' }}>
													{commercialFieldArray.fields.length - 1 ===
																	index && (
														<NabuButton
															variant='text'
															onClick={onAddCommercial}
														>
															<Add />
														</NabuButton>
													)}
													{commercialFieldArray?.fields.length > 1 && (
														<NabuButton
															variant='text'
															onClick={() => {onRemoveCommercial(index);handleProducerChange(index);}}
														>
															<DeleteForever color='error' />
														</NabuButton>
													)}
												</td>
											</>
										)}
									</tr>
								))}
								<tr>
									<td>
                  Total number of Spots
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='totalSpots'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														{...field}
														placeholder={!props?.isPrint ? 'Total Spots' : ''}
														disabled={props?.isPrint}
													/>
												)}
											/>
										</FormControl> 
									</td>
									<td>
                Total number of Tags
										<FormControl fullWidth className={styles.tableForms}>
											<NabuTextbox
												disabled={true}
												key={contractForm
													.watch('commercials')
													?.map((d) => d?.tags)
													?.join('-')}
												value={contractForm
													.watch('commercials')
													?.reduce(
														(prev, cur) => {
															const tags = Number(cur?.tags);
															return prev + (isNaN(tags) ? 0 : tags);
														},
														0
													)}
											/>
										</FormControl> 
									</td>
									<td colSpan={props?.isPrint ? 2 : 3}>
                Total number of Demos
										<FormControl fullWidth className={styles.tableForms}>
											<NabuTextbox
												disabled={true}
												key={contractForm
													.watch('commercials')
													?.map((d) => d?.demos)
													?.join('-')}
												value={contractForm
													.watch('commercials')
													?.reduce(
														(prev, cur) => {
															const demos = Number(cur?.demos);
															return prev + (isNaN(demos) ? 0 : demos);
														},
														0
													)}
											/>
										</FormControl> 
									</td>
								</tr>
							</table>
							<br></br>
							<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td style={{ width: '60%' }}>
										<p><b><u>Classification</u></b></p>
										<Grid item container columnSpacing={3}>
											<Grid item md={4}>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('ANNOUNCER')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='ANNOUNCER'
																		checked={field.value === 'ANNOUNCER'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Announcer</span>
																</Box>
															</div>
														)}
													/>
												</Box>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('ACTOR')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='ACTOR'
																		checked={field.value === 'ACTOR'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Actor</span>
																</Box>
															</div>
														)}
													/>
												</Box>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('SINGER')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='SINGER'
																		checked={field.value === 'SINGER'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Singer</span>
																</Box>
															</div>
														)}
													/>
												</Box>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('GROUP_SPEAKER')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='GROUP_SPEAKER'
																		checked={field.value === 'GROUP_SPEAKER'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Group Speaker</span>
																</Box>
															</div>
														)}
													/>
												</Box>
											</Grid>
											<Grid item md={4}>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('SOLO_OR_DUO')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='SOLO_OR_DUO'
																		checked={field.value === 'SOLO_OR_DUO'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Solo or Duo</span>
																</Box>
															</div>
														)}
													/>
												</Box>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('GROUP_3_TO_5')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='GROUP_3_TO_5'
																		checked={field.value === 'GROUP_3_TO_5'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Group 3-5</span>
																</Box>
															</div>
														)}
													/>
												</Box>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('GROUP_6_TO_8')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='GROUP_6_TO_8'
																		checked={field.value === 'GROUP_6_TO_8'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Group 6-8</span>
																</Box>
															</div>
														)}
													/>
												</Box>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('GROUP_9_OR_MORE')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='GROUP_9_OR_MORE'
																		checked={field.value === 'GROUP_9_OR_MORE'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Group 9 or More</span>
																</Box>
															</div>
														)}
													/>
												</Box>
											</Grid>
											<Grid item md={4}>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('CONTRACTOR')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='CONTRACTOR'
																		checked={field.value === 'CONTRACTOR'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Contractor</span>
																</Box>
															</div>
														)}
													/>
												</Box>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint && field.onChange('SOUND_EFFECTS_PERFORMER')
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='SOUND_EFFECTS_PERFORMER'
																		checked={field.value === 'SOUND_EFFECTS_PERFORMER'}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Sound Effects Performer</span>
																</Box>
															</div>
														)}
													/>
												</Box>
												<Box display={'flex'} alignItems={'flex-start'}>
													<Controller
														control={contractForm.control}
														name='performerType'
														defaultValue=''
														render={({ field }) => (
															<div
																onClick={() =>
																	!props?.isPrint &&
                                field.onChange('FOREIGN_LANGUAGE_TRANSLATION_PERFORMED'
                                )
																}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value='FOREIGN_LANGUAGE_TRANSLATION_PERFORMED'
																		checked={
																			field.value ===
                                        'FOREIGN_LANGUAGE_TRANSLATION_PERFORMED'
																		}
																		onChange={(e) =>
																			field.onChange(e.target.value)
																		}
																		type='radio'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>
																						Foreign Language Translation  Performed
																	</span>
																</Box>
															</div>
														)}
													/>
												</Box>
											</Grid>
										</Grid>
									</td>
									<td style={{width:'40%'}}>
										<p><b><u>Check If Applicable</u></b></p>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='internetOnlyCommercials'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Internet Only Commercial(s)</span></Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='newMediaOnly'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> New Media Only Commercial(s) </span></Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='audioFlexPaymentPackage'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>  Audio Flex Payment Package</span></Box>
													</div>
												)}
											/>
																		
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isOther'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>  Other</span></Box>
													</div>
												)}
											/>
																		
										</Box>
										{contractForm.watch('isOther') && 
																	<FormControl fullWidth className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='otherValue'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					// placeholder={!props?.isPrint ? 'Total Spots' : ''}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																	</FormControl> }
									</td>
								</tr>
								<tr>
									<td>
										<p>
									This contract is subject to all of the above terms and conditions of the SAG-AFTRA Audio Recorded
Commercials Contract. The standard provisions printed on page 2 hereof are a part of this
contract. If this contract provides for compensation at the SAG-AFTRA minimum, no addition,
changes or alterations may be made in this form other than those which are more favorable to
the Performer than herein provided. If this contract provides for compensation above the SAG-
AFTRA minimum, additions may be agreed to between Producer and Performer which do not
conflict with the provisions of the SAG-AFTRA Audio Recorded Commercials Contract, provided
that such additional provisions are separately set forth under “Special Provisions” hereof and
signed by the Performer.
										</p>
									</td>
									<td style={{verticalAlign:'top'}}>
										<p><b><u>Singers</u></b></p>
										<p><b>Group</b> — Multiple Tracking &/or Sweetening?</p>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isSingersMultipleTrackingOccur'
												// defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(false)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value === false}
																onChange={() => field.onChange(false)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> No </span></Box>
													</div>
												)}
											/>
																		
											<Controller
												control={contractForm.control}
												name='isSingersMultipleTrackingOccur'
												// defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(true)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value === true}
																onChange={() => field.onChange(true)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Yes </span></Box>
													</div>
												)}
											/>
																		
										</Box>
										<p><b>Solo/Duo —</b> Multiple Tracking?</p>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isSingersSingleTrackingOccur'
												// defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(false)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value === false}
																onChange={() => field.onChange(false)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> No </span></Box>
													</div>
												)}
											/>
											<Controller
												control={contractForm.control}
												name='isSingersSingleTrackingOccur'
												// defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(true)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value === true}
																onChange={() => field.onChange(true)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Yes </span></Box>
													</div>
												)}
											/>
										</Box>
										<p><b>Solo/Duo —</b> Sweetening?</p>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='sweetening'
												// defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(false)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value === false}
																onChange={() => field.onChange(false)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> No </span></Box>
													</div>
												)}
											/>
											<Controller
												control={contractForm.control}
												name='sweetening'
												// defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(true)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value === true}
																onChange={() => field.onChange(true)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Yes </span></Box>
													</div>
												)}
											/>
										</Box>
										<p># of Tracks</p>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='hashOfTracks'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														{...field}
														// placeholder={!props?.isPrint ? 'Total Spots' : ''}
														disabled={props?.isPrint}
													/>
												)}
											/>
										</FormControl> 

									</td>
								</tr>
								<tr>
									<td>
								Performer does not consent to the use of his/her services in commercials made hereunder: 
									</td>
									<td>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='onInteretinMedia'
												// defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(false)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value === false}
																onChange={() => field.onChange(false)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> on the <b>Internet</b> </span></Box>
													</div>
												)}
											/>
																		
											<Controller
												control={contractForm.control}
												name='onInteretinMedia'
												// defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(true)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value === true}
																onChange={() => field.onChange(true)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> in <b>New Media</b></span></Box>
													</div>
												)}
											/>
																		
										</Box>
									</td>
								</tr>
								<tr>
									<td colSpan={2} style={{ background: '#515151', color: '#FFF' }}>
										<b>SPECIAL PROVISIONS</b> (including adjustments, if any, for Stunt Performers): 
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										{
											props?.isPrint ? (
												<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
											) : (<div className={styles.textareaSection}> 
												<Controller
													control={contractForm.control}
													name='specialProvisions'
													defaultValue=''
													render={({ field }) => (
														<NabuBaseTextArea
															className={styles.specialProvision}
															data-cy='job_name_textbox'
															{...field}
															rows={5}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
														/>
													)}
												/>
												{contractForm.formState.errors?.specialProvisions
													?.message && (
													<FormHelperText
														data-cy='errorHelper-job-title-name'
														error={true}
													>
														{
															contractForm.formState?.errors?.specialProvisions
																.message
														}
													</FormHelperText>
												)}
											</div> )
										}
									</td>
								</tr>
								<tr>
									<td>
									Performer acknowledges that he or she has read all the terms and conditions in the Special Provisions herein and hereby agrees thereto.
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<NabuTextbox disabled={true} placeholder="(Signature of Performer)"/>
										</FormControl>
									</td>
								</tr>
							</table>
							<br></br>
							<div className='keep-together'>
								<p>Until Performer shall otherwise direct in writing, Performer authorizes Producer to make all payments to which Performer may be entitled hereunder as follows</p>
            
								<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
									<tr>
										<td colSpan={3}>
											<Box display={'flex'} alignItems={'flex-start'}>
												<Controller
													control={contractForm.control}
													name='isPaymentAtPerformerAddress'
													// defaultValue={true}
													render={({ field }) => (
														<div
															onClick={() => {(!props?.isPrint) && field.onChange(!field.value);
																contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
															}}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value={field.value + ''}
																	checked={field.value}
																	onChange={(e) =>{ field.onChange(e);
																		contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
																	}}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>
																	<b>To Perfomer at W-4 Address:</b>
																</span></Box>
														</div>
													)}
												/>
											</Box>
										</td>
									</tr>
									<tr>
										<td width={350}>
															(Performer&apos;s Address):
											{contractForm.watch('isPaymentAtPerformerAddress') && <sup>*</sup>}
										</td>
										<td colSpan={2}>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													key={`select_Address_${contractForm.watch(
														'performerId.value'
													)}`}
													name='performerAddressId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																{...field}
																placeholder={!props?.isPrint ? 'Select Address' : ''}
																key={`performer-address-${contractForm.watch(
																	'performerId.value'
																)}`}
																filterProperty='Line1'
																sortField='IsPrimary'
																sortDescending={true}
																onOptionLoaded={(options) => {
																	if(options?.length && !contractForm.watch('performerAddressId.value')){
																		contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																		contractForm.trigger('performerAddressId', { shouldFocus: true});
																		onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');																					
																	}
																}}
																APICaller={(filterModel) =>
																	getAllAddressByPerson(
																		filterModel,
																		contractForm.watch(
																			'performerId.value'
																		) || ''
																	)
																}
																optionGenerator={
																	performerAddressOptionGenerator
																}
																dropDownsize={50}
																isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																isClearable={true}
																isSearchText={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	onAddressChange(contractForm.watch('performerAddressId.value'), 'performer');
																							
																}}
																cacheOptions={false}
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{																							
																<Box sx={{mt:1.3}}>
																	{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																							<AddPersonAddressInline
																								personId={contractForm.watch('performerId.value')}
																								title='ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onPersonAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false })}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'performer-address'
																								})}
																								isPersistent={true} />
																	}
																</Box>
															}
														</>
													)}
												/>
												{contractForm.formState?.errors?.performerAddressId?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState.errors
																?.performerAddressId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									</tr>
									<tr>
										<td>
										
																(ZIP) :
									
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													key={`select-performer-zip-${contractForm.watch('performerAddressId.value')}`}
													control={contractForm.control}
													name='performerZip'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															key={`select-performer-zip-${contractForm.watch('performerAddressId.value')}`}
															{...field}
															placeholder={!props?.isPrint ? 'ZIP' : ''}
															disabled={props?.isPrint}
															onChange={(e) => {
																field.onChange(e);
																onZipCodeChange(
																	contractForm.watch('performerZip') || '',
																	'performer'
																);
															}}
														/>
													)}
												/>
												{contractForm.formState.errors?.performerZip
													?.message && (
													<FormHelperText
														data-cy='errorHelper-performerZip'
														error={true}
													>
														{
															contractForm.formState?.errors?.performerZip
																.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td width={300}> 
									
																(State):
									
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													key={`select-performer-state-${contractForm.watch('performerState')}`}
													control={contractForm.control}
													name='performerState'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect
																key={`select-performer-state-${contractForm.watch('performerState')}`}
																{...field}
																disabled={props?.isPrint}
																options={getStateOptions()}
																convertValueToUpperCase={true}
																placeholder={!props?.isPrint ? 'Select State' : ''}
															/>
														</>
													)}
												/>
												{contractForm.formState?.errors?.performerState
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.performerState
																.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
										
																(City) :
									
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													key={`select-performer-city-${contractForm.watch('performerCity')}`}
													control={contractForm.control}
													name='performerCity'
													defaultValue=''
													render={({ field }) => (
														<NabuSelect
															key={`select-performer-city-${contractForm.watch('performerCity')}`}
															{...field}
															disabled={props?.isPrint}
															options={getCityOptions(
																contractForm.watch('performerState')
															)}
															convertValueToUpperCase={true}
															placeholder={!props?.isPrint ? 'Select City' : ''}
														/>
													)}
												/>
												{contractForm.formState?.errors?.performerCity
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.performerCity
																.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									
									</tr>
									<tr>
										<td>
															(Performer&apos;s Email Address)
		
											<FormControl
												className={styles.emaillowerCase}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													key={`select-phone-ctr${contractForm.watch(
														'performerEmailId'
													)}-${contractForm.watch(
														'performerId.value'
													)}`}
													name='performerEmailId'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect
																{...field}
																key={`select-phone-${contractForm.watch(
																	'performerEmailId'
																)}-${contractForm.watch(
																	'performerId.value'
																)}`}
																defaultCase={true}
																placeholder={!props?.isPrint ? 'Select Email' : ''}
																convertValueToUpperCase={false}
																enableEmptySelection={true}
																disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																options={getPerformerEmailsOptions()}
																handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
															/>
															{
																<Box>
																	{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																	}
																</Box>	
															}
														</>
													)}
												/>
												{contractForm.formState?.errors
													?.performerEmailId?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.performerEmailId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td width={150} colSpan={2}>(Performer&apos;s Phone)
									
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													key={`select-phone-ctr${contractForm.watch(
														'performerPhoneId'
													)}-${contractForm.watch(
														'performerId.value'
													)}`}
													name='performerPhoneId'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect
																{...field}
																key={`select-phone-${contractForm.watch(
																	'performerPhoneId'
																)}-${contractForm.watch(
																	'performerId.value'
																)}`}
																placeholder={!props?.isPrint ? 'Select Phone' : ''}
																convertValueToUpperCase={false}
																enableEmptySelection={true}
																disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																options={getPerformerPhoneNumberOptions()}
																handleDefaultSelection={() => !contractForm.watch('performerPhoneId')}
															/>
															{
																<Box>
																	{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerTelephone-address'|| false}	
																								onSubmit={onPerformerPhoneNumberInlineSubmit}
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'PerformerTelephone-address')}
																								event={phoneNumberPopOver?.event}	
																							/>
																	}
																</Box>	
															}
														</>
													)}
												/>
												{contractForm.formState?.errors
													?.performerPhoneId?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.performerPhoneId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									</tr>
									<tr>
										<td style={{ paddingTop: '9px' }} colSpan={3}>
										
											<Controller
												control={contractForm.control}
												name='isPaymentAtPerformerAgencyAddress'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() =>{ (!props?.isPrint) && field.onChange(!field.value);
															contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
														}}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => {field.onChange(e);
																	contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
																}}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																<b>
																					To Performer c/o:
																</b>
															</span></Box>
													</div>
												)}
											/>
										</td>
									</tr>
									<tr>
										<td>
															(Agent/Representative)
											{contractForm.watch('isPaymentAtPerformerAgencyAddress') && <sup>*</sup>}
									
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													name='performerAdAgencyId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																{...field} 
																key={`agency-${contractForm.watch('performerId.value')}`}
																placeholder='Agency'
																filterProperty='CompanyNames.Name'
																APICaller={(filter) => {
																	filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																	return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																}}
																optionGenerator={companyOptionGenerator}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('performerAdAgencyAddressId', null, { shouldDirty: true});
																	contractForm.setValue('performerAdAgencyPhoneId', '', { shouldDirty: true});
																	onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
																}}																						
																onOptionLoaded={(options) => {
																	if(options?.length && !contractForm.watch('performerAdAgencyId.value')){
																		contractForm.setValue('performerAdAgencyId', options[0], { shouldDirty: true});
																		contractForm.trigger('performerAdAgencyId', { shouldFocus: true});																						
																	}
																}}
																cacheOptions
																isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																defaultOptions
																dropDownsize={25}
																isClearable={true}
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{!props?.isPrint && contractForm.watch('performerId.value') && (<AddTalentAgentInline
																onCompanyCreated={(createdAgent) => contractForm.setValue('performerAdAgencyId', createdAgent, { shouldDirty: true})}
																onClose={() => setCompanyPopoverInline({ isOpen: false})}
																onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
																event={companyPopoverInline?.event}
																isOpen={companyPopoverInline.isOpen}
																personId={contractForm.watch('performerId.value') || ''}
															/>)}
														</>
													)}
												/>
												{contractForm.formState.errors?.performerAdAgencyId?.message && (
													<FormHelperText
														error={true}
														data-cy='errorHelper_ad_agency'
													>
														{
															contractForm.formState?.errors
																?.performerAdAgencyId.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
															(Agent&apos;s Address)
											{contractForm.watch('isPaymentAtPerformerAgencyAddress') && <sup>*</sup>}
									
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													key={`select_Address
																			-${contractForm.watch('performerAdAgencyId.value')}
																			-${contractForm.watch('performerAdAgencyPhoneId')}`
													}
													name='performerAdAgencyAddressId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																{...field}
																placeholder={!props?.isPrint ? 'Select Address' : ''}
																key={`performer-ad-address-${contractForm.watch(
																	'performerAdAgencyId.value'
																)}`}
																filterProperty='Line1'
																sortField='IsPrimary'
																sortDescending={true}
																onOptionLoaded={(options) => {
																	if(options?.length && !contractForm.watch('performerAdAgencyAddressId.value')){
																		contractForm.setValue('performerAdAgencyAddressId', options[0], { shouldDirty: true});
																		contractForm.trigger('performerAdAgencyAddressId', { shouldFocus: true});
																		onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');																							
																	}
																}}
																isDisabled={props?.isPrint}
																APICaller={(filterModel) =>
																	getAllAddressByCompany(
																		filterModel,
																		contractForm.watch(
																			'performerAdAgencyId.value'
																		) || ''
																	)
																}
																optionGenerator={
																	performerAdAgencyAddressOptionGenerator
																}
																dropDownsize={50}
																isClearable={true}
																isSearchText={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('performerAdAgencyPhoneId', '', { shouldDirty: true});
																	onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
																}}
																cacheOptions
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{
																<Box sx={{mt:1.3}}>
																	{(contractForm.watch('performerAdAgencyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('performerAdAgencyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'PerformerAdAgency-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => PerformerAdAgencyAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'PerformerAdAgency-address'
																								})}
																								isPersistent = {true} />
																	}
																</Box>
															}
														</>
													)}
												/>
												{contractForm.formState?.errors
													?.performerAdAgencyAddressId?.message && (
													<FormHelperText
														data-cy='errorHelper_?.addressId'
														error={true}
													>
														{
															contractForm.formState.errors
																?.performerAdAgencyAddressId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td style={{ width: '130px' }}>
															(Agent&apos;s Phone)
									
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													key={`select-phone-ctr${contractForm.watch(
														'performerAdAgencyPhoneId'
													)}-${contractForm.watch(
														'performerAdAgencyAddressId.value'
													)}`}
													name='performerAdAgencyPhoneId'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect
																{...field}
																key={`select-phone-${contractForm.watch(
																	'performerAdAgencyPhoneId'
																)}-${contractForm.watch(
																	'performerAdAgencyAddressId.value'
																)}`}
																placeholder={!props?.isPrint ? 'Select Phone' : ''}
																convertValueToUpperCase={false}
																enableEmptySelection={true}
																disabled={props?.isPrint || !contractForm.watch('performerAdAgencyAddressId.value')}
																options={getPhoneNumberOptions(
																	contractForm.watch(
																		'performerAdAgencyAddressId.value'
																	)
																)}
																handleDefaultSelection={() => !contractForm.watch('performerAdAgencyPhoneId')}
															/>
															{contractForm.watch('performerAdAgencyAddressId.value') &&
																		(!props?.isPrint) && (
																<AddPhoneNumberInline
																	formData={{ ...new PhoneNumberFormModel()}}
																	onSubmit={onPhoneNumberInlineSubmit}
																	onClose={closePhoneNumberInlinePopOver}
																	title='Phone Number'
																	onPopOverOpen={(e) =>openPhoneNumberInlinePopOver(e, 'PerformerAdAgency-Adress')}
																	event={phoneNumberPopOver?.event}
																	isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerAdAgency-Adress' || false}																		/>
															)}
														</>
													)}
												/>
												{contractForm.formState?.errors
													?.performerAdAgencyPhoneId?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.performerAdAgencyPhoneId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>														
									</tr>
									<tr>
										<td>(ZIP)
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													key={`select-performeradAgency-zip-${contractForm.watch('performerAdAgencyAddressId.value')}`}
													control={contractForm.control}
													name='performerAdAgencyZip'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															key={`select-performeradAgency-zip-${contractForm.watch('performerAdAgencyAddressId.value')}`}
															{...field}
															placeholder={!props?.isPrint ? 'ZIP' : ''}
															disabled={props?.isPrint}
															onChange={(e) => {
																field.onChange(e);
																onZipCodeChange(
																	contractForm.watch('performerAdAgencyZip') || '',
																	'performerAdAgency'
																);
															}}
														/>
													)}
												/>
												{contractForm.formState.errors?.performerAdAgencyZip
													?.message && (
													<FormHelperText
														data-cy='errorHelper-performerAdAgencyZip'
														error={true}
													>
														{
															contractForm.formState?.errors
																?.performerAdAgencyZip.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>(State)
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													name='performerAdAgencyState'
													defaultValue=''
													render={({ field }) => (
														<NabuSelect
															key={`select-performerAdAgency-state-${contractForm.watch('performerAdAgencyState')}`}
															{...field}
															disabled={props?.isPrint}
															options={getStateOptions()}
															convertValueToUpperCase={false}
															placeholder={!props?.isPrint ? 'Select State' : ''}
														/>
													)}
												/>
												{contractForm.formState?.errors
													?.performerAdAgencyState?.message && (
													<FormHelperText
														data-cy='errorHelper_location_State'
														error={true}
													>
														{
															contractForm.formState?.errors
																?.performerAdAgencyState.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>(City)
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													name='performerAdAgencyCity'
													defaultValue=''
													render={({ field }) => (
														<NabuSelect
															{...field}
															disabled={props?.isPrint}
															options={getCityOptions(
																contractForm.watch('performerAdAgencyState')
															)}
															convertValueToUpperCase={true}
															placeholder={!props?.isPrint ? 'Select City' : ''}
														/>
													)}
												/>
												{contractForm.formState?.errors
													?.performerAdAgencyCity?.message && (
													<FormHelperText
														data-cy='errorHelper_city'
														error={true}
													>
														{
															contractForm.formState?.errors
																?.performerAdAgencyCity.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									</tr>

								</table>
							</div>
							<br></br>
							<div className='keep-together'>
								<p><b>All notices to Producer shall be addressed as follows:</b></p>
								<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
									<tr>
										<td width={160}>
								PRODUCER{ !props?.isPrint && (
												<Tooltip
													placement='right-end'
													title={'Signatory'}
												>
													<HelpOutline fontSize='inherit' />
												</Tooltip>)
											}
										</td>
										<td>
											<FormControl fullWidth className={styles.tableForms}>
												<Controller
													key={`commercial-assetId
																			-${contractForm.watch(`commercials.${0}.assetId.value`)}
																			`}
													control={contractForm.control}
													name='producerCompanyId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																key={`commercial-assetId
																						-${contractForm.watch(`commercials.${0}.assetId.value`)}
																						`}
																{...field}
																placeholder={!props?.isPrint ? 'Producer' : ''}
																filterProperty='CompanyNames.Name'
																APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																optionGenerator={companyOptionGenerator}
																isClearable={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('producerCompanyAddressId', null, { shouldDirty: true});
																	contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true});
																	contractForm.trigger('producerCompanyAddressId');
																	contractForm.trigger('producerCompanyEmailId');
																	contractForm.setValue('producerPrintName','',{shouldDirty: true} );
																	onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');
																}}
																isDisabled={props?.isPrint}
																cacheOptions
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{/* {!props?.isPrint && (
																						<Box sx={{mt:0.5}}>
																							<AddCompanyInline
																								title='Company' />
																						</Box>
																					)} */}
														</>
													)}
												/>
												{contractForm.formState.errors?.producerCompanyId
													?.message && (
													<FormHelperText
														error={true}
														data-cy='errorHelper-producerCompanyId'
													>
														{
															contractForm.formState?.errors
																?.producerCompanyId.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td width={160}>
								Producer Address 
										</td>
										<td>
											<FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													key={`producer-address-cr-${contractForm.watch(
														'producerCompanyId.value'
													)}`}
													name='producerCompanyAddressId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																{...field}
																placeholder={!props?.isPrint ? 'Select Address' : ''}
																key={`producer-address-${contractForm.watch(
																	'producerCompanyId.value'
																)}-${contractForm.watch(
																	'producerCompanyEmailId'
																)}-${contractForm.watch(
																	'producerCompanyPhoneId'
																)}`}
																filterProperty='Line1'
																sortField='IsPrimary'
																sortDescending={true}
																onOptionLoaded={(options) => {
																	if(options?.length && !contractForm.watch('producerCompanyAddressId.value')){
																		contractForm.setValue('producerCompanyAddressId', options[0], { shouldDirty: true});
																		contractForm.trigger('producerCompanyAddressId', { shouldFocus: true});	
																		onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');																					
																	}
																}}
																APICaller={(filterModel) =>
																	getAllAddressByCompany(
																		filterModel,
																		contractForm.watch(
																			'producerCompanyId.value'
																		) || ''
																	)
																}
																optionGenerator={
																	producerAddressOptionGenerator
																}
																isDisabled={
																	!contractForm.watch(
																		'producerCompanyId.value'
																	) || props?.isPrint
																}
																dropDownsize={50}
																isClearable={true}
																isSearchText={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('producerCompanyPhoneId', '', { shouldDirty: true});
																	contractForm.trigger('producerCompanyPhoneId');
																	contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true});
																	contractForm.trigger('producerCompanyEmailId');
																	onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');
																}}
																cacheOptions={false}
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{
																<Box sx={{mt:1.3}}>
																	{(contractForm.watch('producerCompanyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('producerCompanyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'ProducerCompany-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onProducerAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'ProducerCompany-address'
																								})}
																								isPersistent = {true} />
																	}
																</Box>
															}
														</>
													)}
												/>
												{contractForm.formState.errors?.producerCompanyAddressId?.message && (
													<FormHelperText
														error={true}
														data-cy='errorHelper_producer'
													>
														{
															contractForm.formState?.errors
																?.producerCompanyAddressId.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									</tr>
									<tr>
										<td width={160}>
								Producer Email
										</td>
										<td>
											<FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='producerCompanyEmailId'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect
																{...field}
																key={`select-em-${contractForm.watch(
																	'producerCompanyAddressId.value'
																)}-${contractForm.watch('producerCompanyEmailId')}`}
																placeholder={!props?.isPrint ? 'Select Email' : ''}
																convertValueToUpperCase={false}
																enableEmptySelection={true}
																disabled={props?.isPrint || !contractForm.watch('producerCompanyAddressId.value')}
																options={getCompanyEmailOptions(contractForm.watch('producerCompanyAddressId.value'))}
																handleDefaultSelection={() => !contractForm.watch('producerCompanyEmailId')}
															/>
															{
																<Box sx={{mt:1.3}}>
																	{(contractForm.watch('producerCompanyAddressId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																							onSubmit={onProducerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																							event={emailPopOver?.event}
																						/>
																	}
																</Box>	
															}
														</>
													)}
												/>
												{contractForm.formState?.errors
													?.producerCompanyEmailId?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.producerCompanyEmailId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
								Producer (Print Name):
										</td>
										<td>
											<FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													key={`producerPrintName_${contractForm.watch('producerCompanyId.label')}`}
													name='producerPrintName'
													render={({ field }) => (
														<>
															<NabuTextbox
																{...field}
																placeholder={!props?.isPrint ? 'Performer' : ''}
																disabled={props?.isPrint}
																value={contractForm.watch('producerCompanyId.label')}
																onChange={(e: any) => {
																	field.onChange(e);

																}}
															/>
														</>
													)}
												/>
											</FormControl>
										</td>
									</tr>
									<tr>
										<td>
									SIGNED BY PRODUCER
										</td>
										<td colSpan={3}>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'350px'}}>
												<NabuTextbox disabled={true} placeholder="SIGNED BY PRODUCER"/>
											</FormControl>
										</td>
						
									</tr>
									<tr>
										<td colSpan={4}>The Performer has the right to consult with his/her
								representative or SAG-AFTRA before signing this contract</td>
									</tr>
									<tr>
										<td>
								Performer (Print Name)
										</td>
										<td>
											<FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='performerPrintName'
													render={({ field }) => (
														<>
															<NabuTextbox
																{...field}
																placeholder={!props?.isPrint ? 'Performer' : ''}
																disabled={props?.isPrint}
																value={contractForm.watch('performerId.label')}
															/>
														</>
													)}
												/>
												{contractForm.formState?.errors?.performerPrintName
													?.message && (
													<FormHelperText
														data-cy='errorHelper_?.addressId'
														error={true}
													>
														{
															contractForm.formState.errors
																?.performerPrintName?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
								SIGNED BY PERFORMER
										</td>
										<td>
											<FormControl fullWidth className={styles.tableForms}>
												<NabuTextbox disabled={true} placeholder="SIGNED BY PERFORMER"/>
											</FormControl>
										</td>
									</tr>

								</table>
							</div>
							<br></br>
							<div className='keep-together'>
								<p><b>MINORS ONLY</b></p>
								<p>Performer hereby certifies that he/she is 21 years of age or over (if under 21 years of age this contract must be signed here by a parent or guardian).</p>
								<br></br>
								<p>I, the undersigned, hereby state that I am the parent/guardian of the above named Performer and do hereby
						consent and give my permission to this agreement</p>

								<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
									<span>Signature of Parent/Guardian:</span>
									<FormControl className={styles.tableForms}>
										<NabuTextbox disabled={true} placeholder=""/>
									</FormControl>
									<Box display={'flex'} alignItems={'flex-start'}>
										<Controller
											control={contractForm.control}
											name='guardian'
											// defaultValue={CONTRACT_GUARDIAN.GUARDIAN}
											render={({ field }) => (
												<div 
													style={{ display: 'inline-flex', alignItems: 'center'}}
												>
													<div
														onClick={() => (!props?.isPrint) && field.onChange(CONTRACT_GUARDIAN.MOTHER)}
														style={{ userSelect: 'none', display: 'inline-flex' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.MOTHER}
																onChange={() => field.onChange(CONTRACT_GUARDIAN.MOTHER)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																{CONTRACT_GUARDIAN.MOTHER}
															</span></Box>
													</div>
													<div
														onClick={() => (!props?.isPrint) && field.onChange(CONTRACT_GUARDIAN.FATHER)}
														style={{ userSelect: 'none', display: 'inline-flex' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.FATHER}
																onChange={() => field.onChange(CONTRACT_GUARDIAN.FATHER)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																{CONTRACT_GUARDIAN.FATHER}
															</span></Box>
													</div>
													<div
														onClick={() => (!props?.isPrint) && field.onChange(CONTRACT_GUARDIAN.GUARDIAN)}
														style={{ userSelect: 'none', display: 'inline-flex' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.GUARDIAN}
																onChange={() => field.onChange(CONTRACT_GUARDIAN.GUARDIAN)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																{CONTRACT_GUARDIAN.GUARDIAN}
															</span></Box>
													</div>
												</div>
											)}
										/>
									</Box>

								</div>
							</div>
							<hr></hr>

							<br></br>
							<div className='keep-together'>
								<h3 style={{textAlign:'center'}}>TIME SHEET & STANDARD PROVISIONS</h3>
								<h5 style={{textAlign:'center'}}>HOURS EMPLOYED</h5>
								<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
									<tr style={{ background: '#808080' }}>
										<th style={{ textAlign: 'center' }} colSpan={2}>Date
											<div 	style={{ display: 'flex', justifyContent: 'space-around', }} >
												<span>From</span> <span>To</span>{' '}
											</div>
										</th>
										<th style={{ textAlign: 'center' }} colSpan={2}>Meal Break
											<div 	style={{ display: 'flex', justifyContent: 'space-around', }} >
												<span>From</span> <span>To</span>{' '}
											</div>
										</th>
										{!props?.isPrint && (
											<th> </th>
										)}
									</tr>
									{workDayFieldArray.fields.map((field, index) => (
										<tr key={`work-field-${field.id}`}>
											<td>
												<FormControl
													fullWidth
													className={styles.tableForms}
												>
													<Controller
														control={contractForm.control}
														name={`workDays.${index}.date`}
														defaultValue=''
														render={({ field }) => (
															<NabuDatepicker
																{...field}
																disabled={props?.isPrint}
																placeholder={!props?.isPrint ? 'Date' : ''}
															/>
														)}
													/>
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.date?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td>
												<FormControl
													fullWidth
													className={styles.tableForms}
												>
													<Controller
														control={contractForm.control}
														name={`workDays.${index}.endDate`}
														defaultValue=''
														render={({ field }) => (
															<NabuDatepicker
																{...field}
																disabled={props?.isPrint}
																placeholder={!props?.isPrint ? 'Date' : ''}
															/>
														)}
													/>
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.date?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td>
												<FormControl
													fullWidth
													className={styles.tableForms}
												>
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.workTimeStartAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.workTimeStartAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td>
												<FormControl
													fullWidth
													className={styles.tableForms}
												>
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.makeUpTimeEndAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.makeUpTimeEndAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											{!props?.isPrint && (
												<>
													<td style={{ display: 'flex' }}>
														{workDayFieldArray.fields.length - 1 ===
																	index && (
															<NabuButton
																variant='text'
																onClick={onAddWorkDay}
															>
																<Add />
															</NabuButton>
														)}
														{workDayFieldArray?.fields.length > 1 && (
															<NabuButton
																variant='text'
																onClick={() => onRemoveWorkDay(index)}
															>
																<DeleteForever color='error' />
															</NabuButton>
														)}
													</td>
												</>
											)}
										</tr>
									))}

									<tr>
										<td colSpan={props?.isPrint ? 4 : 5}>
											<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
												<span>PERFORMER SIGNATURE</span>
												<FormControl  className={styles.tableForms}>	
													<NabuTextbox disabled={true} placeholder=""/>	
												</FormControl>
											</div>
										</td>
									</tr>
								</table>
							</div>
							<br></br>

							<div className="keep-together">
								<p><b>IMPORTANT PROVISIONS. PLEASE READ CAREFULLY.</b></p>
								<h4>STANDARD PROVISIONS</h4>
								<ol>
									<li>
										<b>THEATRICAL/INDUSTRIAL USE (STRIKE THE PARAGRAPH BELOW IF SUCH RIGHTS ARE NOT GRANTED BY PERFORMER)</b>
										<p>Producer shall have the right to the commercial(s) produced hereunder for Theatrical & Industrial use as defined and for the period permitted
in the SAG-AFTRA Audio Recorded Commercials Contract, for which Producer shall pay Performer not less than the additional compensation
therein provided.</p>
									</li>
									<li>
										<b>ARBITRATION</b>
										<p>All disputes and controversies of every kind and nature arising out of or in connection with this Contract shall be subject to arbitration as
								provided in Section 64 of the SAG-AFTRA Audio Recorded Commercial Contract.</p>
									</li>
									<li>
										<b>PRODUCER’S RIGHTS</b>
										<p>Performer acknowledges that Performer has no right, title or interest of any kind of nature whatsoever in or to the commercial(s). A role owned
								or created by Producer belongs to Producer and not to Performer.</p>
									</li>
									<li>
										<p>CONFIDENTIAL INFORMATION</p>
									</li>
									<li>
										<p>
								“Confidential Information” means trade secrets, confidential data, and other non-public confidential proprietary information (whether or not labeled as
confidential) including any and all financial terms of and products involved in the production and any and all scripts whether communicated orally, in written form,
or electronically. Confidential information does not include information that was lawfully in Performer’s possession prior to being disclosed in connection with the
employment of Performer, is now, or hereafter becomes generally known to the public, or that Performer rightfully obtained without restriction from a third party.
Performer acknowledges that Performer has and will become aware of certain Confidential Information. Unless otherwise required by law, Performer agrees that,
without Producer’s prior written approval, Performer shall hold such Confidential Information in the strictest confidence and that Performer will not disclose such
Confidential Information to anyone (other than Performer’s representatives in the course of their duties to Performer, which representatives shall be bound by the
same restrictions as set forth in this Agreement) or utilize such Confidential Information for Performer’s benefit or for the benefit of a third party. Notwithstanding
the foregoing, nothing herein shall prohibit Performer from disclosing Confidential Information concerning Performer’s wages, hours and other terms and
conditions of employment as that term is defined under Section 7 of the National Labor Relations Act. For clarity, except as set forth above, Producer may not
demand or request that Performer execute any non-disclosure agreement that has not been approved in advance and in writing by the Union.
										</p>
									</li>
								</ol>
							</div>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} style={{borderBottom:'0px'}}>
								<tr>
									<td colSpan={4}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<b>LOAN-OUT CORPORATION</b>
											<Box display={'flex'} alignItems={'flex-start'}>
												<input type='checkbox' style={{ marginTop: '3px', }}/>
												<span>Performer is working through a loan-out Corporation.  </span>
											</Box>
											<span>Submit W-9 if incorporated.</span>
										</div>
									</td>
								</tr>
							</table>
							
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>	
								<tr>
									<td style={{width:'130px'}}>Corporation name:</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='loanOutId'
												key={`loan-out-ctr-${contractForm.watch('performerId.value')}`}
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															key={`loan-out-${contractForm.watch('performerId.value')}-${locTrigger}`}
															placeholder={!props?.isPrint ? 'Loan Out Corporation' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={companyOptionGenerator}
															defaultFilter={getLoanOutCorpFilter()}
															isClearable={true}
															onOptionLoaded={(options) => {
																if(options?.length && !contractForm.watch('loanOutId.value')){
																	contractForm.setValue('loanOutId', options[0], { shouldDirty: true});
																	contractForm.trigger('loanOutId', { shouldFocus: true});																						
																}
															}}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('fedId',undefined,{shouldDirty: true});
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
													</>
												)}
											/>
											{contractForm.formState.errors?.loanOutId?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper_ad_agency'
												>
													{
														contractForm.formState?.errors?.loanOutId?.message
													}
												</FormHelperText>
											)}
										</FormControl> 
									</td>
									<td style={{width:'120px'}}>FED-ID #</td>
									<td>
										<FormControl 
											fullWidth 
											className={styles.tableForms}
										>
											<Controller
												control={contractForm.control}
												name = 'fedId'
												key={`fedId_${contractForm.watch('fedId')}`}
												render={({ field }) => (
													<NabuTextbox 
														{...field}
														onChange={undefined}
														value = {contractForm.watch('fedId')}
														placeholder="FED-ID #" 
														disabled = {true}
													/>
												)}
																		
											/>
																	
										</FormControl> 
									</td>
								</tr>
								<tr>
									<td>
								Performer’s Tel
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												key={`select-phone-${contractForm.watch(
													'performerPhoneId'
												)}-${contractForm.watch(
													'performerId.value'
												)}`}
												control={contractForm.control}
												name='performerPhoneId'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuSelect
															{...field}
															key={`select-phone-${contractForm.watch(
																'performerPhoneId'
															)}-${contractForm.watch(
																'performerId.value'
															)}`}
															placeholder={!props?.isPrint ? 'Select Phone' : ''}
															convertValueToUpperCase={false}
															enableEmptySelection={true}
															disabled={props?.isPrint || !contractForm.watch('performerId.value')}
															options={getPerformerPhoneNumberOptions()}
															handleDefaultSelection={() => !contractForm.watch('performerPhoneId')}
														/>
														{
															<Box>
																{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onPerformerPhoneNumberInlineSubmit}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerTelephone-address'|| false}	
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'PerformerTelephone-address')}
																								event={phoneNumberPopOver?.event}	
																							/>
																}
															</Box>	
														}
													</>
												)}
											/>
											{contractForm.formState?.errors
												?.performerPhoneId?.message && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors
															?.performerPhoneId?.message
													}
												</FormHelperText>
											)}
										</FormControl> 
									</td>
									<td>
								Performer’s Email
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												key={`select-phone-${contractForm.watch(
													'performerId.value'
												)}-${contractForm.watch('performerEmailId')}`}
												control={contractForm.control}
												name='performerEmailId'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuSelect
															{...field}
															key={`select-phone-${contractForm.watch(
																'performerId.value'
															)}-${contractForm.watch('performerEmailId')}`}
															defaultCase={true}
															placeholder={!props?.isPrint ? 'Select Email' : ''}
															convertValueToUpperCase={false}
															enableEmptySelection={true}
															disabled={props?.isPrint || !contractForm.watch('performerId.value')}
															options={getPerformerEmailsOptions()}
															handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
														/>
														{
															<Box>
																{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																}
															</Box>	
														}
													</>
												)}
											/>
											{contractForm.formState?.errors
												?.performerEmailId?.message && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors
															?.performerEmailId?.message
													}
												</FormHelperText>
											)}
										</FormControl> 
									</td>
								</tr>

							</table>
						</div>
					</div>
				</>
				{/* Main Contract Form End */}
			</ContractMutationWrapper>
		</>
	);
};

export default TeamACSAudioContract;
