/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import styles from './treelevelmenu.module.scss';
import NabuButton from '../../atoms/button/button';
import { Save } from '@mui/icons-material';
import { Tree } from 'antd';
import { mediaType, mediaTypeOptions } from '@/common/constants/constants';

interface IProps {
	onSelectValues?: (selectedNodes: any) => void;
	selectedIds?: any[];
	checkedNodes?: any[];
	allCheckedNodes?: any[];
}

export interface ICheckedNodeProps {
	firstColumnData: any,
	secondColumnData: any,
	thirdColumnData: any,
	fourthColumnData: any,
	fifthColumnData: any,
	firstColSelectedKeys: any,
	secondColSelectedKeys: any,
	thirdColSelectedKeys: any,
	fourthColSelectedKeys: any,
	fifthColSelectedKeys: any,
}

const TreeLevelMenu = (props: IProps) => {
	
	const [checkedValues, setCheckedValues] = useState<ICheckedNodeProps>({
		firstColumnData: [],
		secondColumnData: [],
		thirdColumnData: [],
		fourthColumnData: [],
		firstColSelectedKeys: [],
		secondColSelectedKeys: [],
		thirdColSelectedKeys: [],
		fourthColSelectedKeys: [],
		fifthColumnData: [],
		fifthColSelectedKeys: []
	});

	const handleSelectedMediaItemsChange = (event: any, selectedKeys: any, index?: any) => {
		if(!index){
			setCheckedValues((prev) => {
				return {
					...prev,
					firstColumnData: selectedKeys.concat(event?.halfCheckedKeys),
					firstColSelectedKeys: selectedKeys,
				};
			});
		} else if (index === 5){
			setCheckedValues((prev) => {
				return {
					...prev,
					secondColumnData: selectedKeys.concat(event?.halfCheckedKeys),
					secondColSelectedKeys: selectedKeys,
				};
			});
		}else if (index === 6){
			setCheckedValues((prev) => {
				return {
					...prev,
					thirdColumnData: selectedKeys.concat(event?.halfCheckedKeys),
					thirdColSelectedKeys: selectedKeys,
				};
			});
		}else if (index === 7){
			setCheckedValues((prev) => {
				return {
					...prev,
					fourthColumnData: selectedKeys.concat(event?.halfCheckedKeys),
					fourthColSelectedKeys: selectedKeys,
				};
			});
		}else{
			setCheckedValues((prev) => {
				return {
					...prev,
					fifthColumnData: selectedKeys.concat(event?.halfCheckedKeys),
					fifthColSelectedKeys: selectedKeys,
				};
			});
		}
	};

	const handleSaveClick = () => {
		props?.onSelectValues && props.onSelectValues(checkedValues);
	};

	useEffect(() => {
		const firstColumnValue = mediaType?.filter(c => props?.checkedNodes?.find(e => e === c?.id) && c.column === 'first')?.map(e => e.id);
		const secondColumnValue = mediaType?.filter(c => props?.checkedNodes?.find(e => e === c?.id) && c.column === 'second')?.map(e => e.id);
		const thirdColumnValue = mediaType?.filter(c => props?.checkedNodes?.find(e => e === c?.id) && c.column === 'third')?.map(e => e.id);
		const fourthColumnValue = mediaType?.filter(c => props?.checkedNodes?.find(e => e === c?.id) && c.column === 'fourth')?.map(e => e.id);
		const fifthColumnValue = mediaType?.filter(c => props?.checkedNodes?.find(e => e === c?.id) && c.column === 'fifth')?.map(e => e.id);

		const firstColumnAllCheckedNodes = mediaType?.filter(c => props?.allCheckedNodes?.find(e => e === c?.id) && c.column === 'first')?.map(e => e.id);
		const secondColumnAllCheckedNodes = mediaType?.filter(c => props?.allCheckedNodes?.find(e => e === c?.id) && c.column === 'second')?.map(e => e.id);
		const thirdColumnAllCheckedNodes = mediaType?.filter(c => props?.allCheckedNodes?.find(e => e === c?.id) && c.column === 'third')?.map(e => e.id);
		const fourthColumnAllCheckedNodes = mediaType?.filter(c => props?.allCheckedNodes?.find(e => e === c?.id) && c.column === 'fourth')?.map(e => e.id);
		const fifthColumnAllCheckedNodes = mediaType?.filter(c => props?.allCheckedNodes?.find(e => e === c?.id) && c.column === 'fifth')?.map(e => e.id);
		
		setCheckedValues((prev) => {
			return{
				...prev,
				firstColumnData: firstColumnAllCheckedNodes || [],
				firstColSelectedKeys: firstColumnValue || [],
				secondColumnData: secondColumnAllCheckedNodes || [],
				secondColSelectedKeys: secondColumnValue || [],
				thirdColumnData: thirdColumnAllCheckedNodes || [],
				thirdColSelectedKeys: thirdColumnValue || [],
				fourthColumnData: fourthColumnAllCheckedNodes || [],
				fourthColSelectedKeys: fourthColumnValue || [],
				fifthColumnData: fifthColumnAllCheckedNodes || [],
				fifthColSelectedKeys: fifthColumnValue || []
			};
		});
	},[]);

	return (
		<Box className={styles.treeLevelMenu}>
			<div style={{ 
				display: 'flex', 
				width: 800,
				paddingLeft: 30 
			}}> 
				<Tree
					checkable
					defaultCheckedKeys={props?.checkedNodes ? props?.checkedNodes : []}
					onCheck={(selectedKeys, info) => handleSelectedMediaItemsChange(info, selectedKeys)}
					treeData={mediaTypeOptions.filter(e => Number(e.key) < 6)}
					rootStyle={{display: 'flex'}}
					defaultExpandedKeys={props?.checkedNodes ? props?.checkedNodes : []}
				/>
				{
					mediaTypeOptions?.map((item: any, index) => {
						return (
							index > 4 ?
								<Tree
									key={index}
									checkable
									defaultCheckedKeys={props?.checkedNodes ? props?.checkedNodes : []}
									onCheck={(selectedKeys, info) => handleSelectedMediaItemsChange(info, selectedKeys, index)}
									treeData={[item]}
									rootStyle={{display: 'flex',}}
									defaultExpandedKeys={props?.checkedNodes ? props?.checkedNodes : []}
								/> : null
						);
					})
				}
			</div>
			<div dir='rtl'>
				<br></br>
				<NabuButton onClick={handleSaveClick} variant='filled'>Save <Save/></NabuButton>
			</div>
		</Box>
	);
};

export default TreeLevelMenu;