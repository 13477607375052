import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import NabuButton from '@/common/components/atoms/button/button';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import useNotification from '@/common/hooks/useNotification';
import { CompanyMainType, ICompanyAddress } from '@/common/types/company';
import {
	IBaseContract,
	ICommercialDetail,
	IContractSagAftraSdExtComEx2Y22Form
} from '@/common/types/contract';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import { Box, FormControl, Tooltip } from '@mui/material';
import { UseFormReturn, useForm } from 'react-hook-form';

import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect, { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';

import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddPhoneNumberInline from '@/common/components/templates/pages/add/addPhoneNumberInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { CONTRACT_GUARDIAN, CONTRACT_REF_ID, ContractStatus, ContractStatusOptions, ContractUnionType, DefaultPageSize, SagAftraExhibit2PerformerTypeOptions } from '@/common/constants/constants';
import { OPERATORS, UNION_TYPE } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useScrollToField from '@/common/hooks/useScrollToField';
import { ICompanyRecord } from '@/common/types/company';
import {
	IPacketControlForm
} from '@/common/types/contract';
import { FilterModel, FilterOption, IFilterOption } from '@/common/types/filterModel';
import { addressShortner, displayAddress, displayOnlyEmail, displayPhoneNumberWithoutLabel, getBaseUrl, getCompanyDisplayName, getLocaleDate, getLocaleDateTime, getNotificationText, getPersonFullName, mutateContractAdditionalFormsData, sortEmailOrPhoneNumberForDropDown } from '@/common/utils/utils';
import { EmailFormModel, PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import { getAllAssetForBasicDetail, getAllSignatory, retriveAssetProducts } from '@/services/asset';
import { createCompanyAddressPhoneNumber, getAllAddressByCompany, getAllProducts, getCompanyBasicDetails, getCompanyPrimaryEmailIdsForContract } from '@/services/company';
import { addContractSagAftraSdExtComEx2Y22, addMultipleContract, updateUpdateContractSagAftraSdExtComEx2Y22 } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { GetByWithFullNameSearchForBasicDetail, createPersonEmail, createPersonPhoneNumber, getAddressBasedOnPinCode, getAgentInfo, getAllAddressByPerson } from '@/services/person';
import store from '@/store';
import { setDisableLoader } from '@/store/app/reducer';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, CopyAll, DeleteForever, HelpOutline, NoteAdd, Save } from '@mui/icons-material';
import {
	Checkbox,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays } from 'date-fns';
import { Controller, useFieldArray } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { SagAftraSdExtComEx2Y22Schema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import LocationFreeTextSelect from './locationFreeTextSelect';
import { IContractFormState } from './standardSagAftraEmployment2022';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractSagAftraSdExtComEx2Y22Form;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract | any;
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?: IAssetRecord[];
}

// eslint-disable-next-line react/display-name
const SagAftraSdExtComEx2Y22 = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleContractSagAftraSdExtComEx2Y22FormCancel: (data: IContractSagAftraSdExtComEx2Y22Form, statusValue: string) => {
			if(data){
				const commercials = data?.commercials?.map((d) => ({
					...d,
					assetId: d?.id,	
				}));
				const workDays = data?.workDays?.filter((d) => d?.date) || [];
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractSagAftraSdExtComEx2Y22: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId: data?.adAgencyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					productId: data?.productId?.map(product => product?.value),
					performerAddressId: data?.performerAddressId?.value,
					talentAgencyId: data?.talentAgencyId?.value,
					performerAdAgencyId: data?.performerAdAgencyId?.value,
					performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
					producerCompanyId: data?.producerCompanyId?.value,
					payrollVendorAddressId: data?.payrollVendorAddressId?.value || '',
					commercials,
					workDays
				};
				editContractDetails(submitData);
			}
		},
	}));

	const DEFAULT_COUNTRY_CODE = 'US';

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
		if(props?.defaultValue?.productId)
			contractForm.setValue('productId', props?.defaultValue?.productId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	useEffect(() => {
		contractForm.setValue('isJPCAuthorizer', props?.defaultValue?.isJPCAuthorizer, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.isJPCAuthorizer]);

	const NON_EVENING_WEAR_COST = 20.36;
	const EVENING_WEAR_COST = 33.91;

	const notification = useNotification();

	const contractForm = useForm<IContractSagAftraSdExtComEx2Y22Form>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}],
			}
			: {...(props?.initialValue || {}), 
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials : [{}], 
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
			},
		resolver: yupResolver(SagAftraSdExtComEx2Y22Schema),
		shouldFocusError: false
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const [companyOptions, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(props?.assets || []);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [selectedAddress, setSelectedAddress] = useState<{id:any, type:any}>();
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const AGENT_REPRESENTATIVE_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AGENT/REPRESENTATIVE',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];

	// helper functions
	const getCommercialFilters = () => {
		const assetFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return assetFilter;
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1)?.toISOString() : '',
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};
	
	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	const getProducts = async () => {
		const commercials = contractForm.watch('commercials');
		let totalProducts: any = [];
		
		if(commercials){
			for(let i = 0; i < commercials.length; i++){
				const filterModel = {
					pageSize: DefaultPageSize,
					sortDescending: false,
					sortField: 'CreatedAt',
					pageNumber: 0,
					filters: [{
						property: 'Asset.Id',
						value: commercials[i].assetId?.value ?? '',
						operator: OPERATORS.EQUAL,
						isCaseSensitive: false
					}]
				};
		
				const res: any = await retriveAssetProducts(filterModel);
		
				if(res?.data?.records){
					const products: any = res?.data?.records?.map(product => {
						return { value: product.id, label: product.productName };
					});
					
					totalProducts = [...totalProducts, ...products];
				}
			}

			contractForm.setValue('productId', totalProducts, { shouldDirty: true, shouldValidate: true });
		}
	};

	const onRemoveCommercial = (index: number) => {
		if (commercialFieldArray.fields.length > 1) {
			commercialFieldArray.remove(index);
			getProducts();
		}
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${packetControlForm.watch(key) ? 'Added to' : 'Removed from'} Packet`,
			type: 'info',
		});
	};

	const onAddressChange = (addressId: string, field: 'performer' | 'performerAdAgency') => {
		setSelectedAddress({type: field, id: addressId});
		const address = [...addressOptions, ...(props?.address || [])]?.find( ad => ad?.id === addressId);
		
		if(address) {
			contractForm.setValue(`${field}Zip`, address?.zipCode, {shouldDirty: true});
			contractForm.setValue(`${field}State`, address?.state, {shouldDirty: true});
			contractForm.setValue(`${field}City`, address?.city?.toUpperCase(), {shouldDirty: true});
		} else {
			contractForm.setValue(`${field}State`, '', {shouldDirty: true});
			contractForm.setValue(`${field}Zip`, '', {shouldDirty: true});
			contractForm.setValue(`${field}City`, '', {shouldDirty: true});
		}
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);

				// if(type === 'ADDRESS'){
				// 	return [...responseData];
				// }

				return [...oldFilter, ...responseData];
			});
		}
	};

	useEffect(() => {
		if(selectedAddress?.type !== ''){
			onAddressChange(selectedAddress?.id, selectedAddress?.type);
		}
	}, [addressOptions]);

	const setJPCAuthorizer = () => {
		const adAgency = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('adAgencyId.value')
		)?.isJPCAuthorizer;
		const advertiser = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('advertiserId.value')
		)?.isJPCAuthorizer;

		contractForm.setValue('isJPCAuthorizer', adAgency || advertiser, {
			shouldDirty: true,
		});
	};
	
	const getAssetAdIdByAssetIndex = (index: number) => {		
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.adId || '';
	};

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase(),
			})) || []
			: [];

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const referToOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');

		const options: ILookAheadSelectOption[] = [];
		responseData?.forEach((d: IPersonRecord) => {
			d?.emails?.forEach((e) => {
				!!e?.emailId?.trim() &&
					options.push({
						value: e?.id,
						label: `${getPersonFullName(d?.personName)}(${e?.emailId})` || '-',
					});
			});
		});
		return options;
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const addressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');
		
		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};
	const agentAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');
		
		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};

	const onZipCodeChange = (zipCode: string, field: 'performer' | 'performerAdAgency') => {
		if (zipCode) {
			store.dispatch(setDisableLoader(true));
			getAddressBasedOnPinCode(zipCode)
				.then((res) => {
					if (res?.data?.['country abbreviation'] === 'US') {
						if(res.data){
							const place = res.data.places[0];
							contractForm.setValue(`${field}State`, place['state abbreviation'], { shouldDirty: true });
							contractForm.setValue(`${field}City`, place['place name']?.toUpperCase() ?? '', { shouldDirty: true });
						}
					}
				})
				.catch((e) => console.error(e?.message))
				.finally(() => {
					store.dispatch(setDisableLoader(false));
				});
		}
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];

		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerPhoneNumberOptions = () => {
		const unsortedPhoneNumbers =
			[...(personOptions || []), ...(props?.people || [])]?.find((a) => a?.id === contractForm.watch('performerId.value'))?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			[...(personOptions || []), ...(props?.people || [])]?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];
	
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
			
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const calculateTotalWardrobeFee = () => {
		const getNumber = (value: number | string | undefined): number =>
			isNaN(Number(value)) ? 0 : Number(value);

		const eveningWearCost =
			getNumber(contractForm.watch('eveningWearCount')) * EVENING_WEAR_COST;
		const nonEveningWearCost =
			getNumber(contractForm.watch('nonEveningWearCount')) *
			NON_EVENING_WEAR_COST;

		contractForm.setValue(
			'totalWardrobeFee',
			parseFloat((eveningWearCost + nonEveningWearCost).toFixed(4)),
			{ shouldDirty: true }
		);
	};

	const getUnions = (): any[] =>
		[UNION_TYPE.AFM, UNION_TYPE.SAG_AFTRA].map((value) => ({
			label: value,
			value: value,
		})) || [];

	const getUnionAgreement = (unionType: string): any[] =>
		ContractUnionType[unionType]?.agreements?.map((op) => ({
			label: op?.toUpperCase(),
			value: op?.toUpperCase(),
		})) || [];

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractSagAftraSdExtComEx2Y22(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) => updateUpdateContractSagAftraSdExtComEx2Y22(
				props?.contractId || '',
				payload
			),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		data: IContractSagAftraSdExtComEx2Y22Form,
		handleMultipleContractAdd?: CallableFunction
	) => {
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));
			const workDays = data?.workDays?.filter((d) => d?.date) || [];

			const mainFormValues = props?.mainForm?.getValues();	
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};


			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractSagAftraSdExtComEx2Y22: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				productId: data?.productId?.map(product => product?.value),
				performerAddressId: data?.performerAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				performerAdAgencyId: data?.performerAdAgencyId?.value,
				performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				commercials,
				workDays
			};			

			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractSagAftraSdExtComEx2Y22Form) => {
		handleContractSubmission(data);
	};

	const { mutate: addMultipleContractDetails} =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addMultipleContract(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					const baseURL = getBaseUrl(window.location.href);
					const contractIds : any = res?.data || [];
					const urls =  contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

					urls.forEach((url) => {
						const a = document.createElement('a');	
						a.href = url;
						a.target = '_blank';
						const evt = document.createEvent('MouseEvents');
						evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
						a.dispatchEvent(evt);
					});
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
				} else
					notification.notify({
						message:
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const [contractFormData,setContractFormData ] = useState<IContractSagAftraSdExtComEx2Y22Form>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						jobTitle: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						category:talent?.perfCategory?.toUpperCase(),
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						performerAdAgencyAddressId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddSagAftraSdExtComEx2Y22Models: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};


	const handleSaveAndDuplicate = (data: IContractSagAftraSdExtComEx2Y22Form) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	useEffect(()=> {
		if(props?.isEdit && commercialFieldArray.fields.length === 0){
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const onAdAgencyAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('adAgencyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('adAgencyId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const PerformerAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const PerformerAdAgencyAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerAdAgencyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAdAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const [phoneNumberPopOver, setPhoneNumberInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const closePhoneNumberInlinePopOver = () => {
		setPhoneNumberInlinePopOver({ isOpen: false });
	};

	const openPhoneNumberInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setPhoneNumberInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const onPerformerPhoneNumberInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonPhoneNumber({ personId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(pn => (pn?.id == contractForm.watch('performerId.value') ? ({
						...pn,
						phoneNumbers: [
							{ id: res?.data?.id, ...formData },
							...(pn?.phoneNumbers || {}) as any
						]
					}) : pn))
				));	
				
				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerPhoneId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	const onPhoneNumberInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('performerAdAgencyAddressId.value') || '';
		createCompanyAddressPhoneNumber({ addressId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();
				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('performerAdAgencyAddressId.value')
							? {
								...ad,
								phoneNumbers: [
									{ id: res?.data?.id, ...formData },
									...(ad?.phoneNumbers || {}),
								],
							}
							: ad
					)
				);
				contractForm.setValue('performerAdAgencyPhoneId', res.data.id);
				contractForm.trigger('performerAdAgencyAddressId', { shouldFocus: true });
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const handleCommercialChange = async (id: string) => {
		const filterModel = {
			pageSize: DefaultPageSize,
			sortDescending: false,
			sortField: 'CreatedAt',
			pageNumber: 0,
			filters: [{
				property: 'Asset.Id',
				value: id,
				operator: OPERATORS.EQUAL,
				isCaseSensitive: false
			}]
		};

		const res: any = await retriveAssetProducts(filterModel);

		if(res?.data?.records){
			const existingProducts: ILookAheadSelectOption[] = contractForm.getValues('productId') ?? [];
			let products: any = res?.data?.records?.map(product => {
				return { value: product.id, label: product.productName, assetId: id };
			});

			products = products.filter(product => {
				const foundProduct = existingProducts.find(eProduct => eProduct.value === product.value);
				if(foundProduct) return false;
				else return true;
			});

			contractForm.setValue('productId', [...products, ...existingProducts], { shouldDirty: true, shouldValidate: true });
		}
	};

	const handleProducerChange = (index ?: any)=>{
		if(index ==0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
		}
		if(contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
		}
	};

	const [producerEmails,setProducerEmails]=useState([]);
	const [producerPhoneNumber,setProducerPhoneNumber]=useState([]);
	useQuery({
		queryKey: ['contract-union', 'getBy', contractForm.watch('producerCompanyId.value') ?? ''],
		queryFn: () => getAllAddressByCompany(new FilterModel([new FilterOption('true', 'IsPrimary')]), contractForm.watch('producerCompanyId.value') ?? ''),
		refetchOnWindowFocus: false,
		enabled: !!contractForm.watch('producerCompanyId.value'),
		onSuccess: (res) => {
			if(res?.data?.records?.length){
				const producerEmails = res?.data?.records[0]?.emails;
				const producerPhone =res?.data?.records[0]?.phoneNumbers;
				const emails = sortEmailOrPhoneNumberForDropDown(producerEmails);
				setProducerEmails(emails?.map((e) => ({
					label: displayOnlyEmail(e) || '-',
					value: e?.id || '',
				})) || []);
				const phoneNumbers = sortEmailOrPhoneNumberForDropDown(producerPhone);
				setProducerPhoneNumber(phoneNumbers?.map((pn) => ({
					label: displayPhoneNumberWithoutLabel(pn),
					value: pn?.id || '',
				})) || []);
			}
			else {
				return (
					setProducerEmails([])
				);
			}
		},
		onError: () => {
			return (
				setProducerEmails([])
			);
		},
	});

	const payload = {
		advertiserCompanyId: contractForm.watch('advertiserId.value'),
		adAgencyCompanyId: contractForm.watch('adAgencyId.value'),
	};
	const [emailsForCompany,getEmailsForCompany]=useState<any>();

	useQuery({
		queryKey: [contractForm.watch('advertiserId.value'), contractForm.watch('adAgencyId.value')],
		queryFn: () => getCompanyPrimaryEmailIdsForContract(payload),
		refetchOnWindowFocus: false,
		enabled: !!contractForm.watch('adAgencyId.value'),
		onSuccess: (res) => {
			if (res?.data?.length > 0) {
				getEmailsForCompany(
					res.data?.map((e) => ({
						label: displayOnlyEmail(e) || '-', 
						value: e.id || '',  
					})) || []
				);
			}
			else {
				return (
					getEmailsForCompany([])
				);
			}
		},
		onError: () => {
			return (
				getEmailsForCompany([])
			);
		},
	});

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}> Government & Payroll forms</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen ? undefined : styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>Contract Name<sup>*</sup></FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox 
																				{...field}
																				placeholder={!props?.isPrint ? 'Contract Name' : ''}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Payroll Vendor' : ''}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors?.payrollVendorId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				sortField='IsPrimary'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isOpen}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</>
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Select Status' : ''}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Union
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='unionType'
																	defaultValue={undefined}
																	render={({ field }) => (
																		<NabuSelect
																			{...field}
																			enableEmptySelection={true}
																			placeholder='Select Union'
																			options={getUnions()}
																		/>
																	)}
																/>
																{contractForm.formState?.errors?.unionType?.message && (
																	<FormHelperText error={true}>
																		{contractForm.formState.errors.unionType.message}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														{getUnionAgreement(contractForm.watch('unionType'))?.length > 0 && (
															<Grid item sm={12} md={6} lg={2.4}>
																<FormControl fullWidth>
																	<FormLabel>
											Union Agreement <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='unionAgreement'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuSelect
																				{...field}
																				placeholder='Select Agreement'
																				options={getUnionAgreement(
																					contractForm.watch('unionType')
																				)}
																			/>
																		)}
																	/>
																	{contractForm.formState?.errors?.unionAgreement?.message && (
																		<FormHelperText error={true}>
																			{contractForm.formState.errors.unionAgreement.message}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
														)}
													</Grid>
												</div>
											)}
											{/* Main Contract Form Start */}
											<>
												<div className={styles.contractFormBlock}>
													<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
														<table className={`${styles.contractForms} keep-together`} width={'100%'}>
															<tbody>
																<tr>
																	<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																</tr>
																<tr>
																	<td style={{ width: '50%' }}>
																		<h2>
																	2022 Standard SAG-AFTRA<br></br>
																	Employment Contract
																		</h2>{' '}
																	</td>
																	<td style={{ width: '50%' }}>
																		<table
																			width={'100%'}
																			border={1}
																			cellPadding={0}
																			cellSpacing={0}
																		>
																			<tr style={{ background: '#808080' }}>
																				<th colSpan={2} align='left'>
																			PRODUCER TO COMPLETE
																				</th>
																			</tr>
																			<tr>
																				<td style={{ width: '30%' }}>Date</td>
																				<td style={{ width: '70%' }}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						{props?.isPrint ? (
																							<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerDate')) || ''}</p>
																						) : (<Controller
																							control={contractForm.control}
																							name='producerDate'
																							render={({ field }) => (
																								<NabuDatepicker
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={!props?.isPrint ? 'Date' : ''}
																								/>
																							)}
																						/>)}
																						{contractForm.formState?.errors
																							?.producerDate?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors
																										?.producerDate.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Please return to</td>
																				<td>
																					<FormControl
																						className={styles.tableForms}
																						fullWidth
																					>
																						<Controller
																							key={`select-email-${contractForm.watch(
																								'advertiserId.value'
																							)}-${contractForm.watch(
																								'adAgencyId.value'
																							)}-${contractForm.watch('returnToEmail')}`}
																							control={contractForm.control}
																							name='returnToEmail'
																							defaultValue=''
																							render={({ field }) => (
																								<>
																									<NabuSelect
																										{...field}
																										key={`select-email-${contractForm.watch(
																											'advertiserId.value'
																										)}-${contractForm.watch(
																											'adAgencyId.value'
																										)}-${contractForm.watch('returnToEmail')}`}
																										defaultCase={true}
																										placeholder={!props?.isPrint ? 'Select Email' : ''}
																										convertValueToUpperCase={false}
																										enableEmptySelection={true}
																										disabled={
																											props?.isPrint 
																										}
																										onChange={(e: any) => {
																											field.onChange(e);
																										}}
																										options={emailsForCompany}
																									/>
																								</>
																							)}
																						/>
																						{contractForm.formState?.errors
																							?.returnToEmail?.message && (
																							<FormHelperText
																								data-cy='errorHelper_first_session_date'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors
																										?.returnToEmail?.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
														>
															<tbody>
																<tr>
																	<td>
																		<h4>Extras in Commercials (Exhibit 2)</h4>
																	</td>
																	<td
																		align='right'
																		style={{
																			display: 'flex',
																			justifyContent: 'flex-end',
																		}}
																	>
																		<div style={{ display: 'flex' }}>
																			<Controller
																				control={contractForm.control}
																				name='isJPCAuthorizer'
																				render={({ field }) => (
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							key={`jpc-${contractForm.watch(
																								'adAgencyId.value'
																							)}-${contractForm.watch(
																								'advertiserId.value'
																							)}`}
																							value={`${field.value}`}
																							checked={field.value || false}
																							onChange={undefined}
																							type='checkbox'
																							style={{
																								marginTop: '3px',
																							}}
																						/>
																						<span>
																			JPC Authorizer Agency or Advertiser
																						</span>
																					</Box>
																				)}
																			/>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
														>
															<tbody>
																<tr>
																	<td style={{ width: '50%', color: '#515151' }}>
																		<p
																			key={`p-${contractForm.watch(
																				'payrollVendorId.label'
																			)}-${contractForm.watch(
																				'payrollVendorId.value'
																			)}-${contractForm.watch('payrollVendorId')}`}
																		>
																			<span
																				key={`${contractForm.watch(
																					'payrollVendorId.label'
																				)}`}
																			>
																				{contractForm.watch('payrollVendorId.label')},{' '}
																			</span>
																			<span
																				key={`${contractForm.watch(
																					'payrollVendorAddressId.label'
																				)}`}
																			>
																				{contractForm.watch('payrollVendorAddressId.label')}{' '}
																			</span>
																			<br></br> is the Employer of Record solely for
															the purpose
																			<br></br>
															of taxes, workers’ compensation and unemployment
															insurance.
																		</p>
																	</td>
																	<td style={{ width: '50%' }}>
																		<table width={'100%'}>
																			<tr>
																				<td style={{ width: '30%' }}>
																			Between (Ad Agency )<sup>*</sup>:
																				</td>
																				<td style={{ width: '70%' }}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							control={contractForm.control}
																							name='adAgencyId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										data-cy='ad_agency'
																										{...field}
																										placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																										filterProperty='CompanyNames.Name'
																										APICaller={getCompanyBasicDetails}
																										optionGenerator={
																											companyOptionGenerator
																										}
																										defaultFilter={AD_AGENCY_FILTER}
																										isClearable={true}
																										isDisabled={props?.isPrint}
																										onChange={(e: any) => {
																											field.onChange(e);
																											setJPCAuthorizer();
																											contractForm.setValue(
																												'adAgencyAddressId', null, { shouldDirty: true}
																											);
																											contractForm.trigger('adAgencyAddressId');
																										}}
																										cacheOptions
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																									{!props?.isPrint && (
																										<Box sx={{mt:0.5}}>
																											<AddCompanyInline
																												title='Company'
																												companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
																										</Box>
																									)}
																								</>
																							)}
																						/>
																						{(contractForm.formState.errors?.adAgencyId) && (
																							<FormHelperText
																								error={true}
																								data-cy='errorHelper_ad_agency'
																							>
																								{
																									contractForm.formState?.errors
																										?.adAgencyId?.value?.message || 
																								contractForm.formState?.errors
																									?.adAgencyId?.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: '30%' }}>
																			and (Performer)<sup>*</sup>:
																				</td>
																				<td style={{ width: '70%' }}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										data-cy='name_select'
																										{...field}
																										key={`performer-name
																										-${contractForm.watch('performerId.value')}
																										-${contractForm.watch('performerPhoneId')}-${contractForm.watch('performerEmailId')}`}
																										isClearable={true}
																										placeholder={!props?.isPrint ? 'Performer' : ''}
																										filterProperty='PersonName.FirstName'
																										APICaller={(
																											filterModel,
																											searchText
																										) =>
																											GetByWithFullNameSearchForBasicDetail(
																												filterModel,
																												searchText
																											)
																										}
																										isSearchText={true}
																										isDisabled={props?.isPrint}
																										optionGenerator={
																											performerOptionGenerator
																										}
																										defaultFilter={getPerformerFilter()}
																										onChange={(e: any) => {
																											field.onChange(e);
																											contractForm.trigger('performerEmailId');
																											contractForm.setValue('performerEmailId', '', { shouldDirty: true});
																											contractForm.setValue('performerPhoneId', '', { shouldDirty: true});
																											contractForm.resetField('performerEmailId');
																											contractForm.resetField('performerPhoneId');
																											contractForm.setValue('performerPrintName', contractForm.watch('performerId.label'),{ shouldDirty: true });
																											contractForm.setValue('performerAddressId', null, { shouldDirty: true});
																											contractForm.setValue('performerZip', '', { shouldDirty: true});
																											contractForm.setValue('performerState', '', { shouldDirty: true});
																											contractForm.setValue('performerCity', '', { shouldDirty: true});
																										}}
																										cacheOptions={false}
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																									{!props?.isPrint && (
																										<Box >
																											<AddPersonInline
																												title='Person'
																												onPersonCreated={() => ({})}
																												initialValues={{personType: ['TALENT'], isMinor: false}}
																											/>
																										</Box>
																									)}
																								</>
																							)}
																						/>
																						{(contractForm.formState?.errors?.performerId) && (
																							<FormHelperText
																								data-cy='errorHelper_first_session_date'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors
																										?.performerId?.value?.message ||
																								contractForm.formState?.errors
																									?.performerId?.message 
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
														>
															<tr>
																<td style={{ width: '10%' }}>Job #</td>
																<td style={{ width: '90%' }}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='jobTitle'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					data-cy='job_name_textbox'
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Job' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.jobTitle
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-job-title-name'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors?.jobTitle
																						.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tr style={{ background: '#808080' }}>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Dates Worked<sup></sup>
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Work Time
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Meals
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Travel to Location
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Travel from Location
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
														Makeup/Fitting
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																{!props?.isPrint && (
																	<th style={{ textAlign: 'center' }}></th>
																)}
															</tr>
															{workDayFieldArray.fields.map((field, index) => (
																<tr key={`work-field-${field.id}`}>
																	<td height={40} colSpan={2}>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.date`}
																				defaultValue=''
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Date' : ''}
																					/>
																				)}
																			/>
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.date?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.workTimeStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.workTimeEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.mealTimeStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.mealTimeEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeToLocationStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						disableOpenPicker={true}
																						ampm={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeToLocationEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeFromLocationStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeFromLocationEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl className={styles.tableForms}>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.makeUpTimeStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.makeUpTimeEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	{!props?.isPrint && (
																		<>
																			<td style={{ display: 'flex' }}>
																				{workDayFieldArray.fields.length - 1 ===
																	index && (
																					<NabuButton
																						variant='text'
																						onClick={onAddWorkDay}
																					>
																						<Add />
																					</NabuButton>
																				)}
																				{workDayFieldArray?.fields.length > 1 && (
																					<NabuButton
																						variant='text'
																						onClick={() => onRemoveWorkDay(index)}
																					>
																						<DeleteForever color='error' />
																					</NabuButton>
																				)}
																			</td>
																		</>
																	)}
																</tr>
															))}
														</table>
														<table
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
															style={{
																border: '#515151 solid 1px',
																borderTop: '0px',
															}}
														>
															<tr>
																<td width={160}>Performer Signature:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled={true} />
																	</FormControl>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
															style={{ borderTop: '0px' }}
														>
															<tr style={{ background: '#808080' }}>
																<th colSpan={3} style={{ textAlign: 'left' }}>
															PRODUCER ENGAGES EXTRA PERFORMER AND EXTRA
															PERFORMER AGREES TO PERFORM SERVICES FOR PRODUCER
															IN COMMERCIALS AS FOLLOWS:
																</th>
															</tr>
															<tr>
																<td>
															Commercial Title(s)<sup>*</sup>
																	{ !props?.isPrint && (
																		<Tooltip
																			placement='right-end'
																			title={!contractForm.watch('advertiserId.value') ? 'Select Advertiser before selecting commercials' : 'Commercials are fetched from the selected advertiser.'}
																		>
																			<HelpOutline fontSize='inherit' />
																		</Tooltip>)
																	}
																</td>
																<td>Ad-ID(s)</td>
																{!props?.isPrint && <td></td>}
															</tr>
															{commercialFieldArray.fields.map((field, index) => (
																<tr key={`commercial-${field.id}`}>
																	<td>
																		<></>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`commercials.${index}.assetId`}
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='source-asset'
																							{...field}
																							placeholder={!props?.isPrint ? 'Commercial' : ''}
																							filterProperty='Title'
																							isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
																							defaultFilter={getCommercialFilters()}
																							APICaller={(filterModel) => getAllAssetForBasicDetail(filterModel, false)}
																							optionGenerator={
																								lookAheadAssetOptionGenerator
																							}
																							onChange={(e: any) => { handleProducerChange(index);handleCommercialChange(e?.value); field.onChange(e);}}
																							isClearable={true}
																							cacheOptions
																							defaultOptions
																							dropDownsize={25}
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{mt: 1}}>
																								<AddAssetInline
																									title='Asset' />
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.commercials
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState.errors?.commercials[
																							index
																						]?.assetId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox
																				key={`commercials.${index}.assetId`}
																				value={getAssetAdIdByAssetIndex(index)}
																			/>
																		</FormControl>
																	</td>
																	{!props?.isPrint && (
																		<>
																			<td>
																				<div style={{ display: 'flex' }}>
																					{commercialFieldArray.fields.length - 1 ===
																	index && (
																						<NabuButton
																							variant='text'
																							onClick={onAddCommercial}
																						>
																							<Add />
																						</NabuButton>
																					)}
																					{commercialFieldArray?.fields.length > 1 && (
																						<NabuButton
																							variant='text'
																							onClick={() => {onRemoveCommercial(index);handleProducerChange(index);}}
																						>
																							<DeleteForever color='error' />
																						</NabuButton>
																					)}
																				</div>
																			</td>
																		</>
																	)}
																</tr>
															))}
															<tr>
																<td width={'50%'}>
																	<div
																		style={{
																			marginTop: '7px',
																			display: 'inline-block',
																		}}
																	>
														Total number of commercials{' '}
																	</div>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '50%' }}
																	>
																		<NabuTextbox
																			disabled={true}
																			key={contractForm
																				.watch('commercials')
																				?.map((d) => d?.assetId)
																				?.join('-')}
																			value={contractForm
																				?.watch('commercials')
																				?.reduce(
																					(prev, cur) => prev + (cur?.assetId ? 1 : 0),
																					0
																				)}
																		/>
																	</FormControl>
																</td>
																<td colSpan={2}></td>
															</tr>
														</table>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
															style={{ borderTop: '0' }}
														>
															<tr>
																<td colSpan={2}>
																	<p>
																		<b>
																	(Such commercial(s) are to be produced by) Ad
																	Agency, Address
																		</b>
																	</p>
																</td>
															</tr>
															<tr>
																<td>
																	<div
																		style={{
																			marginTop: '7px',
																			display: 'inline-block',
																		}}
																	>
																Ad Agency Name<sup>*</sup>:{' '}
																	</div>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '70%' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='adAgencyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						data-cy='ad_agency'
																						{...field}
																						placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						defaultFilter={AD_AGENCY_FILTER}
																						isDisabled={props?.isPrint}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							setJPCAuthorizer();
																							contractForm.setValue(
																								'adAgencyAddressId', null, { shouldDirty: true}
																							);
																							contractForm.trigger('adAgencyAddressId');
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<Box sx={{mt:0.5}}>
																							<AddCompanyInline
																								title='Company'
																								companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
																						</Box>
																					)}
																				</>
																			)}
																		/>
																		{(contractForm.formState.errors?.adAgencyId) && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors?.adAgencyId
																						?.value?.message || 
																				contractForm.formState?.errors?.adAgencyId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>
																	<div
																		style={{
																			marginTop: '7px',
																			display: 'inline-block',
																		}}
																	>
																Ad Agency Address:{' '}
																	</div>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '70%' }}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`agency-address-cr-${contractForm.watch(
																				'adAgencyId.value'
																			)}-${contractForm.watch('adAgencyAddressId.value')}`}
																			name='adAgencyAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Address' : ''}
																						key={`agency-address-${contractForm.watch(
																							'adAgencyId.value'
																						)}-${contractForm.watch('adAgencyAddressId.value')}`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('adAgencyAddressId.value')){
																								contractForm.setValue('adAgencyAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('adAgencyAddressId', { shouldFocus: true});																						
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByCompany(
																								filterModel,
																								contractForm.watch('adAgencyId.value') ||
																					''
																							)
																						}
																						optionGenerator={
																							adAgencyAddressOptionGenerator
																						}
																						isDisabled={
																							!contractForm.watch('adAgencyId.value') ||
																			props?.isPrint
																						}
																						dropDownsize={50}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{
																						<Box sx={{mt:1.3}}>
																							{(contractForm.watch('adAgencyId.value')&& (!props.isPrint)) &&
																						<AddCompanyAddressInline
																							companyId={contractForm.watch('adAgencyId.value')}
																							title = 'ADDRESS'
																							isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'AdAgency-address'|| false}
																							event={addressPopOver?.event}
																							onAddressCreated={(d) => onAdAgencyAddressCreated(d)}
																							onClose={() => setAddressPopOver({ isOpen: false})}
																							onPopOverOpen={(e) => setAddressPopOver({
																								isOpen: true,
																								event: e?.currentTarget,
																								for: 'AdAgency-address'
																							})}
																							isPersistent = {true} />
																							}
																						</Box>

																					}
																				</>

																			)}
																		/>
																		{contractForm.formState.errors?.adAgencyAddressId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors
																						?.adAgencyAddressId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td>
																	<div
																		style={{
																			marginTop: '7px',
																			display: 'inline-block',
																		}}
																	>
																Acting as agent for Advertiser:{' '}
																	</div>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '70%' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='advertiserId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Advertiser' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						defaultFilter={ADVERTISER_FILTER}
																						isDisabled={props?.isPrint}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('commercials', [{} as any], { shouldDirty: true});
																							contractForm.setValue('productId', null, { shouldDirty: true});
																							contractForm.setValue('producerCompanyId', null, { shouldDirty: true});
																							setJPCAuthorizer();
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<Box sx={{mt:0.5}}>
																							<AddCompanyInline
																								title='Company'
																								companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
																						</Box>
																					)}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.advertiserId && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors?.advertiserId?.value?.message ||
																				contractForm.formState?.errors?.advertiserId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>
																	<div
																		style={{
																			marginTop: '7px',
																			display: 'inline-block',
																		}}
																	>
																Products:{' '}
																	</div>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '70%' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='productId'
																			render={({ field }) => (
																				<LookAheadSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Select Product' : ''}
																					key={`product-${contractForm.watch(
																						'advertiserId.value'
																					)}`}
																					isMulti
																					filterProperty='ProductName'
																					APICaller={(filterModel) =>
																						getAllProducts(
																							filterModel,
																							contractForm.watch('advertiserId.value')
																						)
																					}
																					optionGenerator={productOptionGenerator}
																					isDisabled={
																						!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
																					}
																					defaultFilter={PRODUCT_FILTER}
																					dropDownsize={20}
																					isClearable={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																			)}
																		/>
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td>
																	<p>Date/Time of Engagement<sup>*</sup></p>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			name='engagementDate'
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Engagement Date' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.engagementDate?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.engagementDate.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>
																	<div>
																		<p>	Place of Engagement<sup>*</sup>:</p>
																	</div>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			name='placeOfEngagement'
																			render={({ field }) => (
																				<LocationFreeTextSelect
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Place of Engagement' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.placeOfEngagement
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.placeOfEngagement.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>

														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
															style={{
																border: '#515151 solid 1px',
																borderTop: '0px',
															}}
														>
															<tr>
																<td valign='top' width={300}>
																	<p><b>Category</b></p>
																	<br></br>
																	<FormControl className={styles.tableForms} fullWidth>
																		<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																			<Controller
																				control={contractForm.control}
																				name='category'
																				// defaultValue={'COMMERCIAL EXTRA PERFORMER'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('COMMERCIAL_EXTRA_PERFORMER')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='COMMERCIAL_EXTRA_PERFORMER'
																								checked={field.value === 'COMMERCIAL_EXTRA_PERFORMER'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Commercial Extra Performer</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl className={styles.tableForms} fullWidth>
																		<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																			<Controller
																				control={contractForm.control}
																				name='category'
																				// defaultValue={'HAND MODEL'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('HAND_MODEL')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='HAND_MODEL'
																								checked={field.value === 'HAND_MODEL'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Hand Model</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl className={styles.tableForms} fullWidth>
																		<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																			<Controller
																				control={contractForm.control}
																				name='category'
																				// defaultValue={'STAND-IN'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('STAND_IN')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='STAND_IN'
																								checked={field.value === 'STAND_IN'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Stand-In</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl className={styles.tableForms} fullWidth>
																		<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																			<Controller
																				control={contractForm.control}
																				name='category'
																				// defaultValue={'PHOTO DOUBLE'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('PHOTO_DOUBLE')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='PHOTO_DOUBLE'
																								checked={field.value === 'PHOTO_DOUBLE'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Photo Double</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl className={styles.tableForms} fullWidth>
																		<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																			<Controller
																				control={contractForm.control}
																				name='category'
																				// defaultValue={'OTHER'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('OTHER')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='OTHER'
																								checked={field.value === 'OTHER'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Other</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<div>
																		{props?.isPrint ? (
																			<p className={styles.descriptionPrintText} style={{ padding: '0px' }}>
																				{contractForm.watch('otherCategory')}
																			</p>
																		) : (
																			<>
																				<Controller
																					control={contractForm.control}
																					name='otherCategory'
																					render={({ field }) => (
																						<textarea
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Other Category' : ''}
																							style={{ width: '100%' }}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.otherCategory?.message && (
																					<FormHelperText error={true}>
																						{contractForm.formState?.errors?.otherCategory.message}
																					</FormHelperText>
																				)}
																			</>
																		)}
																	</div>
																</td>


																<td valign='top' width={500}>
																	<p><b>Type</b></p>
																	<br></br>

																	<FormControl className={styles.tableForms} fullWidth>
																		<div style={{ display: 'inline-flex',alignItems: 'center' }}>
																			<Controller
																				control={contractForm.control}
																				name='type'
																				defaultValue='' // Set default value as an empty string for single select
																				render={({ field }) => (
																					<div style={{ userSelect: 'none' }}>
																						<Box display={'flex'}  alignItems={'flex-start'}>
																							{/* Checkbox for 13 Weeks Use */}
																							<input
																								value='is13WeeksUse'
																								checked={field.value === 'is13WeeksUse'}
																								onChange={() => field.onChange(field.value === 'is13WeeksUse' ? '' : 'is13WeeksUse')}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>13 Weeks Use</span>

																							{/* Checkbox for Unlimited Use */}
																							<input
																								value='isUnlimitedUse'
																								checked={field.value === 'isUnlimitedUse'}
																								onChange={() => field.onChange(field.value === 'isUnlimitedUse' ? '' : 'isUnlimitedUse')}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Unlimited Use</span>

																							{/* Checkbox for Traditional Digital Commercial */}
																							<input
																								value='isTraditionalDigitalCommercial'
																								checked={field.value === 'isTraditionalDigitalCommercial'}
																								onChange={() => field.onChange(field.value === 'isTraditionalDigitalCommercial' ? '' : 'isTraditionalDigitalCommercial')}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Traditional Digital Commercial(s) (no Streaming/Linear)</span>

																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																</td>
																<td valign='top'>
																	<p>
																		<b>Adjustments (check all that apply)</b>
																	</p>
																	<br></br>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isWetSnowSmokeOrDust'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Wet,Snow, Smoke or Dust ($53.25)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isHazardAdjustment'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Hazard Adjustment $</span>{' '}
																						</Box>
																					</div>
																				)}
																			/>
																			<Controller
																				control={contractForm.control}
																				name='adjustmentAmount'
																				render={({ field }) => (
																					<input
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Adjustment Amount' : ''}
																						onChange={(e) => {
																							if (
																								new RegExp(/^\d*\.?\d*$/).test(
																									e?.target?.value || ''
																								) &&
																			Number(e?.target?.value) >= 0
																							) {
																								field.onChange(e);
																							} else {
																								const value: string =
																				e?.target?.value ?? '';
																								const num = value
																									?.match(/^\d*\.?\d*/)
																									?.at(0);
																								if (!isNaN(Number(num || ''))) {
																									field.onChange(Number(num || ''));
																								} else {
																									field.onChange(0);
																								}
																							}
																						}}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.adjustmentAmount
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.adjustmentAmount.message
																					}
																				</FormHelperText>
																			)}
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isMakeUpSkullCapHairGoodsHairCut'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>
																					Make-up, Skull Cap, Hairgoods, Haircuts
																					($41.85)
																							</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isNightPremium'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Night Premium</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isOtherAdjustment'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Other:</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<div>
																		{
																			props?.isPrint ? (
																				<p className={styles.descriptionPrintText} style={{padding:'0px'}}>{contractForm.watch('otherAdjustment')}</p>
																			) : ( <>
																				<Controller
																					control={contractForm.control}
																					name='otherAdjustment'
																					render={({ field }) => (
																						<textarea 
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Other Adjustment' : ''}
																							style={{width:'100%'}}
																						/>
																					)}
																				/>
																			</>)
																		}
																	</div>
																
																</td>
															</tr>
														</table>													
													</div>
												</div>
												<div id={CONTRACT_REF_ID.MAIN_FORM_PART_2} className={styles.contractFormBlock} style={{padding:'20px'}}>
													<div className={styles.bgWhite}>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
															style={{
																border: '#515151 solid 1px',
																borderTop: '0px',
															}}
														>
															<tr>
																<td colSpan={4}>
																	<b>
																Allowances / Requested Props by Producer (check
																all that apply)
																	</b>
																</td>
															</tr>
															<tr>
																<td valign='top' width={320}>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isFlightInsurancePayable'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>
																					Flight Insurance (@ $13.39) Payable{' '}
																							</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isTravelWithinStudioZone'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Travel within Studio Zone ($8.00)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isVehicleAllowance'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => {
																									field.onChange(e);
																									if(e?.target?.value) {
																										contractForm.setValue('vehicleType', '', { shouldDirty: true });
																										contractForm.setValue('vehicleTolls', '', { shouldDirty: true });
																										contractForm.setValue('vehicleMileage', '', { shouldDirty: true });
																										contractForm.setValue('vehicleParking', '', { shouldDirty: true });
																									}
																								}}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Vehicle</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>

																		<ul>
																			<p>
																				<FormControl className={styles.tableForms}>
																					<div
																						style={{
																							display: 'flex',
																							alignItems: 'center',
																						}}
																					>
																				Type:
																						<Controller
																							control={contractForm.control}
																							name='vehicleType'
																							render={({ field }) => (
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint || !contractForm.watch('isVehicleAllowance')}
																									placeholder={!props?.isPrint ? 'Vehicle Type' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.vehicleType
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors
																										?.vehicleType.message
																								}
																							</FormHelperText>
																						)}
																					</div>
																				</FormControl>
																			</p>
																			<p>
																				<FormControl className={styles.tableForms}>
																					<div
																						style={{
																							display: 'flex',
																							alignItems: 'center',
																						}}
																					>
																				Tolls $:
																						<Controller
																							control={contractForm.control}
																							name='vehicleTolls'
																							render={({ field }) => (
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint || !contractForm.watch('isVehicleAllowance')}
																									placeholder={!props?.isPrint ? 'Vehicle Tolls' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.vehicleTolls
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors
																										?.vehicleTolls.message
																								}
																							</FormHelperText>
																						)}
																					</div>
																				</FormControl>
																			</p>
																			<p>
																				<FormControl className={styles.tableForms}>
																					<div
																						style={{
																							display: 'flex',
																							alignItems: 'center',
																						}}
																					>
																				Mileage $:
																						<Controller
																							control={contractForm.control}
																							name='vehicleMileage'
																							render={({ field }) => (
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint || !contractForm.watch('isVehicleAllowance')}
																									placeholder={!props?.isPrint ? 'Vehicle Mileage' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.vehicleMileage
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors
																										?.vehicleMileage.message
																								}
																							</FormHelperText>
																						)}
																					</div>
																				</FormControl>
																			</p>
																			<p>
																				<FormControl className={styles.tableForms}>
																					<div
																						style={{
																							display: 'flex',
																							alignItems: 'center',
																						}}
																					>
																				Parking $:
																						<Controller
																							control={contractForm.control}
																							name='vehicleParking'
																							render={({ field }) => (
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint || !contractForm.watch('isVehicleAllowance')}
																									placeholder={!props?.isPrint ? 'Vehicle Parking' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.vehicleParking
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors
																										?.vehicleParking.message
																								}
																							</FormHelperText>
																						)}
																					</div>
																				</FormControl>
																			</p>
																		</ul>
																	</FormControl>
																</td>

																<td valign='top' width={360}>
																	<p>
																		<b>Type</b>
																	</p>
																	<br></br>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<span>Wardrobe Furnished by:</span>
																		</div>
																	</FormControl>
																	{/* <ul>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<div
																				style={{
																					display: 'inline-flex',
																					alignItems: 'center',
																				}}
																			>
																				<Controller
																					control={contractForm.control}
																					name='isWardrobeToBeFurnishedByProducer'
																					defaultValue={false}
																					render={({ field }) => (
																						<div
																							onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																							style={{ userSelect: 'none' }}
																						>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value={field.value + ''}
																									checked={field.value}
																									onChange={(e) => field.onChange(e)}
																									type='checkbox'
																									disabled={props?.isPrint}
																									style={{ marginTop: '3px' }}
																								/>
																								<span>Producer:</span>
																							</Box>
																						</div>
																					)}
																				/>
																			</div>
																		</FormControl>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<div
																				style={{
																					display: 'inline-flex',
																					alignItems: 'center',
																				}}
																			>
																				<Controller
																					control={contractForm.control}
																					name='isWardrobeToBeFurnishedByExtraPerformer'
																					defaultValue={false}
																					render={({ field }) => (
																						<div
																							onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																							style={{ userSelect: 'none' }}
																						>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value={field.value + ''}
																									checked={field.value}
																									onChange={(e) => field.onChange(e)}
																									type='checkbox'
																									disabled={props?.isPrint}
																									style={{ marginTop: '3px' }}
																								/>
																								<span>Extra Performer:</span>
																							</Box>
																						</div>
																					)}
																				/>
																			</div>
																		</FormControl>
																	</ul> */}
																	<ul>
																		<FormControl className={styles.tableForms} fullWidth>
																			<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																				<Controller
																					control={contractForm.control}
																					name='wardrobeSelection'
																					defaultValue=''
																					render={({ field }) => (
																						<div style={{ userSelect: 'none' }}
																						>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									type='checkbox'
																									value='isWardrobeToBeFurnishedByProducer'
																									checked={field.value === 'isWardrobeToBeFurnishedByProducer'}
																									onChange={() =>
																										field.onChange(field.value === 'isWardrobeToBeFurnishedByProducer' ? '' : 'isWardrobeToBeFurnishedByProducer')
																									}
																									disabled={props?.isPrint}
																									style={{ marginTop: '3px' }}
																								/>
																								<span>Producer:</span>
																							</Box>
																						</div>
																					)}
																				/>
																			</div>
																		</FormControl>

																		<FormControl className={styles.tableForms} fullWidth>
																			<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																				<Controller
																					control={contractForm.control}
																					name='wardrobeSelection'
																					render={({ field }) => (
																						<div style={{ userSelect: 'none' }}
																						>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									type='checkbox'
																									value='isWardrobeToBeFurnishedByExtraPerformer'
																									checked={field.value === 'isWardrobeToBeFurnishedByExtraPerformer'}
																									onChange={() =>
																										field.onChange(field.value === 'isWardrobeToBeFurnishedByExtraPerformer' ? '' : 'isWardrobeToBeFurnishedByExtraPerformer')
																									}
																									disabled={props?.isPrint}
																									style={{ marginTop: '3px' }}
																								/>
																								<span>Extra Performer:</span>
																							</Box>
																						</div>
																					)}
																				/>
																			</div>
																		</FormControl>
																	</ul>

																	<p>
																If by Extra Performer, number of Radio ($6.52)
																Tennis Racquet ($6.52) Tolls: costumes requested
																by Producer:
																	</p>
																	<p>
																		<FormControl className={styles.tableForms}>
																			<div
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																			>
																		Non-Evening Wear (${NON_EVENING_WEAR_COST}):
																				<Controller
																					control={contractForm.control}
																					name='nonEveningWearCount'
																					render={({ field }) => (
																						<input
																							{...field}
																							type='number'
																							onChange={(e) => {
																								field.onChange(e);
																								calculateTotalWardrobeFee();
																							}}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Non-Evening Wear Count' : ''}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.nonEveningWearCount
																					?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.nonEveningWearCount.message
																						}
																					</FormHelperText>
																				)}
																			</div>
																		</FormControl>
																	</p>
																	<p>
																		<FormControl className={styles.tableForms}>
																			<div
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																			>
																		Evening Wear (${EVENING_WEAR_COST}):
																				<Controller
																					control={contractForm.control}
																					name='eveningWearCount'
																					render={({ field }) => (
																						<input
																							{...field}
																							onChange={(e) => {
																								field.onChange(e);
																								calculateTotalWardrobeFee();
																							}}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Evening Wear Count' : ''}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.eveningWearCount
																					?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.eveningWearCount.message
																						}
																					</FormHelperText>
																				)}
																			</div>
																		</FormControl>
																	</p>
																	<p>
																		<FormControl className={styles.tableForms}>
																			<div
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																			>
																		Total Wardrobe Fee: $
																				<Controller
																					control={contractForm.control}
																					name='totalWardrobeFee'
																					render={({ field }) => (
																						<input
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Total Wardrobe Fee' : ''}
																							onChange={(e) => {
																								if (
																									new RegExp(/^\d*\.?\d*$/).test(
																										e?.target?.value || ''
																									) &&
																			Number(e?.target?.value) >= 0
																								) {
																									field.onChange(e);
																								} else {
																									const value: string =
																				e?.target?.value ?? '';
																									const num = value
																										?.match(/^\d*\.?\d*/)
																										?.at(0);
																									if (!isNaN(Number(num || ''))) {
																										field.onChange(Number(num || ''));
																									} else {
																										field.onChange(0);
																									}
																								}
																							}}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.totalWardrobeFee
																					?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.totalWardrobeFee.message
																						}
																					</FormHelperText>
																				)}
																			</div>
																		</FormControl>
																	</p>
																</td>
																<td valign='top'>
																	<p>
																		<b>Adjustments (check all that apply)</b>
																	</p>
																	<br></br>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isBooks'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Books ($2.95 each)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isBinocularOperaGlasses'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Binoculars, Opera Glasses ($6.52)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isCameraLargePortableRadio'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>
																					Camera, Large Portable Radio ($6.52)
																							</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isGolfClubs'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Golf Clubs and Bag ($14.24)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isLuggage'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>
																					Luggage ($6.52 each piece, incl. Bookbags,
																					Briefcases)
																							</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isMobileLaptop'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>
																					Mobile, Laptop, MP3 Player ($6.52)
																							</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isPet'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Pet ($27.22)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isSkatesAndSkateboard'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Skates and Skateboard ($10.72)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isSkis'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Skis ($14.24, incl. poles, boots)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isTennisRacket'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Tennis Racquet ($6.52)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isBicycle'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Bicycle ($14.29)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isMoped'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Moped ($21.38)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isCarMotorcycle'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Car, Trailer, Motorcycle ($42.71)</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isOtherAllowance'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Other:</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																		<Controller
																			control={contractForm.control}
																			name='otherFeeDesc'
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Other Fees' : ''}
																				/>
																			)}
																		/>
																		<ul>
																			<div
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																			>
																		Fee $:
																				<Controller
																					control={contractForm.control}
																					name='allowanceFees'
																					render={({ field }) => (
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Allowance Fee' : ''}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.allowanceFees
																					?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.allowanceFees.message
																						}
																					</FormHelperText>
																				)}
																			</div>
																		</ul>
																	</FormControl>
																</td>
															</tr>
														</table>

														<br></br>
														<table
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
															style={{
																border: '#515151 solid 1px',
																borderBottom: '0',
															}}
														>
															<tr style={{ background: '#808080' }}>
																<th colSpan={4} style={{ textAlign: 'left' }}>
															Extra Performer authorizes Producer to make all
															payments to Extra Performer as follows:
																</th>
															</tr>
															<tr>
																<td style={{ paddingTop: '9px' }} colSpan={4}>
																	<div
																		style={{
																			display: 'inline-flex',
																			alignItems: 'center',
																		}}
																	>
																		<Controller
																			control={contractForm.control}
																			name='isPaymentAtExtraPerformerAddress'
																			// defaultValue={true}
																			render={({ field }) => (
																				<div
																					onClick={() => {
																						(!props?.isPrint) && field.onChange(!field.value);
																						contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtExtraPerformerAddress'));
																					}}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => {
																								field.onChange(e);
																								contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtExtraPerformerAddress'));
																							}}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																						<span>
																							<b>To Extra Performer at (Address)::</b>
																						</span>
																					</Box>
																				</div>
																			)}
																		/>
																	</div>
																</td>
															</tr>
															{(<>
																<tr>
																	<td style={{ width: '170px' }}>
															(Performer&apos;s Address):
																		{contractForm.watch('isPaymentAtExtraPerformerAddress') && <sup>*</sup>}
																	</td>
																	<td colSpan={3}>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				key={`select_Address_${contractForm.watch(
																					'performerId.value'
																				)}`}
																				name='performerAddressId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Select Address' : ''}
																							key={`performer-address-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('performerAddressId.value')){
																									contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});									
																									contractForm.trigger('performerAddressId', { shouldFocus: true});	
																									onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');																					
																								}
																							}}
																							APICaller={(filterModel) =>
																								getAllAddressByPerson(
																									filterModel,
																									contractForm.watch(
																										'performerId.value'
																									) || ''
																								)
																							}
																							optionGenerator={
																								addressOptionGenerator
																							}
																							dropDownsize={50}
																							isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('performerId.value')&& (!props.isPrint)) &&
																						<AddCompanyAddressInline
																							companyId={contractForm.watch('performerId.value')}
																							title = 'ADDRESS'
																							isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'Performer-address'|| false}
																							event={addressPopOver?.event}
																							onAddressCreated={(d) => PerformerAddressCreated(d)}
																							onClose={() => setAddressPopOver({ isOpen: false})}
																							onPopOverOpen={(e) => setAddressPopOver({
																								isOpen: true,
																								event: e?.currentTarget,
																								for: 'Performer-address'
																							})}
																							isPersistent = {true} />
																								}
																							</Box>

																						}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.performerAddressId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState.errors
																							?.performerAddressId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td colSpan={4}>
																		<div
																			style={{
																				marginTop: '7px',
																				display: 'inline-block',
																			}}
																		>
																(ZIP) :
																		</div>{' '}
																		<FormControl
																			className={styles.tableForms}
																			style={{ width: '25%' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerZip'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						placeholder={!props?.isPrint ? 'ZIP' : ''}
																						disabled={props?.isPrint}
																						onChange={(e) => {
																							field.onChange(e);
																							onZipCodeChange(
																								contractForm.watch('performerZip') || '',
																								'performer'
																							);
																						}}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.performerZip
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-performerZip'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors?.performerZip
																							.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		<div
																			style={{
																				marginTop: '7px',
																				display: 'inline-block',
																			}}
																		>
																(State) :
																		</div>{' '}
																		<FormControl
																			className={styles.tableForms}
																			style={{ width: '25%' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerState'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							key={`select-performer-state-${contractForm.watch('performerState')}`}
																							{...field}
																							disabled={props?.isPrint}
																							options={getStateOptions()}
																							convertValueToUpperCase={false}
																							placeholder={!props?.isPrint ? 'Select State' : ''}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.performerState
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.performerState
																							.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		<div
																			style={{
																				marginTop: '7px',
																				display: 'inline-block',
																			}}
																		>
																(City):
																		</div>{' '}
																		<FormControl
																			className={styles.tableForms}
																			style={{ width: '25%' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerCity'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						disabled={props?.isPrint}
																						options={getCityOptions(
																							contractForm.watch('performerState')
																						)}
																						convertValueToUpperCase={true}
																						placeholder={!props?.isPrint ? 'Select City' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState?.errors?.performerCity
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.performerCity
																							.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td colSpan={4}>
															(Performer&apos;s Email Address)
	
																		<FormControl
																			className={styles.emaillowerCase}
																			fullWidth style={{maxWidth:250}}
																		>
																			<Controller
																				control={contractForm.control}
																				key={`select-phone-ctr${contractForm.watch(
																					'performerPhoneId'
																				)}-${contractForm.watch(
																					'performerId.value'
																				)}`}
																				name='performerEmailId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerPhoneId'
																							)}-${contractForm.watch(
																								'performerId.value'
																							)}`}			
																							defaultCase={true}			
																							placeholder={!props?.isPrint ? 'Select Email' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																							options={getPerformerEmailsOptions()}
																							handleDefaultSelection={() => {
																								return !contractForm.watch('performerEmailId');
																							}}
																						/>
																						{
																							<Box>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																								}
																							</Box>	
																						}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerPhoneId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.performerPhoneId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	(Performer&apos;s Phone)
																	
																		<FormControl
																			className={styles.tableForms}
																			fullWidth style={{maxWidth:250}}
																		>
																			<Controller
																				control={contractForm.control}
																				key={`select-phone-ctr${contractForm.watch(
																					'performerPhoneId'
																				)}-${contractForm.watch(
																					'performerId.value'
																				)}`}
																				name='performerPhoneId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerPhoneId'
																							)}-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							placeholder={!props?.isPrint ? 'Select Phone' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																							options={getPerformerPhoneNumberOptions()}
																							handleDefaultSelection={() => {
																								return !contractForm.watch('performerPhoneId');
																							}}
																						/>
																						{
																							<Box>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onPerformerPhoneNumberInlineSubmit}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerTelephone-address'|| false}	
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'PerformerTelephone-address')}
																								event={phoneNumberPopOver?.event}	
																							/>
																								}
																							</Box>	
																						}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerPhoneId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.performerPhoneId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
															</>)}
														</table>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
															style={{ borderTop: 'none' }}
														>
															<tr>
																<td>
															I am represented on this production by talent
															agency:
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			name='talentAgencyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						data-cy='ad_agency'
																						{...field}
																						placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						defaultFilter={AGENT_REPRESENTATIVE_FILTER}
																						isClearable={true}
																						isDisabled={props?.isPrint}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('talentAgencyId.value')){
																								contractForm.setValue('talentAgencyId', options[0], { shouldDirty: true});									
																								contractForm.trigger('talentAgencyId', { shouldFocus: true});																					
																							}
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<Box sx={{mt:0.5}}>
																							<AddCompanyInline
																								title='Company'
																								companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
																						</Box>
																					)}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.talentAgencyId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors
																						?.talentAgencyId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>
															Agent’s commission % to be included in gross pay
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			name='talentAgencyCommission'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					placeholder={!props?.isPrint ? 'Commission' : ''}
																					type='number'
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.talentAgencyCommission
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-talentAgencyCommission'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors?.talentAgencyCommission
																						.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
														<table
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
															style={{
																border: '#515151 solid 1px',
																borderTop: 'none',
															}}
														>
															<tr>
																<td style={{ paddingTop: '9px' }} colSpan={6}>
																	<div
																		style={{
																			display: 'inline-flex',
																			alignItems: 'center',
																		}}
																	>
																		<Controller
																			control={contractForm.control}
																			name='isPaymentAtPerformerAgencyAddress'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() =>{ 
																						(!props?.isPrint) && field.onChange(!field.value);
																						contractForm.setValue('isPaymentAtExtraPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
																					}}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) =>{ field.onChange(e);
																								contractForm.setValue('isPaymentAtExtraPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
																							}}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																						<span>
																							<b>
																					To Performer c/o (Agent/Representative,
																					Address):
																							</b>
																						</span>
																					</Box>
																				</div>
																			)}
																		/>
																	</div>
																</td>
															</tr>
															{(<>
																<tr>
																	<td style={{ width: '170px' }}>	
															(Agent/Representative)
																		{contractForm.watch('isPaymentAtPerformerAgencyAddress') && <sup>*</sup>}
																	</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerAdAgencyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field} 
																							key={`agency-${contractForm.watch('performerId.value')}`}
																							placeholder='Agency'
																							filterProperty='CompanyNames.Name'
																							APICaller={(filter) => {
																								filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																								return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																							}}
																							optionGenerator={companyOptionGenerator}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('performerAdAgencyAddressId', null, { shouldDirty: true});
																								contractForm.setValue('performerAdAgencyPhoneId', '', { shouldDirty: true});
																								onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
																							}}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('performerAdAgencyId.value')){
																									contractForm.setValue('performerAdAgencyId', options[0], { shouldDirty: true});									
																									contractForm.trigger('performerAdAgencyId', { shouldFocus: true});																					
																								}
																							}}
																							cacheOptions
																							isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																							defaultOptions
																							dropDownsize={25}
																							isClearable={true}
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && contractForm.watch('performerId.value') && (<AddTalentAgentInline
																							onCompanyCreated={(createdAgent) => contractForm.setValue('performerAdAgencyId', createdAgent, { shouldDirty: true})}
																							onClose={() => setCompanyPopoverInline({ isOpen: false})}
																							onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
																							event={companyPopoverInline?.event}
																							isOpen={companyPopoverInline.isOpen}
																							personId={contractForm.watch('performerId.value') || ''}
																						/>)}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.performerAdAgencyId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors
																							?.performerAdAgencyId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>														
																	<td style={{ width: '140px' }}>
															(Agent&apos;s Address)
																		{contractForm.watch('isPaymentAtPerformerAgencyAddress') && <sup>*</sup>}
																	</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				key={`select_Address
																		-${contractForm.watch('performerAdAgencyId.value')}
																		-${contractForm.watch('performerAdAgencyPhoneId')}`
																				}
																				name='performerAdAgencyAddressId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Select Address' : ''}
																							key={`performer-ad-address-${contractForm.watch(
																								'performerAdAgencyId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('performerAdAgencyAddressId.value')){
																									contractForm.setValue('performerAdAgencyAddressId', options[0], { shouldDirty: true});
																									contractForm.trigger('performerAdAgencyAddressId', { shouldFocus: true});	
																									onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');																					
																								}
																							}}
																							isDisabled={props?.isPrint || !contractForm.watch('performerAdAgencyId.value')}
																							APICaller={(filterModel) =>
																								getAllAddressByCompany(
																									filterModel,
																									contractForm.watch(
																										'performerAdAgencyId.value'
																									) || ''
																								)
																							}
																							optionGenerator={
																								agentAddressOptionGenerator
																							}
																							dropDownsize={50}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('performerAdAgencyPhoneId', '', { shouldDirty: true});
																								onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
																							}}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('performerAdAgencyId.value')&& (!props.isPrint)) &&
																						<AddCompanyAddressInline
																							companyId={contractForm.watch('performerAdAgencyId.value')}
																							title = 'ADDRESS'
																							isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'PerformerAdAgency-address'|| false}
																							event={addressPopOver?.event}
																							onAddressCreated={(d) => PerformerAdAgencyAddressCreated(d)}
																							onClose={() => setAddressPopOver({ isOpen: false})}
																							onPopOverOpen={(e) => setAddressPopOver({
																								isOpen: true,
																								event: e?.currentTarget,
																								for: 'PerformerAdAgency-address'
																							})}
																							isPersistent = {true} />
																								}
																							</Box>
																						}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerAdAgencyAddressId?.message && (
																				<FormHelperText
																					data-cy='errorHelper_?.addressId'
																					error={true}
																				>
																					{
																						contractForm.formState.errors
																							?.performerAdAgencyAddressId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td style={{ width: '130px' }}>
															(Agent&apos;s Phone)
																	</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				key={`select-phone-ctr${contractForm.watch(
																					'performerAdAgencyPhoneId'
																				)}-${contractForm.watch(
																					'performerAdAgencyAddressId.value'
																				)}`}
																				name='performerAdAgencyPhoneId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerAdAgencyPhoneId'
																							)}-${contractForm.watch(
																								'performerAdAgencyAddressId.value'
																							)}`}
																							placeholder={!props?.isPrint ? 'Select Phone' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={props?.isPrint || !contractForm.watch('performerAdAgencyAddressId.value')}
																							options={getPhoneNumberOptions(
																								contractForm.watch(
																									'performerAdAgencyAddressId.value'
																								)
																							)}
																							handleDefaultSelection={() => !contractForm.watch('performerAdAgencyPhoneId')}
																						/>
																						{contractForm.watch('performerAdAgencyAddressId.value') &&
																		(!props?.isPrint) && (
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onPhoneNumberInlineSubmit}
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) =>openPhoneNumberInlinePopOver(e, 'PerformerAdAgency-Adress')}
																								event={phoneNumberPopOver?.event}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerAdAgency-Adress' || false}																		/>
																						)}
																					</>

																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerAdAgencyPhoneId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.performerAdAgencyPhoneId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td style={{ width: '140px' }}>(ZIP)</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerAdAgencyZip'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						placeholder={!props?.isPrint ? 'ZIP' : ''}
																						disabled={props?.isPrint}
																						onChange={(e) => {
																							field.onChange(e);
																							onZipCodeChange(
																								contractForm.watch('performerAdAgencyZip') || '',
																								'performerAdAgency'
																							);
																						}}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.performerAdAgencyZip
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-performerAdAgencyZip'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.performerAdAgencyZip.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td style={{ width: '130px' }}>(State)</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerAdAgencyState'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						key={`select-state-${contractForm.watch('performerAdAgencyState')}`}
																						{...field}
																						disabled={props?.isPrint}
																						options={getStateOptions()}
																						convertValueToUpperCase={true}
																						placeholder={!props?.isPrint ? 'Select State' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerAdAgencyState?.message && (
																				<FormHelperText
																					data-cy='errorHelper_location_State'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.performerAdAgencyState.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>														
																	<td style={{ width: '170px' }}>(City)</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerAdAgencyCity'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						disabled={props?.isPrint}
																						options={getCityOptions(
																							contractForm.watch('performerAdAgencyState')
																						)}
																						convertValueToUpperCase={true}
																						placeholder={!props?.isPrint ? 'Select City' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerAdAgencyCity?.message && (
																				<FormHelperText
																					data-cy='errorHelper_city'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.performerAdAgencyCity.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
															</>)}
														</table>
														<table
															width={'100%'}
															cellPadding={0}
															cellSpacing={0}
															border={1}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tr>
																<td
																	style={{ background: '#515151', color: '#FFF' }}
																	colSpan={2}
																>
															SPECIAL PROVISIONS
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<div>
																		{
																			props?.isPrint ? (
																				<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
																			) : (
																				<div className={styles.textareaSection}>
																					<Controller
																						control={contractForm.control}
																						name='specialProvisions'
																						render={({ field }) => (
																							<NabuBaseTextArea
																								className={styles.specialProvision}
																								wrap='wrap'
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
																								rows={5}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors?.specialProvisions
																						?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors
																									?.specialProvisions.message
																							}
																						</FormHelperText>
																					)}
																				</div>
																			)
																		}		
																	</div>															
																</td>
															</tr>
															<tr>
																<td width={'50%'}>
																	<p>
																Performer acknowledges that he/she has read all
																the terms and conditions in the Special
																Provisions section above and hereby agrees
																thereto:
																	</p>
																</td>
																<td>
															Performer Signature
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled={true} />
																	</FormControl>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tr style={{ background: '#808080' }}>
																<th colSpan={2} style={{ textAlign: 'left' }}>
																	<b>MINORS: </b>Performer hereby certifies that
															he/she is 21 years of age or over. (If under 21
															years of age, this contract must be signed below
															by a parent or guardian.)
																</th>
															</tr>
															<tr>
																<td>
																	<div
																		style={{
																			marginTop: '7px',
																			display: 'inline-block',
																			minWidth: '130px',
																		}}
																	>
																I, the undersigned hereby state that I am the
																		<div
																			style={{
																				display: 'inline-flex',
																				
																			}}
																		>
																			<FormControl fullWidth>
																				<Controller
																					control={contractForm.control}
																					name='guardian'
																					render={({ field }) => (
																						<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																							<div
																								onClick={() => {
																									if (!props?.isPrint) {
																										field.onChange(
																											field.value === CONTRACT_GUARDIAN.FATHER ? '' : CONTRACT_GUARDIAN.FATHER
																										);
																									}
																								}}
																								style={{ userSelect: 'none', display: 'inline-flex' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.FATHER}
																										onChange={() =>
																											field.onChange(
																												field.value === CONTRACT_GUARDIAN.FATHER ? '' : CONTRACT_GUARDIAN.FATHER
																											)
																										}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>
																										<b>{CONTRACT_GUARDIAN.FATHER}</b>
																									</span>
																								</Box>
																							</div>
																							<div
																								onClick={() => {
																									if (!props?.isPrint) {
																										field.onChange(
																											field.value === CONTRACT_GUARDIAN.MOTHER ? '' : CONTRACT_GUARDIAN.MOTHER
																										);
																									}
																								}}
																								style={{ userSelect: 'none', display: 'inline-flex' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.MOTHER}
																										onChange={() =>
																											field.onChange(
																												field.value === CONTRACT_GUARDIAN.MOTHER ? '' : CONTRACT_GUARDIAN.MOTHER
																											)
																										}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>
																										<b>{CONTRACT_GUARDIAN.MOTHER}</b>
																									</span>
																								</Box>
																							</div>
																							<div
																								onClick={() => {
																									if (!props?.isPrint) {
																										field.onChange(
																											field.value === CONTRACT_GUARDIAN.GUARDIAN ? '' : CONTRACT_GUARDIAN.GUARDIAN
																										);
																									}
																								}}
																								style={{ userSelect: 'none', display: 'inline-flex' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.GUARDIAN}
																										onChange={() =>
																											field.onChange(
																												field.value === CONTRACT_GUARDIAN.GUARDIAN ? '' : CONTRACT_GUARDIAN.GUARDIAN
																											)
																										}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>
																										<b>{CONTRACT_GUARDIAN.GUARDIAN}</b>
																									</span>
																								</Box>
																							</div>
																						</div>
																					)}
																				/>
																			</FormControl>
																		</div>
																&nbsp; of the above named Performer and do hereby
																consent<br></br> and give my permission to this
																agreement:{' '}
																	</div>
																</td>
																<td>
															Parent or Guardian Signature
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled={true} />
																	</FormControl>
																	<p>
																		<em>{!contractForm.watch('guardian') ? '' : `(${contractForm.watch('guardian')|| ''})`}</em>
																	</p>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tr style={{ background: '#808080', color:'#FFF' }}>
																<td colSpan={4}>
																	<b>
																This contract is subject to all of the terms and
																conditions which pertain to Extra Performers in
																the applicable Commercials Contract.
																	</b>
																</td>
															</tr>
															<tr>
																<td width={210}>Performer (Print Name):</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='performerPrintName'
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Performer' : ''}
																					value={contractForm.watch('performerId.label')}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.performerPrintName
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.performerPrintName.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={180}>Producer{ !props?.isPrint && (
																	<Tooltip
																		placement='right-end'
																		title={'Signatory'}
																	>
																		<HelpOutline fontSize='inherit' />
																	</Tooltip>)
																}  (Name, Company)<sup></sup>:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			key={`commercial-assetId
																			-${contractForm.watch(`commercials.${0}.assetId.value`)}
																			`}
																			control={contractForm.control}
																			name='producerCompanyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						key={`commercial-assetId
																							-${contractForm.watch(`commercials.${0}.assetId.value`)}
																							`}
																						placeholder={!props?.isPrint ? 'Producer' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																						optionGenerator={companyOptionGenerator}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('producerCompanyEmail', '', { shouldDirty: true});
																							contractForm.setValue('producerCompanyPhone', '', { shouldDirty: true});
																							contractForm.trigger('producerCompanyEmail');
																							contractForm.trigger('producerCompanyPhone');
																						}}
																						isDisabled={props?.isPrint}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{/* {!props?.isPrint && (
																						<Box sx={{mt:0.5}}>
																							<AddCompanyInline
																								title='Company'
																							/>
																						</Box>
																					)} */}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.producerCompanyId && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper-producerCompanyId'
																			>
																				{
																					contractForm.formState?.errors
																						?.producerCompanyId?.value?.message ||
																				contractForm.formState?.errors
																					?.producerCompanyId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={210}>Performer Telephone Number:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-phone-ctr${contractForm.watch(
																				'performerPhoneId'
																			)}-${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerPhoneId'
																			defaultValue=''
																			render={({ field }) => (
																				<>
																					<NabuSelect
																						{...field}
																						key={`select-phone-${contractForm.watch(
																							'performerPhoneId'
																						)}-${contractForm.watch(
																							'performerId.value'
																						)}`}
																						placeholder={!props?.isPrint ? 'Select Phone' : ''}
																						convertValueToUpperCase={false}
																						enableEmptySelection={true}
																						disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																						options={getPerformerPhoneNumberOptions()}
																						handleDefaultSelection={() => !contractForm.watch('performerPhoneId')}
																					/>
																					{
																						<Box>
																							{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onPerformerPhoneNumberInlineSubmit}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerTelephone-address'|| false}	
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'PerformerTelephone-address')}
																								event={phoneNumberPopOver?.event}	
																							/>
																							}
																						</Box>	
																					}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerPhoneId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.performerPhoneId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={180}>Producer Telephone Number:</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			key={`select-em-${contractForm.watch(
																				'producerCompanyId'
																			)}`}
																			control={contractForm.control}
																			name='producerCompanyPhone'
																			defaultValue=''
																			render={({ field }) => (
																				<>
																					<NabuSelect
																						key={`select-em-${contractForm.watch(
																							'producerCompanyId'
																						)}`}
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Phone' : ''}
																						convertValueToUpperCase={false}
																						enableEmptySelection={true}
																						disabled={props?.isPrint || !contractForm.watch('producerCompanyId.value')}
																						options={producerPhoneNumber}
																						handleDefaultSelection={() => contractForm.watch('producerCompanyId.label') && !contractForm.watch('producerCompanyPhone')}
																					/>
																					{/* {
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('producerCompanyAddressId.value') && (!props.isPrint)) &&
																							<AddEmailInline
																								formData={{ ...new EmailFormModel()}}
																								isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																								onSubmit={onProducerEmailInlineSubmit}
																								onClose={closeEmailInlinePopOver}
																								title='Email address'
																								onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																								event={emailPopOver?.event}
																							/>
																								}
																							</Box>	
																						} */}
																				</>
																			)}
																		/>
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={200}>Performer Email:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.emaillowerCase}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-phone-ctr${contractForm.watch(
																				'performerPhoneId'
																			)}-${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerEmailId'
																			defaultValue=''
																			render={({ field }) => (
																				<>
																					<NabuSelect
																						{...field}
																						key={`select-phone-${contractForm.watch(
																							'performerPhoneId'
																						)}-${contractForm.watch(
																							'performerId.value'
																						)}`}
																						defaultCase={true}
																						placeholder={!props?.isPrint ? 'Select Email' : ''}
																						convertValueToUpperCase={false}
																						enableEmptySelection={true}
																						disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																						options={getPerformerEmailsOptions()}
																						handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
																					/>
																					{
																						<Box>
																							{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																							}
																						</Box>	
																					}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerPhoneId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.performerPhoneId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={180}>Producer Email:</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			key={`select-em-${contractForm.watch(
																				'producerCompanyId'
																			)}`}
																			control={contractForm.control}
																			name='producerCompanyEmail'
																			defaultValue=''
																			render={({ field }) => (
																				<>
																					<NabuSelect
																						key={`select-em-${contractForm.watch(
																							'producerCompanyId'
																						)}`}
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Email' : ''}
																						convertValueToUpperCase={false}
																						enableEmptySelection={true}
																						disabled={props?.isPrint || !contractForm.watch('producerCompanyId.value')}
																						options={producerEmails}
																						handleDefaultSelection={() => contractForm.watch('producerCompanyId.label') && !contractForm.watch('producerCompanyEmail')}
																					/>
																					{/* {
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('producerCompanyAddressId.value') && (!props.isPrint)) &&
																							<AddEmailInline
																								formData={{ ...new EmailFormModel()}}
																								isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																								onSubmit={onProducerEmailInlineSubmit}
																								onClose={closeEmailInlinePopOver}
																								title='Email address'
																								onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																								event={emailPopOver?.event}
																							/>
																								}
																							</Box>	
																						} */}
																				</>
																			)}
																		/>
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={200}>Performer Signature</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled={true} />
																	</FormControl>
																</td>
																<td width={180}>Producer Signature:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled={true} />
																	</FormControl>
																</td>
															</tr>
														</table>
														<br />													
													</div>
												</div>
												<div id={CONTRACT_REF_ID.MAIN_FORM_PART_3} style={{padding:'20px'}}>
													<div className={styles.bgWhite}>
														<table
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tr>
																<td colSpan={2}>
																	<h4>Confidentiality Clause</h4>
																	<br></br>
																	<p>
																Confidential Information” means trade secrets,
																confidential data, and other non-public
																confidential proprietary information (whether or
																not labeled as confidential) including any and
																all financial terms of and products involved in
																the production and any and all information
																regarding the creative elements (including,
																without limitation, scripts and storyboards)
																whether communicated orally, in written form, or
																electronically. Confidential information does
																not include information that was lawfully in
																Extra Performer’s possession prior to being
																disclosed in connection with the employment of
																Extra Performer, is now, or hereafter becomes
																generally known to the public, or that Extra
																Performer rightfully obtained without
																restriction from a third party. Extra Performer
																acknowledges that Extra Performer has and will
																become aware of certain Confidential
																Information. Unless otherwise required by law,
																Extra Performer agrees that, without Producer’s
																prior written approval, Extra Performer shall
																hold such Confidential Information in the
																strictest confidence and that Extra Performer
																will not disclose such Confidential Information
																to anyone (other than Extra Performer’s
																representatives in the course of their duties to
																Extra Performer, which representatives shall be
																bound by the same restrictions as set forth in
																this Agreement) or utilize such Confidential
																Information for Extra Performer’s benefit or for
																the benefit of a third party. Notwithstanding
																the foregoing, nothing herein shall prohibit
																Extra Performer from disclosing Confidential
																Information concerning Extra Performer’s wages,
																hours, and other terms and conditions of
																employment as that term is defined under Section
																7 of the National Labor Relations Act. For
																clarity, except as set forth above, Producer may
																not demand or request that Extra Performer
																execute any non-disclosure agreement that has
																not been approved in advance and in writing by
																the Union.
																	</p>
																</td>
															</tr>
														</table>
													</div>
												</div>
											</>
											{/* Main Contract Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
								&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
								Cancel
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
										packetControlForm.formState.isDirty
											) ||
									isAddingContract ||
									isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled'
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(
												contractForm.formState.isDirty ||
										packetControlForm.formState.isDirty
											) ||
									isAddingContract ||
									isEditingContract
										}
									>
								Save & Duplicate <CopyAll />
									</NabuButton>
							&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)} noOfTalentsSelected={noOfTalentsSelected} setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} performerCategory={SagAftraExhibit2PerformerTypeOptions} isUnion={true} />
		</>
	);
});

export default SagAftraSdExtComEx2Y22;
