import { forwardRef } from 'react';
import { FormControl } from '@mui/material';
import { Textarea } from '@mui/joy';
import styles from './textarea.module.scss';
// eslint-disable-next-line react/display-name
export const NabuTextArea = forwardRef((props: any, ref: any) => {
	props = { ...props };

	const handleInputChange = (event) => {
		const input = event.target;
		const value = event.target.value;

		if(input?.type !== 'number'){
			const cursorPosition = input.selectionStart;
			input.value = value;
			input.setSelectionRange(cursorPosition, cursorPosition);
		}		
		props.onChange && props.onChange({ ...event, target: { ...event.target, value } });
	};

	return (
		<>
			<FormControl fullWidth sx={{  }}>
				<Textarea className={styles.textarea} data-cy={`text_area_${props?.name}`} color={props.color} minRows={props.minRows} {...props} onChange={handleInputChange} ref={ref}/>
				{/* <FormHelperText>This is a helper text.</FormHelperText> */}
			</FormControl>
		</>
	);
});
// eslint-disable-next-line react/display-name
export const NabuBaseTextArea = forwardRef((props: any, ref: any) => {
	props = { ...props };

	const getValueCase = (value: string) => {
		if(props?.keepDefaultCase || props?.convertValueToUpperCase === false)
			return value;
		return value?.toUpperCase();
	};

	return (
		<>
			<FormControl fullWidth sx={{  }}>
				<textarea 
					{...props} 
					ref={ref}
					className={styles.textarea}
					onChange={(event) => props.onChange(getValueCase(event?.target?.value || ''))} 
				/>
			</FormControl>
		</>
	);
});