import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import { IFilterModel, IFilterOption, IPagedResultModel } from '@/common/types/filterModel';
import { Sheet } from '@mui/joy';
import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { AxiosResponse } from 'axios';
import { ComponentProps, forwardRef } from 'react';
import AsyncSelect from 'react-select/async';
import styles from './lookAheadSelect.module.scss';

export interface ILookAheadSelectOption {
	value: string;
	label: string;
}

interface Props extends ComponentProps<typeof AsyncSelect> {
	name?: string;
	APICaller?: (filterModel: IFilterModel, searchText?: string) => Promise<AxiosResponse<IPagedResultModel<any>, any>>;
	dropDownsize?: number;
	filterProperty: string;
	sortField?: string;
	filterOperator?: OPERATORS;
	nameOfClass?: string;
	defaultFilter?: IFilterOption[];
	optionGenerator: (responseData: any) => ILookAheadSelectOption[] | any;
	functionCaller?: () => Promise<any> | void;
	handleFilter?: (val:any) => Promise<any> | void;
	isSearchText?: boolean;
	sortDescending?: boolean;
	onOptionLoaded?: (options: ILookAheadSelectOption[]) => void;
	placeholder?: string;
}

// eslint-disable-next-line react/display-name
const LookAheadSelect = forwardRef((props: Props, ref: any) => {
	const { dropDownsize, filterProperty, filterOperator, APICaller, optionGenerator ,functionCaller,handleFilter, sortField, isSearchText} = props;

	const additionalProps = props?.isMulti ? {
		components: {
			Option: ({ ...rest}) => <div {...rest}><Sheet color={rest?.isSelected ? 'primary' : undefined} variant={rest?.isSelected ? 'solid' : undefined}>
				<MenuItem onClick={() => rest?.selectOption( rest?.data)}>
					<Checkbox checked={rest?.isSelected} color='default'/>
					<ListItemText>{rest.children}</ListItemText>
				</MenuItem>
			</Sheet></div>
		},
		closeMenuOnSelect: false,
		hideSelectedOptions: false
	} : {};

	const onOptionsLoaded = (options: ILookAheadSelectOption[]) => {
		props?.onOptionLoaded && props?.onOptionLoaded(options);
	};

	const getOptions = (inputValue: string, callback: (options) => void) => {
		const currentFilter: IFilterOption[] = !isSearchText ? [{
			isCaseSensitive: false,
			operator: filterOperator || OPERATORS.CONTAINS,
			property: filterProperty,
			value: inputValue,
		}] : [];

		if(props?.defaultFilter) currentFilter.push(...props.defaultFilter);

		const filter: IFilterModel = {
			pageNumber: 0,
			pageSize: dropDownsize || 50,
			sortDescending: !!props?.sortDescending,
			sortField: sortField || filterProperty,
			filters: currentFilter,
		};

		if(APICaller){
			APICaller(filter, inputValue).then((res) => {
				if (res?.status === StatusCodes.OK) {
					const filterOptions = optionGenerator(res?.data?.records || res?.data);
					callback(filterOptions);
					onOptionsLoaded(filterOptions);					
				}
			});
		}
		if (functionCaller) {
			if(inputValue && handleFilter){
				handleFilter(inputValue);
			}
			const result = functionCaller();

			if(result){
				result.then((res) => {
					if (res?.status === StatusCodes.OK) {
						const filterOptions = optionGenerator(res?.data?.records || res?.data);
						callback(filterOptions);
						onOptionsLoaded(filterOptions);
					}
				});
			}
		}
	};

	return (
		<div data-cy='company_asyn_select' className={props.nameOfClass}>
			<AsyncSelect
				className={styles.asyncSelect}
				aria-label={props?.name}
				styles={{
					menu: (base) => ({...base, zIndex: 999}),
					multiValueLabel: (base) => ({...base, whiteSpace:'normal', maxWidth:'240px'}),
				}}
				placeholder={props.placeholder}
				loadOptions={getOptions}
				{...additionalProps}
				{...props}
				// ref={ref}
			/>
		</div>
	);
});

export default LookAheadSelect;
