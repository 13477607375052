import AddIcon from '@mui/icons-material/Add';
import NabuButton from '@/common/components/atoms/button/button';
import { NabuCardDefault } from '@/common/components/molecules/cardWhite/cardWhite';
import { useQuery } from 'react-query';
import useNotification from '@/common/hooks/useNotification';
import { StatusCodes } from '@/common/enums/StatusCodes';
import { getNotificationText } from '@/common/utils/utils';
import { getAssetById } from '@/services/asset';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PDFViewer from '@/modules/people/components/peopleInfo/components/tabs/pdfViewer';
import { Edit } from '@mui/icons-material';
import { AddNewModule } from '@/common/constants/constants';

const CompletionReport = () => {
	const { assetId } = useParams();
	const notification = useNotification();
	const [completionReport, setCompletionReport] = useState(null as any);

	const { refetch: fetchAssetDetail } = useQuery({
		queryKey: ['assetProfile', assetId],
		queryFn: () => getAssetById(assetId ?? ''),
		refetchOnWindowFocus: false,
		onSuccess: async (res: any) => {
			if (res?.status === StatusCodes.OK) {
				setCompletionReport(res?.data?.completionReport);
			}
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('Asset', 'FETCHING', true),
			});
		},
	});

	useEffect(() => {
		fetchAssetDetail();
	}, []);
  
	const navigate = useNavigate();

	return (
		<>
			{completionReport ? <NabuButton variant='text' onClick={() => {
				navigate(`/completion-report/${completionReport?.id ?? ''}/completion-report-profile`, { state: { isAssetFormDrawerOpen: true}});
			}}><Edit/>Edit</NabuButton> : <NabuButton variant='text' onClick={() =>
				navigate(`/add/${AddNewModule.COMPLETION_REPORT}`, {
					state: { module: AddNewModule.COMPLETION_REPORT },
				})
			}><AddIcon/>Add</NabuButton>}
			<NabuCardDefault>
				<div className="App">
					<PDFViewer pdfUrl={'fsjflsjflff'} documentName={'Completion Report Document'}/>
				</div>
			</NabuCardDefault>
		</>
	);
};

export default CompletionReport;
