import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import {
	CONTRACT_REF_ID,
	ContractStatus,
	ContractStatusOptions,
} from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import useScrollToField from '@/common/hooks/useScrollToField';
import {
	CompanyMainType,
	ICompanyAddress,
	ICompanyRecord,
} from '@/common/types/company';
import {
	IBaseContract,
	ICommercialDetail,
	IContractNonUnionForm,
	IPacketControlForm,
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	displayOnlyEmail,
	displayPhoneNumberWithoutLabel,
	getBaseUrl,
	getCompanyDisplayName,
	getLocaleDate,
	getNotificationText,
	getPersonFullName,
	mutateContractAdditionalFormsData,
	sortEmailOrPhoneNumberForDropDown,
} from '@/common/utils/utils';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getAllAssetForBasicDetail } from '@/services/asset';
import {
	getAllAddressByCompany,
	getAllStaffOfCompany,
	getCompanyBasicDetails,
} from '@/services/company';
import {
	addContractNonUnion,
	addMultipleContract,
	updateContractNonUnion,
} from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonRecord } from '@/services/models/person';
import {
	GetByWithFullNameSearchForBasicDetail,
	getAgentInfo,
	getAllAddressByPerson,
} from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	CopyAll,
	NoteAdd,
	Save
} from '@mui/icons-material';
import {
	Box,
	Checkbox,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { format } from 'date-fns';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState
} from 'react';
import {
	Controller,
	UseFormReturn,
	useFieldArray,
	useForm,
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { ContractFormGeneralSchema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import { IContractFormState } from '../unionForms/standardSagAftraEmployment2022';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractNonUnionForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?: IAssetRecord[];
}

// eslint-disable-next-line react/display-name
const NonUnionTalentAgreement = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleContractNonUnionTalentAgreementFormCancel: (
			data: IContractNonUnionForm,
			statusValue: string
		) => {
			if (data) {
				const commercials = data?.commercials?.map((d) => ({
					...d,
					assetId: d?.assetId?.value,
				}));
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractNonUnion: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					payrollVendorAddressId: data?.payrollVendorAddressId?.value,
					productionCompanyId:data?.productionCompanyId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId: data?.adAgencyId?.value,
					productId: data?.productId?.value,
					performerAddressId: data?.performerAddressId?.value,
					recordingCompanyId: data?.recordingCompanyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					talentAgencyId: data?.talentAgencyId?.value,
					talentAgencyAddressId: data?.talentAgencyAddressId?.value,
					talentAgencyStaffId: data?.talentAgencyStaffId?.value,
					brandId:data?.brandId?.value,
					commercials,
				};
				editContractDetails(submitData);
			}
		},
	}));
	
	const notification = useNotification();

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	if(props?.initialValue?.commercials?.length && props?.initialValue?.commercials[0]?.assetId?.value){
		props.initialValue.commercials = props.initialValue?.commercials?.map(d => ({ value: d?.assetId?.value, label: d?.assetId?.label})) as any;
	}

	const contractFormValues = props?.initialValue as any;

	const contractForm = useForm<IContractNonUnionForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				commercials: [],
			}
			: {...(props?.initialValue),
				performerAddressId:contractFormValues?.contractNonUnion?.performerAddressData?.id,
				performerEmailId:undefined,
				performerPhoneId:undefined,
				talentAgencyId:undefined,
				talentAgencyAddressId:undefined,
				talentAgencyEmailId:undefined,
				talentAgencyPhoneId:undefined,
				talentAgencyStaffId:undefined
			},
		resolver: yupResolver(ContractFormGeneralSchema()),
		shouldFocusError: false
	});

	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const ASSET_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'Title',
			value: '',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];

	const AGENT_REPRESENTATIVE_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AGENT/REPRESENTATIVE',
		},
	];

	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	const onRemoveCommercial = (index: number) => {
		if (commercialFieldArray.fields.length > 1) {
			commercialFieldArray.remove(index);
		}
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${
				packetControlForm.watch(key) ? 'Added to' : 'Removed from'
			} Packet.`,
			type: 'info',
		});
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'PERSON' | 'ADDRESS'
	) => {
		const cb = {
			PERSON: setPersonOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		const address = responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find((a) => a?.id === addressId)?.emails || [];
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getPerformerPhoneNumberOptions = () => {
		const unsortedPhoneNumbers =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractNonUnion(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractNonUnion(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		data: IContractNonUnionForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;
		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const commercials = data?.commercials?.map((d: any) => ({
				assetId: d?.value
			}));

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractNonUnion: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				productId: data?.productId?.value,
				performerAddressId: data?.performerAddressId?.value,
				recordingCompanyId: data?.recordingCompanyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				talentAgencyAddressId: data?.talentAgencyAddressId?.value,
				talentAgencyStaffId: data?.talentAgencyStaffId?.value,
				brandId:data?.brandId?.value,
				productionCompanyId:data?.productionCompanyId?.value,
				commercials,
			};

			if (!props?.isEdit) {
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					},
				});
			} else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractNonUnionForm) => {
		handleContractSubmission(data);
	};

	const { mutate: addMultipleContractDetails } = useMutation({
		mutationKey: ['contract-add'],
		mutationFn: (payload: any) => addMultipleContract(payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				const baseURL = getBaseUrl(window.location.href);
				const contractIds: any = res?.data || [];
				const urls =
					contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

				urls.forEach((url) => {
					const a = document.createElement('a');
					a.href = url;
					a.target = '_blank';
					const evt = document.createEvent('MouseEvents');
					evt.initMouseEvent(
						'click',
						true,
						true,
						window,
						0,
						0,
						0,
						0,
						0,
						false,
						false,
						false,
						false,
						0,
						null
					);
					a.dispatchEvent(evt);
				});
				notification.notify({
					message: getNotificationText('Contract', 'CREATE'),
					type: 'success',
				});
			} else
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});

	const [contractFormData, setContractFormData] =
		useState<IContractNonUnionForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractNonUnionForm) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						partName: talent?.role,
						talentAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						talentAgencyAddressId:undefined,
						talentAgencyStaffId:undefined,
						talentAgencyPhoneId:undefined,
						talentAgencyEmailId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddNonUnionModels: contractAddModels,
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData &&
			handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	useEffect(() => {
		if (props?.isEdit && commercialFieldArray.fields.length === 0) {
			onAddCommercial();
		}
	}, [props?.isEdit]);

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}>
													{' '}
												Government & Payroll forms
												</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen
											? undefined
											: styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Contract Name<sup>*</sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Contract Name' : ''
																				}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Payroll Vendor' : ''
																				}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions={false}
																				defaultOptions
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isOpen}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</>
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Select Status' : ''
																				}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
													</Grid>
												</div>
											)}
											{/* Main Contract Form Start */}
											<>
												<div className={styles.contractFormBlock}>
													<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
														<table className={styles.contractForms} width={'100%'}>
															<tbody>
																<tr>
																	<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																</tr>
																<tr>
																	<td align='center'>
																		<h2>NON-UNION TALENT AGREEMENT</h2>{' '}
																	</td>
																</tr>
															</tbody>
														</table>
														<hr></hr>
														<br></br>
														<table
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
															align='center'
														>
															<tr>
																<td style={{ padding: '0' }}>
																	<table
																		width={'100%'}
																		border={1}
																		cellPadding={0}
																		cellSpacing={0}
																		className={styles.contractFormsInner}
																	>
																		<tr>
																			<td width={150}>Client/Advertiser 1</td>
																			<td width={300}>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						key={`advertiser-${contractForm.watch(
																							'advertiserId.value'
																						)}-${contractForm.watch(
																							'productId.value'
																						)}`}
																						control={contractForm.control}
																						name='advertiserId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									{...field}
																									placeholder={
																										!props?.isPrint
																											? 'Client'
																											: ''
																									}
																									filterProperty='CompanyNames.Name'
																									APICaller={
																										getCompanyBasicDetails
																									}
																									optionGenerator={
																										companyOptionGenerator
																									}
																									defaultFilter={
																										ADVERTISER_FILTER
																									}
																									isDisabled={props?.isPrint}
																									isClearable={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																										contractForm.resetField(
																											'productId'
																										);
																									}}
																									cacheOptions={false}
																									defaultOptions
																								/>
																								{!props?.isPrint && (
																									<div className={styles.inlineAdd}>
																										<AddCompanyInline
																											title='Company'
																											companyType={{
																												mainType:
																					'ADVERTISER' as CompanyMainType,
																												subType: [],
																											}}
																										/>
																									</div>
																								)}
																							</>
																						)}
																					/>
																					{contractForm.formState.errors
																						?.advertiserId?.message && (
																						<FormHelperText
																							error={true}
																							data-cy='errorHelper_ad_agency'
																						>
																							{
																								contractForm.formState?.errors
																									?.advertiserId.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td width={180}>Job #</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='jobTitle'
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								data-cy='job_name_textbox'
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={
																									!props?.isPrint ? 'Job' : ''
																								}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors?.jobTitle
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper-job-title-name'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors
																									?.jobTitle.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td>Job Name</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='jobName'
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								data-cy='job_name_textbox'
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={
																									!props?.isPrint
																										? 'Job Name'
																										: ''
																								}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors?.jobName
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper-job-title-name'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors
																									?.jobName.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td>
																			Deliverable Assets(
																				{commercialFieldArray.fields.length || ''}
																			)
																			</td>
																			<td width={450}>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='commercialInfo'
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								data-cy='job_name_textbox'
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={
																									!props?.isPrint
																										? 'Commercial Info'
																										: ''
																								}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors
																						?.commercialInfo?.message && (
																						<FormHelperText
																							data-cy='errorHelper-job-title-name'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors
																									?.commercialInfo.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																				<div
																					style={{
																						margin: '0',
																						paddingLeft: '10',
																					}}
																				>
																			
																					<div key={'commercial-'}>
																						<FormControl
																							fullWidth
																							className={styles.tableForms}
																						>
																							<Controller
																								control={contractForm.control}
																								name={'commercials'}
																								render={({ field }) => (
																									<div
																									>
																										<LookAheadSelect
																											isMulti 
																											className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																											data-cy='source-asset'
																											{...field}
																											placeholder={!props?.isPrint ? 'Commercial' : ''}
																											filterProperty='Title'
																											defaultFilter={ASSET_FILTER}
																											isDisabled={props?.isPrint}
																											APICaller={(filterModel) => getAllAssetForBasicDetail( filterModel, false)}
																											optionGenerator={
																												lookAheadAssetOptionGenerator
																											}
																											onChange={(e: any) =>
																												field.onChange(e)
																											}
																											isClearable={true}
																											cacheOptions
																											defaultOptions
																										/>
																										{/* {!props?.isPrint && (
																											<>
																												{commercialFieldArray
																													.fields.length -
																														1 ===
																														index && (
																													<NabuButton
																														variant='text'
																														onClick={
																															onAddCommercial
																														}
																													>
																														<Add />
																													</NabuButton>
																												)}
																												{commercialFieldArray
																													?.fields.length >
																														1 && (
																													<NabuButton
																														variant='text'
																														onClick={() =>
																															onRemoveCommercial(
																																index
																															)
																														}
																													>
																														<DeleteForever color='error' />
																													</NabuButton>
																												)}
																											</>
																										)} */}
																										{!props?.isPrint && (
																											<Box sx={{mt: 1}}>
																												<AddAssetInline
																													title='Asset' />
																											</Box>
																										)}
																									</div>
																								)}
																							/>
																							{/* {contractForm.formState?.errors
																								?.commercials?.length && (
																								<FormHelperText error={true}>
																									{
																										contractForm.formState
																											.errors?.commercials?.assetId?.value?.message
																									}
																								</FormHelperText>
																							)} */}
																						</FormControl>
																					</div>
																						
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td>Agency</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						key={`select-agency-ctr${contractForm.watch(
																							'adAgencyId.value'
																						)}`}
																						control={contractForm.control}
																						name='adAgencyId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									data-cy='ad_agency'
																									{...field}
																									placeholder={
																										!props?.isPrint
																											? 'Agency'
																											: ''
																									}
																									filterProperty='CompanyNames.Name'
																									APICaller={
																										getCompanyBasicDetails
																									}
																									optionGenerator={
																										companyOptionGenerator
																									}
																									defaultFilter={AD_AGENCY_FILTER}
																									isDisabled={props?.isPrint}
																									isClearable={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																									}}
																									cacheOptions={false}
																									defaultOptions
																								/>
																								{!props?.isPrint && (
																									<Box sx={{ mt: 1.3 }}>
																										<AddCompanyInline
																											title='Company'
																											companyType={{
																												mainType:
																												'AD AGENCY' as CompanyMainType,
																												subType: [],
																											}}
																										/>
																									</Box>
																								)}
																							</>
																						)}
																					/>
																					{contractForm.formState.errors
																						?.adAgencyId?.message && (
																						<FormHelperText
																							error={true}
																							data-cy='errorHelper_ad_agency'
																						>
																							{
																								contractForm.formState?.errors
																									?.adAgencyId.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td>Talent Name</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='performerId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									data-cy='name_select'
																									{...field}
																									placeholder={
																										!props?.isPrint
																											? 'Select Performer'
																											: ''
																									}
																									key={`performer-name-${contractForm.watch(
																										'performerId.value'
																									)}`}
																									isClearable={true}
																									filterProperty='PersonName.FirstName'
																									APICaller={(
																										filterModel,
																										searchText
																									) =>
																										GetByWithFullNameSearchForBasicDetail(
																											filterModel,
																											searchText
																										)
																									}
																									isSearchText={true}
																									isDisabled={props?.isPrint}
																									optionGenerator={
																										performerOptionGenerator
																									}
																									defaultFilter={getPerformerFilter()}
																									onChange={(e: any) => {
																										field.onChange(e);
																										contractForm.trigger( 'performerEmailId' );
																										contractForm.setValue( 'performerEmailId', '', { shouldDirty: true } );
																										contractForm.trigger( 'performerEmailId' );
																										contractForm.setValue( 'printName', contractForm.watch( 'performerId.label' ), { shouldDirty: true } );
																										contractForm.setValue('talentAgencyId',null,{ shouldDirty: true });
																										contractForm.setValue('performerAddressId',null,{ shouldDirty: true });
																										contractForm.setValue('talentAgencyAddressId',null,{ shouldDirty: true });
																										contractForm.trigger('talentAgencyAddressId');
																										contractForm.setValue('talentAgencyEmailId', '',{ shouldDirty: true });
																										contractForm.setValue('talentAgencyPhoneId', '',{ shouldDirty: true });
																										contractForm.trigger('talentAgencyId');
																										contractForm.trigger('talentAgencyEmailId');
																										contractForm.trigger('talentAgencyPhoneId');
																									}}
																									cacheOptions={false}
																									defaultOptions
																								/>
																								{!props?.isPrint && (
																									<Box>
																										<AddPersonInline
																											title='Person'
																											onPersonCreated={() => ({})}
																										/>
																									</Box>
																								)}
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.performerId?.message && (
																						<FormHelperText
																							data-cy='errorHelper_first_session_date'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors
																									?.performerId?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td>Role</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='partName'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.partName
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-partName'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors?.partName
																						.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>On Location</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='location'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.location
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-location'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors?.location
																						.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>Session Fee</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='sessionFee'
																			render={({ field }) => (
																				<input
																					{...field}
																					disabled={props?.isPrint}
																					min={0}
																					type='number'
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.sessionFee
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-sessionFee'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.sessionFee.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td>Work Day(s)</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('workDay')) || []}</p>
																		) :(<Controller
																			control={contractForm.control}
																			name='workDay'
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>)}
																		{contractForm.formState.errors?.workDay
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-workDays'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors?.workDay
																						.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>Fitting Fee</td>
																<td colSpan={3}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='fittingFee'
																			render={({ field }) => (
																				<input
																					{...field}
																					disabled={props?.isPrint}
																					min={0}
																					type='number'
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.fittingFee
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-fittingFee'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.fittingFee.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td>
																	<div style={{display:'flex', alignItems:'center'}}>
																		<b>Stunt Bump</b>{' '}
																		<span>
																			<FormControl
																				className={styles.tableForms}
																			>
																				<Controller
																					control={contractForm.control}
																					name='stuntBump'
																					defaultValue=''
																					render={({ field }) => (
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={
																								!props?.isPrint ? 'Stunt Bump' : ''
																							}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.stuntBump
																					?.message && (
																					<FormHelperText
																						data-cy='errorHelper-stuntBump'
																						error={true}
																					>
																						{
																							contractForm.formState?.errors
																								?.stuntBump.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																	, if talent performance includes using a
																	harness for aerial suspension 3-4ft above
																	ground
																		</span>
																	</div>
																</td>
																<td align='right'>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																		}}
																	>
																		<span>
																			<b>Producer Approval : </b>
																		</span>
																		<FormControl className={styles.tableForms}>
																			<NabuTextbox disabled />
																		</FormControl>
																	</div>
																</td>
															</tr>
														</table>
														<hr></hr>

														<div className={styles.inlineText}>
															<b>Use Fee:</b>{' '}
															<FormControl className={styles.tableForms}>
																<Controller
																	control={contractForm.control}
																	name='useFee'
																	defaultValue=''
																	render={({ field }) => (
																		<NabuTextbox
																			{...field}
																			disabled={props?.isPrint}
																			placeholder={!props?.isPrint ? 'Use Fee' : ''}
																		/>
																	)}
																/>
																{contractForm.formState.errors?.useFee?.message && (
																	<FormHelperText
																		data-cy='errorHelper-useFee'
																		error={true}
																	>
																		{contractForm.formState?.errors?.useFee.message}
																	</FormHelperText>
																)}
															</FormControl>{' '}
														to be paid on{' '}
															{props?.isPrint ? (
																<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('useFeePayDate')) || ''}</p>
															) :(<Controller
																control={contractForm.control}
																name='useFeePayDate'
																render={({ field }) => (
																	<NabuDatepicker
																		{...field}
																		disabled={props?.isPrint}
																	/>
																)}
															/>)}
															{contractForm.formState.errors?.useFeePayDate
																?.message && (
																<FormHelperText
																	data-cy='errorHelper-useFeePayDate'
																	error={true}
																>
																	{
																		contractForm.formState?.errors?.useFeePayDate
																			.message
																	}
																</FormHelperText>
															)}{' '}
														for{' '}
															<Controller
																control={contractForm.control}
																name='useFeePayFor'
																defaultValue='3 months'
																render={({ field }) => (
																	<NabuTextbox
																		{...field}
																		disabled={props?.isPrint}
																		placeholder={
																			!props?.isPrint ? 'Duration in months' : ''
																		}
																	/>
																)}
															/>
															{contractForm.formState.errors?.useFeePayFor
																?.message && (
																<FormHelperText
																	data-cy='errorHelper-useFeePayFor'
																	error={true}
																>
																	{
																		contractForm.formState?.errors?.useFeePayFor
																			.message
																	}
																</FormHelperText>
															)}{' '}
														use; an additional use payment for{' '}
															{contractForm.watch('additionalUseFeePayDate') &&
															format(
																new Date(
																	contractForm
																		.watch('useFeePayDate')
																		?.toString() || ''
																),
																'yyyy'
															)}{' '}

														</div>
														<div className={styles.inlineText}>
														use rights to be paid in{' '}
															{props?.isPrint ? (
																<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('additionalUseFeePayDate')) || ''}</p>
															) :(<Controller
																control={contractForm.control}
																name='additionalUseFeePayDate'
																render={({ field }) => (
																	<NabuDatepicker
																		{...field}
																		disabled={props?.isPrint}
																	/>
																)}
															/>)}
															{contractForm.formState.errors
																?.additionalUseFeePayDate?.message && (
																<FormHelperText
																	data-cy='errorHelper-additionalUseFeePayDate'
																	error={true}
																>
																	{
																		contractForm.formState?.errors
																			?.additionalUseFeePayDate.message
																	}
																</FormHelperText>
															)}{' '}
														for{' '}
															<FormControl className={styles.tableForms}>
																<Controller
																	control={contractForm.control}
																	name='additionalUseFee'
																	defaultValue=''
																	render={({ field }) => (
																		<NabuTextbox
																			{...field}
																			disabled={props?.isPrint}
																			placeholder={
																				!props?.isPrint ? '$' : ''
																			}
																		/>
																	)}
																/>
																{contractForm.formState.errors?.additionalUseFee
																	?.message && (
																	<FormHelperText
																		data-cy='errorHelper-additionalUseFee'
																		error={true}
																	>
																		{
																			contractForm.formState?.errors
																				?.additionalUseFee.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														. Use fees total{' '}
															<FormControl className={styles.tableForms} fullWidth style={{maxWidth:'120px'}}>
																<Controller
																	control={contractForm.control}
																	name='totalUseFee'
																	defaultValue=''
																	render={({ field }) => (
																		<NabuTextbox
																			{...field}
																			disabled={props?.isPrint}
																			placeholder={
																				!props?.isPrint ? '$' : ''
																			}
																		/>
																	)}
																/>
																{contractForm.formState.errors?.totalUseFee
																	?.message && (
																	<FormHelperText
																		data-cy='errorHelper-totalUseFee'
																		error={true}
																	>
																		{
																			contractForm.formState?.errors?.totalUseFee
																				.message
																		}
																	</FormHelperText>
																)}
															</FormControl>{' '}
														and covers{' '}
															<FormControl className={styles.tableForms}>
																<Controller
																	control={contractForm.control}
																	name='totalUseFeePayFor'
																	defaultValue=''
																	render={({ field }) => (
																		<NabuTextbox
																			{...field}
																			disabled={props?.isPrint}
																			placeholder={
																				!props?.isPrint ? 'Duration in months' : ''
																			}
																		/>
																	)}
																/>
																{contractForm.formState.errors?.totalUseFeePayFor
																	?.message && (
																	<FormHelperText
																		data-cy='errorHelper-totalUseFeePayFor'
																		error={true}
																	>
																		{
																			contractForm.formState?.errors
																				?.totalUseFeePayFor.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														of use rights.
														</div>
														<p style={{display:'flex', alignItems:'center'}}>
															<b>Agent Fees:</b>
															<FormControl className={styles.tableForms} style={{ width: '50%'}}>
																<Controller
																	control={contractForm.control}
																	name='agentFeeDetail'
																	defaultValue='All fees plus 20%, only paid if talent is represented.'
																	render={({ field }) => (
																		<NabuTextbox
																			{...field}
																			disabled={props?.isPrint}
																			placeholder={
																				!props?.isPrint ? 'Agent Fee Detail' : ''
																			}
																		/>
																	)}
																/>
																{contractForm.formState.errors?.agentFeeDetail
																	?.message && (
																	<FormHelperText
																		data-cy='errorHelper-agentFeeDetail'
																		error={true}
																	>
																		{
																			contractForm.formState?.errors?.agentFeeDetail
																				.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</p>
														<br></br>
														
														<br></br>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td width={130}>
																	<b>Term :</b>
																</td>
																<td>
																	<div className={styles.inlineText}>
																	
																		<FormControl className={styles.tableForms}>
																			<Controller
																				control={contractForm.control}
																				name='term'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Term' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.term?.message && (
																				<FormHelperText
																					data-cy='errorHelper-term'
																					error={true}
																				>
																					{contractForm.formState?.errors?.term.message}
																				</FormHelperText>
																			)}
																		</FormControl>
														, from first air date :{' '}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText}>{getLocaleDate(contractForm.watch('estimatedFirstAirDate')) || ''}</p>
																		) :(<Controller
																			control={contractForm.control}
																			name='estimatedFirstAirDate'
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>)}
																		{contractForm.formState.errors
																			?.estimatedFirstAirDate?.message && (
																			<FormHelperText
																				data-cy='errorHelper-estimatedFirstAirDate'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.estimatedFirstAirDate.message
																				}
																			</FormHelperText>
																		)}
																	</div>
																</td>
															</tr>
															<tr>
																<td width={160} valign='top'>
																	<b>Media & Territory :</b>
																</td>
																<td>
																	<div>
																		{
																			props?.isPrint ? (
																				<p className={styles.descriptionPrintText}>{contractForm.watch('mediaTerritoryDetail')}</p>
																			) : (<> 
																				<Controller
																					control={contractForm.control}
																					name='mediaTerritoryDetail'
																					defaultValue=''
																					render={({ field }) => (
																						<textarea
																							{...field}
																							className={styles.specialProvision}
																							rows={3}
																							disabled={props?.isPrint}
																							placeholder={
																								!props?.isPrint
																									? 'Media & Territory Detail'
																									: ''
																							}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors
																					?.mediaTerritoryDetail?.message && (
																					<FormHelperText
																						data-cy='errorHelper-mediaTerritoryDetail'
																						error={true}
																					>
																						{
																							contractForm.formState?.errors
																								?.mediaTerritoryDetail.message
																						}
																					</FormHelperText>
																				)}
																			</> )
																		}
																	</div>

																</td>
															</tr>
															<tr>
																<td>
																	<b>Exclusivity:</b>
																</td>
																<td>
																	<FormControl className={styles.tableForms} style={{minWidth:'300'}}>
																		<Controller
																			control={contractForm.control}
																			name='exclusivity'
																			defaultValue='None'
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={
																						!props?.isPrint ? 'exclusivity' : ''
																					}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.exclusivity
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-exclusivity'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.exclusivity.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
														<br></br>
														<p>
															<b>All Media Use Rights:</b>
															<div>
																{
																	props?.isPrint ? (
																		<p className={styles.descriptionPrintText}>{contractForm.watch('allMediaUseRightDetail')}</p>
																	) : (<> 
																		<Controller
																			control={contractForm.control}
																			name='allMediaUseRightDetail'
																			defaultValue='The talent performer agreement will detail that Advertiser shall have the right to use All images, footage and/or audio recordings to be used in any type of media, either known now or in the future, including but not limited to broadcast, internet, industrial, and new media including all lifts, variations and cut downs.'
																			render={({ field }) => (
																				<textarea
																					{...field}
																					className={styles.specialProvision}
																					rows={2}
																					disabled={props?.isPrint}
																					placeholder={
																						!props?.isPrint
																							? 'Media & Territory Detail'
																							: ''
																					}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.allMediaUseRightDetail?.message && (
																			<FormHelperText
																				data-cy='errorHelper-allMediaUseRightDetail'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.allMediaUseRightDetail.message
																				}
																			</FormHelperText>
																		)}
																	</> )
																}
															</div>
				
														</p>
														<br></br>
														<p>
															<b>Historical Use Rights:</b>
															<div>
																{
																	props?.isPrint ? (
																		<p className={styles.descriptionPrintText}>{contractForm.watch('historicalUseDetail')}</p>
																	) : (<> 
																		<Controller
																			control={contractForm.control}
																			name='historicalUseDetail'
																			defaultValue="Advertising Materials may be used anywhere in the world by Advertiser and Agency at sales meetings and for the intra-company, research, award, publicity (including, without limitation, on Agency's advertising agency 'reel' and website), as a historical archive file for reference and educational purposes to be granted worldwide and in perpetuity."
																			render={({ field }) => (
																				<textarea
																					{...field}
																					className={styles.specialProvision}
																					rows={3}
																					disabled={props?.isPrint}
																					placeholder={
																						!props?.isPrint
																							? 'Historical Use Rights Detail'
																							: ''
																					}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.historicalUseDetail
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-historicalUseDetail'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.historicalUseDetail.message
																				}
																			</FormHelperText>
																		)}
																	</> )
																}
															</div>
														
														</p>
														<br></br>
														<p>
															<b>Renewal Option:</b>
															<div>
																{
																	props?.isPrint ? (
																		<p className={styles.descriptionPrintText}>{contractForm.watch('renewalOptionDetail')}</p>
																	) : (<> 
																		<Controller
																			control={contractForm.control}
																			name='renewalOptionDetail'
																			defaultValue='Advertiser may extend all above terms and conditions for up to one consecutive 15 month-term for an additional payment for $2200 for All Media Use Rights plus agent fees (20%). Agency or Advertiser will inform Talent of intention to renew.'
																			render={({ field }) => (
																				<textarea
																					{...field}
																					className={styles.specialProvision}
																					rows={3}
																					disabled={props?.isPrint}
																					placeholder={
																						!props?.isPrint
																							? 'Renewal Option Detail'
																							: ''
																					}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.renewalOptionDetail
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-renewalOptionDetail'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.renewalOptionDetail.message
																				}
																			</FormHelperText>
																		)}
																	</> )
																}
															</div>
													
														</p>
														<br></br>
														<p>
															<b>Conditions:</b>
															<div>
																{
																	props?.isPrint ? (
																		<p className={styles.descriptionPrintText}>{contractForm.watch('conditionDetail')}</p>
																	) : (<> 
																		<Controller
																			control={contractForm.control}
																			name='conditionDetail'
																			defaultValue='Talent will be paid from timecard/contract. Talent and agent fees will be paid through a Payroll Vendor (HTP). No Agent invoices are needed. Agency or Advertiser shall cause a third-party payroll provider to process and issue talent payments to Talent. Accordingly, Advertiser understands that the Payroll Vendor (HTP) shall be the employer of record for payroll purposes and shall process all the applicable state, federal and local taxes on such fees as noted above. Agency is the hiring entity.'
																			render={({ field }) => (
																				<textarea
																					{...field}
																					className={styles.specialProvision}
																					rows={3}
																					disabled={props?.isPrint}
																					placeholder={
																						!props?.isPrint ? 'Conditions Detail' : ''
																					}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.conditionDetail
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-conditionDetail'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.conditionDetail.message
																				}
																			</FormHelperText>
																		)}
																	</> )
																}
															</div>
													
														</p>													
													</div>
												</div>

												<div id={CONTRACT_REF_ID.MAIN_FORM_PART_2} className={styles.contractFormBlock} style={{padding:'20px'}}>
													<div className={styles.bgWhite}>
														<p>
															<b>
															For good and valuable consideration, receipt, and
															sufficiency of which is hereby acknowledged, I
															hereby agree as follows:
															</b>
														</p>
														<p>
														For good and valuable consideration, receipt of
														which is hereby acknowledged, I hereby grant to 9th
														Wonder/Gravity Global (hereinafter called
														&quot;Agency&quot;), and its client, MIDFIRST BANK
														(hereinafter &quot;Advertiser&quot;), and
														Agency&apos;s and Advertiser&apos;s respective
														subsidiaries, affiliates, successors and assigns and
														those acting under their permission, the absolute
														right and permission to use, publish, distribute
														and/or broadcast my image, likeness, picture, and
														photograph in whole or in part, or distorted in
														character or form, in conjunction with my own name
														or a fictitious name, together with or without
														written or spoken copy, for advertising, publicity,
														business, trade or any other lawful purpose
														whatsoever per the term, territory and use rights
														noted above.
														</p>
														<br></br>
														<p>
														I further agree in the event of a retake of any or
														all scenes in which I participate, or if additional
														scenes are required (whether originally completed or
														not) I will return to work and render my services in
														such scenes at the same rate of compensation as
														agreed for the original production hourly rate.
														</p>
														<br />
														<p>
														I agree that I will not hold the Agency, Advertiser,
														or anyone who receives permission from either of
														them, responsible for any liability resulting from
														the use of my likeness and film(s) in accordance
														with the terms hereof, including what might be
														deemed to be misrepresentation of me, my character
														or my person due to distortion, optical illusion or
														faulty reproduction which may occur in the finished
														product. I hereby waive any right that I may have to
														inspect or approve the finished product or products
														or the advertising copy or other matter that may be
														used in connection therewith.
														</p>
														<br></br>
														<p>
														I warrant and represent that this license does not
														in any way conflict with any existing commitments on
														my part. Nothing herein will constitute any
														obligation on the licensed parties to make any use
														of any of the rights set forth herein.
														</p>
														<br></br>
														<p>
														I understand that all payments and notices to me
														shall be delivered at the address indicated in this
														agreement. I also understand and agree that it is my
														sole responsibility to inform Agency and Advertiser
														of any change of address in writing. If payment does
														not reach me due to a change of address, Agency and
														Advertiser will hold such payment until notified by
														me in writing of my current mailing address and
														shall have no liability for any delay resulting
														therefrom. Notices with respect to any provisions
														herein, including but not limited to notices to
														renew, extend or exercise any rights or options
														herein shall be deemed accepted by me if Agency
														and/or Advertiser sent such notice to the address
														that I have provided even if I do not receive such
														notice due to my failure to provide a change of
														address notification to Agency and/or Advertiser in
														writing.
														</p>
														<br></br>
														<p>
														This Agreement and the Job are confidential
														information of the Advertiser. I will not, at any
														time, disclose any information related to the Job
														such as what I did or who was there to any third
														parties including, without limitation, any posting
														to social media or other platform accessible by
														third parties or the general public. I further agree
														that I will not take any pictures or make any
														recordings of or at the session and I will not post
														any material relating thereto to my social media or
														any other venue, platform or application which can
														be viewed by the public without the express written
														consent of Advertiser. I also agree that I shall
														abide by any restrictions placed by Advertiser on my
														use of the material.
														</p>
														<br></br>
														<p>
														This Agreement is governed by the laws of the State
														of Texas, and the parties also agree that the courts
														of the State of Texas have personal jurisdiction
														over them for purposes of any disputes which may
														arise from this Agreement. This Agreement contains
														the entire understanding between the parties
														regarding the subject matter hereof and supersedes
														all prior understandings. No waiver, modification or
														additions to this Agreement shall be valid unless in
														writing and signed by the parties hereto.
														</p>
														<br></br>
														<p>
														By signing this form, I hereby agree that the
														Advertiser may take deductions from my earnings to
														adjust previous overpayments if and when said
														overpayments occur. I am over the age of 18 (19 in
														Alabama or Nebraska) and have the right to enter
														into this Agreement on my own behalf and grant the
														rights herein, without violating any law or
														infringing upon the rights of any third parties.
														</p>
														<br></br>
														<p>
														By signing below, I acknowledge that I have read and
														understood all the terms and conditions of this
														Agreement and agree to be bound thereby
														</p>

														<hr></hr>
														<table
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td colSpan={2}>
																	<b>Signature:</b>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled />
																	</FormControl>
																</td>
																<td colSpan={2}>
																	<b>Date:</b>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled />
																	</FormControl>
																</td>
															</tr>
														</table>
														{/*<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td width={140}>Print LEGAL Name:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='printName'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={
																						!props?.isPrint ? 'Print Name' : ''
																					}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.printName
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-printName'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.printName.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={160}>Talent Home Address:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select_Address_${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						{...field}
																						placeholder={
																							!props?.isPrint
																								? 'Select Address'
																								: ''
																						}
																						key={`performer-address-${contractForm.watch(
																							'performerId.value'
																						)}`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('performerAddressId.value')){
																								contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('performerAddressId', { shouldFocus: true});																						
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByPerson(
																								filterModel,
																								contractForm.watch(
																									'performerId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							adAgencyAddressOptionGenerator
																						}
																						dropDownsize={50}
																						isDisabled={
																							props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																						}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions={false}
																						defaultOptions
																					/>
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerAddressId?.value?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState.errors
																						?.performerAddressId?.value?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>*/}
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td width={160}>Print LEGAL Name:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='printName'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={
																						!props?.isPrint ? 'Print Name' : ''
																					}
																					value={contractForm.watch('performerId.label')}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.printName
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-printName'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.printName.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={160}>Talent Home Address:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select_Address_${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						{...field}
																						placeholder={
																							!props?.isPrint
																								? 'Select Address'
																								: ''
																						}
																						key={`performer-address-${contractForm.watch(
																							'performerId.value'
																						)}`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('performerAddressId.value')){
																								contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('performerAddressId', { shouldFocus: true});																						
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByPerson(
																								filterModel,
																								contractForm.watch(
																									'performerId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							adAgencyAddressOptionGenerator
																						}
																						dropDownsize={50}
																						isDisabled={
																							props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																						}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions={false}
																						defaultOptions
																					/>
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerAddressId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState.errors
																						?.performerAddressId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={100}>Talent Phone</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-phone-ctr${contractForm.watch(
																				'performerPhoneId'
																			)}-${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerPhoneId'
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					key={`select-phone-${contractForm.watch(
																						'performerPhoneId'
																					)}-${contractForm.watch(
																						'performerId.value'
																					)}`}
																					placeholder={
																						!props?.isPrint ? 'Select Phone' : ''
																					}
																					convertValueToUpperCase={false}
																					enableEmptySelection={true}
																					disabled={
																						props?.isPrint ||
																					!contractForm.watch(
																						'performerId.value'
																					)
																					}
																					options={getPerformerPhoneNumberOptions()}
																					handleDefaultSelection={() => !contractForm.watch('performerPhoneId')}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerPhoneId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.performerPhoneId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={100}>Talent Email</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-phone-ctr${contractForm.watch(
																				'performerEmailId'
																			)}-${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerEmailId'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					key={`select-phone-${contractForm.watch(
																						'performerEmailId'
																					)}-${contractForm.watch(
																						'performerId.value'
																					)}`}
																					placeholder={
																						!props?.isPrint ? 'Select Email' : ''
																					}
																					convertValueToUpperCase={false}
																					enableEmptySelection={true}
																					disabled={
																						props?.isPrint ||
																					!contractForm.watch(
																						'performerId.value'
																					)
																					}
																					options={getPerformerEmailsOptions()}
																					handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerEmailId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.performerEmailId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
														<br></br>
														<h4>TALENT AGENT INFO</h4>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td width={160}>Talent Agency Name</td>
																<td>
																	<FormControl className={styles.tableForms} fullWidth >
																		<Controller
																			key={`agency-cr-${contractForm.watch('performerId.value')}`}
																			control={contractForm.control}
																			name='talentAgencyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						data-cy='ad_agency'
																						key={`agency-${contractForm.watch('performerId.value')}`}
																						{...field}
																						placeholder={
																							!props?.isPrint ? 'Ad-Agency' : ''
																						}
																						filterProperty='CompanyNames.Name'
																						APICaller={(filter) => {
																							filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																							return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																						}}
																						optionGenerator={
																							companyOptionGenerator
																						}
																						isClearable={true}
																						isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('talentAgencyAddressId', null, { shouldDirty: true } );
																							contractForm.setValue('talentAgencyEmailId', '', { shouldDirty: true } );
																							contractForm.setValue('talentAgencyPhoneId', '', { shouldDirty: true } );
																							contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true } );
																							contractForm.trigger('talentAgencyStaffId' );
																							contractForm.trigger('talentAgencyAddressId' );
																							contractForm.trigger('talentAgencyEmailId');
																							contractForm.trigger('talentAgencyPhoneId');
																						}}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('talentAgencyId.value')){
																								contractForm.setValue('talentAgencyId', options[0], { shouldDirty: true});
																								contractForm.trigger('talentAgencyId', { shouldFocus: true});
																							}
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && contractForm.watch('performerId.value') && <div style={{float:'right'}}><AddTalentAgentInline
																						onCompanyCreated={(createdAgent) => contractForm.setValue('talentAgencyId', createdAgent, { shouldDirty: true})}
																						onClose={() => setCompanyPopoverInline({ isOpen: false})}
																						onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget, for: 'talentAgency' })}
																						event={companyPopoverInline?.event}
																						isOpen={companyPopoverInline.isOpen && companyPopoverInline.for === 'talentAgency'}
																						personId={contractForm.watch('performerId.value') || ''}
																					/></div>}
																				</>
																			)}
																		/>
																	</FormControl>
																</td>
																<td width={160}>Talent Agent Name</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			key={`staff-${contractForm.watch(
																				'talentAgencyId.value'
																			)}-${contractForm.watch(
																				'talentAgencyStaffId.value'
																			)}`}
																			control={contractForm.control}
																			name='talentAgencyStaffId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						{...field}
																						filterProperty='PersonName.FirstName'
																						APICaller={(filter) =>
																							getAllStaffOfCompany(
																								contractForm.watch(
																									'talentAgencyId.value'
																								) || '',
																								filter
																							)
																						}
																						optionGenerator={
																							performerOptionGenerator
																						}
																						isDisabled={
																							props?.isPrint ||
																						!contractForm.watch(
																							'talentAgencyId.value'
																						)
																						}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions={false}
																						defaultOptions
																					/>
																				</>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.talentAgencyStaffId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.talentAgencyStaffId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={140}>Agency Address</td>
																<td colSpan={3}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`agency-address-cr-${contractForm.watch(
																				'talentAgencyId.value'
																			)}`}
																			name='talentAgencyAddressId'
																			render={({ field }) => (
																				<LookAheadSelect
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					{...field}
																					placeholder={
																						!props?.isPrint
																							? 'Select Address'
																							: ''
																					}
																					key={`agency-address-${contractForm.watch(
																						'talentAgencyId.value'
																					)}`}
																					filterProperty='Line1'
																					sortField='IsPrimary'
																					sortDescending={true}
																					onOptionLoaded={(options) => {
																						if(options?.length && !contractForm.watch('talentAgencyAddressId.value')){
																							contractForm.setValue('talentAgencyAddressId', options[0], { shouldDirty: true});
																							contractForm.trigger('talentAgencyAddressId', { shouldFocus: true});																						
																						}
																					}}
																					APICaller={(filterModel) =>
																						getAllAddressByCompany(
																							filterModel,
																							contractForm.watch(
																								'talentAgencyId.value'
																							) || ''
																						)
																					}
																					optionGenerator={
																						adAgencyAddressOptionGenerator
																					}
																					isDisabled={
																						!contractForm.watch(
																							'talentAgencyId.value'
																						) || props?.isPrint
																					}
																					dropDownsize={50}
																					isClearable={true}
																					isSearchText={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.setValue(
																							'talentAgencyEmailId',
																							'',
																							{ shouldDirty: true }
																						);
																						contractForm.setValue(
																							'talentAgencyPhoneId',
																							'',
																							{ shouldDirty: true }
																						);
																						contractForm.trigger(
																							'talentAgencyEmailId'
																						);
																						contractForm.trigger(
																							'talentAgencyPhoneId'
																						);
																					}}
																					cacheOptions={false}
																					defaultOptions
																				/>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.talentAgencyAddressId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors
																						?.talentAgencyAddressId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td>Agent Phone</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-phone-ctr${contractForm.watch(
																				'talentAgencyPhoneId'
																			)}-${contractForm.watch(
																				'talentAgencyAddressId.value'
																			)}`}
																			name='talentAgencyPhoneId'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					key={`select-phone-${contractForm.watch(
																						'talentAgencyPhoneId'
																					)}-${contractForm.watch(
																						'talentAgencyAddressId.value'
																					)}`}
																					placeholder={
																						!props?.isPrint ? 'Select Phone' : ''
																					}
																					convertValueToUpperCase={false}
																					enableEmptySelection={true}
																					disabled={
																						props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
																					}
																					options={getPhoneNumberOptions(
																						contractForm.watch(
																							'talentAgencyAddressId.value'
																						)
																					)}
																					handleDefaultSelection={() => !contractForm.watch('talentAgencyPhoneId')}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.talentAgencyPhoneId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.talentAgencyPhoneId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>Agent Email</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-phone-ctr${contractForm.watch(
																				'talentAgencyEmailId'
																			)}-${contractForm.watch(
																				'talentAgencyAddressId.value'
																			)}`}
																			name='talentAgencyEmailId'
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					key={`select-phone-ctr${contractForm.watch(
																						'talentAgencyEmailId'
																					)}-${contractForm.watch(
																						'talentAgencyAddressId.value'
																					)}`}
																					placeholder={
																						!props?.isPrint ? 'Select Email' : ''
																					}
																					convertValueToUpperCase={false}
																					enableEmptySelection={true}
																					disabled={
																						props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
																					}
																					options={getCompanyEmailOptions(
																						contractForm.watch(
																							'talentAgencyAddressId.value'
																						)
																					)}
																					handleDefaultSelection={() => !contractForm.watch('talentAgencyEmailId')}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.talentAgencyEmailId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.talentAgencyEmailId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															
															</tr>
														</table>
														<br></br>
														<p>
															<b>PAYMENTS MAILED TO:</b> Performer authorizes
														payments to which Performer may be entitled
														hereunder, as follows:
														</p>
														<br></br>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																gap: '10px',
															}}
														>
															<Controller
																control={contractForm.control}
																name='isPaymentAtAgentAddress'
																defaultValue={false}
																render={({ field }) => (
																	<div
																		onClick={props?.isPrint ? undefined : () => field.onChange(false)}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value={field.value + ''}
																				checked={!field.value}
																				onChange={() => field.onChange(false)}
																				type='checkbox'
																				disabled={props?.isPrint}
																				style={{marginTop: '3px' }}
																			/>
																			<span>
																		To performer at address listed above
																			</span>
																		</Box>
																	</div>
																)}
															/>
														Or
															<Controller
																control={contractForm.control}
																name='isPaymentAtAgentAddress'
																defaultValue={false}
																render={({ field }) => (
																	<div
																		onClick={props?.isPrint ? undefined : () => field.onChange(true)}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value={field.value + ''}
																				checked={field.value}
																				onChange={() => field.onChange(true)}
																				type='checkbox'
																				disabled={props?.isPrint}
																				style={{marginTop: '3px' }}
																			/>
																			<span>To Agent at address listed above</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</div>
												</div>
											</>
											{/* Main Contract Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
									&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
									Cancel
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
									Save & Duplicate <CopyAll />
									</NabuButton>
								&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm
				handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)}
				noOfTalentsSelected={noOfTalentsSelected}
				setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)}
			/>
		</>
	);
});

export default NonUnionTalentAgreement;
