import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect, {
	ILookAheadSelectOption,
} from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { ContractStatus, ContractStatusOptions, ContractUnionType, DefaultPageSize, SagAftraTVPriHTPerformerTypeOptions } from '@/common/constants/constants';
import { OPERATORS, UNION_TYPE } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import useScrollToField from '@/common/hooks/useScrollToField';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import { IBaseContract, ICommercialDetail, IContractSagAftraTvPrincipalForm, IPacketControlForm } from '@/common/types/contract';
import { FilterModel, IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	displayOnlyEmail,
	getBaseUrl,
	getCompanyDisplayName,
	getLocaleDate,
	getLocaleDateTime,
	getNotificationText,
	getPersonFullName,
	mutateContractAdditionalFormsData,
	sortEmailOrPhoneNumberForDropDown
} from '@/common/utils/utils';
import { EmailFormModel } from '@/modules/company/shared/models/company';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getAllAssetForBasicDetail, getAllSignatory, retriveAssetProducts } from '@/services/asset';
import {
	addNewCompanyEmail,
	getAllAddressByCompany,
	getAllProducts,
	getCompanyBasicDetails,
	getProducerCompanyForContract
} from '@/services/company';
import { addContractSagAftraTvPrincipal, addMultipleContract, updateContractSagAftraTvPrincipal } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import {
	GetByWithFullNameSearchForBasicDetail,
	createPersonEmail,
	getAgentInfo,
	getAllAddressByPerson
} from '@/services/person';
import store, { IStore } from '@/store';
import { setLoader } from '@/store/app/reducer';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	Add,
	CopyAll,
	DeleteForever,
	HelpOutline,
	NoteAdd,
	Save
} from '@mui/icons-material';
import {
	Autocomplete,
	Box, Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
	Radio,
	RadioGroup,
	TextField,
	Tooltip
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
	Controller,
	UseFormReturn,
	useFieldArray,
	useForm,
} from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { StandardSagAftraTvPrincipalSchema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import LocationFreeTextSelect from './locationFreeTextSelect';

export interface IContractFormState {
	isPacketOpen?: boolean;
}

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractSagAftraTvPrincipalForm;
	defaultValue?: IBaseContract | any;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	assets?: IAssetRecord[];
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
}

type IAddressPopOverState = { isPayrollVendorAddressOpen?: boolean; isAdAgencyAddressOpen?: boolean, isPerformerAddressOpen?: boolean, performerAdAgencyAddressOpen?: boolean, isProducerAddressOpen?: boolean,  event?: HTMLButtonElement | null, for?: string };

// eslint-disable-next-line react/display-name
const SagAftraTvPrincipal = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	const [, setCancel] = useState<boolean>(false);
	useImperativeHandle(ref, () => ({
		handleContractSdSagAftraTvPrincipalFormCancel: (data: IContractSagAftraTvPrincipalForm, statusValue: string) => {
			if(data){
				setCancel(true);
				const commercials = data?.commercials?.map((d) => ({
					...d,
					assetId: d?.id,
				}));
				const workDays = data?.workDays?.filter((d) => d?.date) || [];

				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractSagAftraTvPrincipal: undefined,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					adAgencyId: data?.adAgencyId?.value,
					advertiserId: data?.advertiserId?.value,
					performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
					performerAdAgencyId: data?.performerAdAgencyId?.value,
					performerAddressId: data?.performerAddressId?.value,
					performerId: data?.performerId?.value,
					producerCompanyId: data?.producerCompanyId?.value,
					producerAddressId: data?.producerAddressId?.value,
					productId: data?.productId?.map((product) => product?.value),
					// returnToEmail: data?.returnToEmail.value,
					payrollVendorId: data?.payrollVendorId?.value,
					payrollVendorAddressId: data?.payrollVendorAddressId?.value || '',
					commercials,
					workDays
				};
				editContractDetails(submitData);
			}
		},
	}));

	const DEFAULT_COUNTRY_CODE = 'US';

	const NON_EVENING_WEAR_COST = 20.36;
	const EVENING_WEAR_COST = 33.91;

	const notification = useNotification();

	const LOGIN_USER_NAME = useSelector((store: IStore) => store?.userContext)
		?.userInfo?.userName;

	const contractForm = useForm<IContractSagAftraTvPrincipalForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}],
			}
			: {...(props?.initialValue || {}), 
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials : [{}], 
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
			},
		resolver: yupResolver(StandardSagAftraTvPrincipalSchema),
		shouldFocusError: false
	});

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
		if(props?.defaultValue?.productId)
			contractForm.setValue('productId', props?.defaultValue?.productId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	useEffect(() => {
		contractForm.setValue('isJPCAuthorizer', props?.defaultValue?.isJPCAuthorizer, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.isJPCAuthorizer]);

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch('contractNYTheftPreventionForm'),
			isCATheftPreventionForm: !!contractForm.watch('contractCATheftPreventionForm'),
		},
	});

	const [companyOptions, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(props?.people || []);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(
		props?.assets || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [selectedAddress, setSelectedAddress] = useState<{id:any, type:any}>();
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});

	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];

	// helper functions
	const getCommercialFilters = () => {
		const assetFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return assetFilter;
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			// cb((prev) => {
			// 	const oldFilter = prev?.filter(
			// 		(d) => !responseData?.some((r) => r?.id === d?.id)
			// 	);

			// 	if(type === 'ADDRESS'){
			// 		return [...responseData];
			// 	}
				
			// 	return [...oldFilter, ...responseData];
			// });
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const productOptionGenerator = (responseData) => {
		return (Array.isArray(responseData) ? responseData : [] )?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const addressOptionGenerator = (responseData: IPersonAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const agentAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const producerAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	useEffect(() => {
		if(selectedAddress?.type !== ''){
			onAddressChange(selectedAddress?.id, selectedAddress?.type);
		}
	}, [addressOptions]);

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	
	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
		[...(personOptions || []), ...(props?.people || [])]?.find(
			(a) => a?.id === contractForm.watch('performerId.value')
		)?.emails || [];

		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {...contractForm.watch(
				`workDays.${workDayFieldArray.fields.length - 1}`
			), id: undefined};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1) : '',
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};

	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	// const getProducts = async () => {
	// 	const commercials = contractForm.watch('commercials');
	// 	let totalProducts: any = [];
		
	// 	if(commercials){
	// 		for(let i = 0; i < commercials.length; i++){
	// 			const filterModel = {
	// 				pageSize: DefaultPageSize,
	// 				sortDescending: false,
	// 				sortField: 'CreatedAt',
	// 				pageNumber: 0,
	// 				filters: [{
	// 					property: 'Asset.Id',
	// 					value: commercials[i].assetId?.value ?? '',
	// 					operator: OPERATORS.EQUAL,
	// 					isCaseSensitive: false
	// 				}]
	// 			};
		
	// 			const res: any = await retriveAssetProducts(filterModel);
		
	// 			if(res?.data?.records){
	// 				const products: any = res?.data?.records?.map(product => {
	// 					return { value: product.id, label: product.productName };
	// 				});
					
	// 				totalProducts = [...totalProducts, ...products];
	// 			}
	// 		}

	// 		contractForm.setValue('productId', totalProducts, { shouldDirty: true, shouldValidate: true });
	// 	}
	// };


	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	// const [updatedCities,getUpdatedCities]= useState<any>();

	const getCityOptions = (stateCode?: string) =>
	// 	if (!stateCode) {
	// 		return [];
	// 	}
	// 	const citiesData = City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode);
	// 	const cityOptions = citiesData.map((city: any) => {
	// 		return {label: city.name, value: city.name.toUpperCase()};
	// 	});
	// 	if(placeName && placeName != '' && !cityOptions.find(x => x.value === placeName)){
	// 		cityOptions.push({label: placeName, value: placeName.toUpperCase()});
	// 	}
	// 	getUpdatedCities(cityOptions)
	// };
		stateCode ? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
			label: city.name?.toUpperCase(),
			value: city?.name?.toUpperCase(),
		})) || [] : [];

	const getUnions = (): any[] =>
		[UNION_TYPE.AFM, UNION_TYPE.SAG_AFTRA].map((value) => ({
			label: value,
			value: value,
		})) || [];

	const getUnionAgreement = (unionType: string): any[] =>
		ContractUnionType[unionType]?.agreements?.map((op) => ({
			label: op?.toUpperCase(),
			value: op?.toUpperCase(),
		})) || [];

	const setJPCAuthorizer = () => {
		const adAgency = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('adAgencyId.value')
		)?.isJPCAuthorizer;
		const advertiser = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('advertiserId.value')
		)?.isJPCAuthorizer;

		contractForm.setValue('isJPCAuthorizer', adAgency || advertiser, {
			shouldDirty: true,
		});
	};

	const calculateTotalWardrobeFee = () => {
		const getNumber = (value: number | string | undefined): number =>
			isNaN(Number(value)) ? 0 : Number(value);

		const eveningWearCost =
			getNumber(contractForm.watch('eveningWearCount')) * EVENING_WEAR_COST;
		const nonEveningWearCost =
			getNumber(contractForm.watch('nonEveningWearCount')) *
			NON_EVENING_WEAR_COST;

		contractForm.setValue(
			'totalWardrobeFee',
			parseFloat((eveningWearCost + nonEveningWearCost).toFixed(4)),
			{ shouldDirty: true }
		);
	};

	const getAssetAdIdByAssetIndex = (index: number) => {
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.adId || '';
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find(
				(a) => a?.id === addressId
			)?.emails || [];

		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);

		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

		
	const onAddressChange = (addressId: string, field: 'performer' | 'performerAdAgency' | 'producerAddress', createdAddress?: ICompanyAddress | IPersonAddress) => {
		setSelectedAddress({type: field, id: addressId});

		const address = [...addressOptions, createdAddress]?.find( ad => ad?.id === addressId);	
		if(address) {
			contractForm.setValue(`${field}State`, address?.state, {shouldDirty: true});
			contractForm.setValue(`${field}Zip`, address?.zipCode, {shouldDirty: true});
			contractForm.setValue(`${field}City`, address?.city?.toUpperCase(), {shouldDirty: true});
			contractForm.trigger(`${field}City`);				
		} else {
			contractForm.setValue(`${field}State`, '', {shouldDirty: true});
			contractForm.setValue(`${field}Zip`, '', {shouldDirty: true});
			contractForm.setValue(`${field}City`, '', {shouldDirty: true});
		}
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${packetControlForm.watch(key) ? 'Added to' : 'Removed from'} Packet.`,
			type: 'info',
		});
	};

	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};
	
	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	useQuery({
		queryFn: () =>
			GetByWithFullNameSearchForBasicDetail(
				new FilterModel([
					{
						isCaseSensitive: false,
						operator: OPERATORS.EQUAL,
						property: 'UserName',
						value: LOGIN_USER_NAME || '',
					},
				])
			),
		enabled: !!LOGIN_USER_NAME,
		refetchOnWindowFocus: false,
		onSuccess: (res) => {
			setPersonOptions(res?.data?.records || []);
			const email =
				res?.data?.records?.at(0)?.emails?.find((d) => !!d?.isPrimary) ||
				res?.data?.records?.at(0)?.emails?.find((d) => !!d?.emailId);
			const name = getPersonFullName(res?.data?.records?.at(0)?.personName);
			// if (email?.emailId && email?.id) {
			// 	contractForm.setValue('returnToEmail', {
			// 		label: `${name} (${email?.emailId})`,
			// 		value: email?.id,
			// 	}, { shouldDirty: true});
			// }
		},
	});

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractSagAftraTvPrincipal(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});
	
	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractSagAftraTvPrincipal(props?.contractId || '', payload),
			onSuccess: (res, variables) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					if(variables?.status === ContractStatus.Cancelled){
						notification.notify({
							message: 'The contract has been voided successfully',
							type: 'success',
						});
					}
					else {
						notification.notify({
							message: getNotificationText('Contract', 'UPDATE'),
							type: 'success',
						});
					}
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: addMultipleContractDetails} =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addMultipleContract(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					const baseURL = getBaseUrl(window.location.href);
					const contractIds : any = res?.data || [];
					const urls =  contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

					urls.forEach((url) => {
						const a = document.createElement('a');	
						a.href = url;
						a.target = '_blank';
						const evt = document.createEvent('MouseEvents');
						evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
						a.dispatchEvent(evt);
					});
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
				} else
					notification.notify({
						message:
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (data: IContractSagAftraTvPrincipalForm, handleMultipleContractAdd?: CallableFunction) => {
		
		const commercials = data?.commercials?.map((d) => ({
			...d,
			assetId: d?.assetId?.value,
		}));

		const workDays = data?.workDays?.filter((d) => d?.date) || [];

		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}		
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}		
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}		
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}		
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}		
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;

			store.dispatch(setLoader(true));
		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		}
		finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value || '',
				contractSagAftraTvPrincipal: undefined,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				adAgencyId: data?.adAgencyId?.value,
				advertiserId: data?.advertiserId?.value,
				performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
				performerAdAgencyId: data?.performerAdAgencyId?.value,
				performerAddressId: data?.performerAddressId?.value,
				performerId: data?.performerId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				producerAddressId: data?.producerAddressId?.value,
				productId: data?.productId?.map(product => product?.value),
				// returnToEmail: data?.returnToEmail?.value,
				payrollVendorId: data?.payrollVendorId?.value,
				commercials,
				workDays
			};
			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};


	const onSubmit = (data: IContractSagAftraTvPrincipalForm) => {
		handleContractSubmission(data);
	};

	const [contractFormData, setContractFormData] = useState<IContractSagAftraTvPrincipalForm>();

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partPlayed: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerTypes:talent?.perfCategory?.toUpperCase(),
						performerAddressId:undefined,
						// performerEmail:undefined,
					};
					return submitData;
				});
				const multipleContractData = {
					ContractAddSagAftraTvPrincipalModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	const handleSaveAndDuplicate = (data: IContractSagAftraTvPrincipalForm) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const [addressPopOver, setAddressPopOver] = useState<IAddressPopOverState>({
		isAdAgencyAddressOpen: false,
		isPayrollVendorAddressOpen: false,
		isPerformerAddressOpen: false,
		performerAdAgencyAddressOpen: false,
		isProducerAddressOpen:false
	});

	const onAddressCreated = (createdAddress: ICompanyAddress, companyId, addressField) => {
		setCompanyOptions((prev) => {
			const company = companyOptions.find(
				(op) => op.id === contractForm.watch(companyId)
			);

			!company?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				company?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === company?.id ? company : c));
		});
		contractForm.setValue(addressField, { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = prev.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
		onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer', createdAddress);
	};

	const onPerformerAdAgencyAddressCreated = (createdAddress: ICompanyAddress) => {
		handleStateOptions([createdAddress], 'ADDRESS');
		setCompanyOptions((prev) => {
			const company = companyOptions.find(
				(op) => op.id === contractForm.watch('performerAdAgencyId.value')
			);

			!company?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				company?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === company?.id ? company : c));
		});

		contractForm.setValue('performerAdAgencyPhoneId', undefined, { shouldDirty: true});
		contractForm.trigger('performerAdAgencyPhoneId');
		contractForm.setValue('performerAdAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
		onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency', createdAddress);
	};

	useEffect(()=> {
		if(props?.isEdit && commercialFieldArray.fields.length === 0){
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const COMPENSATION_OPTIONS = [ 'SCALE', 'DOUBLE SCALE' ];

	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmail', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const onProducerEmailInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('producerAddressId.value') || '';
		addNewCompanyEmail(addressId, {...formData, isPrimary: true}).then((res: any) =>{
			if(res?.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('producerAddressId.value')
							? {
								...ad,
								emails: [
									{ id: res?.data?.id, ...formData },
									...(ad?.emails || []),
								],
							}
							: ad
					)
				);
				contractForm.trigger('producerAddressId', { shouldFocus: true });
				contractForm.setValue('producerEmail', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const handleCommercialChange = async (id: string) => {
		const filterModel = {
			pageSize: DefaultPageSize,
			sortDescending: false,
			sortField: 'CreatedAt',
			pageNumber: 0,
			filters: [{
				property: 'Asset.Id',
				value: id,
				operator: OPERATORS.EQUAL,
				isCaseSensitive: false
			}]
		};

		const res: any = await retriveAssetProducts(filterModel);

		if(res?.data?.records){
			const existingProducts: ILookAheadSelectOption[] = contractForm.getValues('productId') ?? [];
			let products: any = res?.data?.records?.map(product => {
				return { value: product.id, label: product.productName, assetId: id };
			});

			products = products.filter(product => {
				const foundProduct = existingProducts.find(eProduct => eProduct.value === product.value);
				if(foundProduct) return false;
				else return true;
			});

			contractForm.setValue('productId', [...products, ...existingProducts], { shouldDirty: true, shouldValidate: true });
		}
	};


	const onProducerAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('producerCompanyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('producerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const handleProducerChange = (index ?: any)=>{
		if(index ==0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerEmail','',{shouldDirty:true});
			contractForm.setValue('producerName','',{shouldDirty:true});
		}
		if(contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerEmail','',{shouldDirty:true});
			contractForm.setValue('producerName','',{shouldDirty:true});
		}
	};

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id ='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}> Government & Payroll forms</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isNYTheftPreventionForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isNYTheftPreventionForm');
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isCATheftPreventionForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isCATheftPreventionForm');
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen ? undefined : styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid  container item columnSpacing={3}>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>Contract Name<sup>*</sup></FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox 
																				{...field}
																				placeholder={!props?.isPrint ? 'Contract Name' : ''}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				{...field}
																				placeholder={!props?.isPrint ? 'Payroll Vendor' : ''}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions
																				defaultOptions
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors?.payrollVendorId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>

																			<LookAheadSelect
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																				
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isPayrollVendorAddressOpen || false}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isPayrollVendorAddressOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isPayrollVendorAddressOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</>
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors?.payrollVendorAddressId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Select Status' : ''}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Union
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='unionType'
																	defaultValue={undefined}
																	render={({ field }) => (
																		<NabuSelect
																			{...field}
																			enableEmptySelection={true}
																			placeholder='Select Union'
																			options={getUnions()}
																		/>
																	)}
																/>
																{contractForm.formState?.errors?.unionType?.message && (
																	<FormHelperText error={true}>
																		{contractForm.formState.errors.unionType.message}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														{getUnionAgreement(contractForm.watch('unionType'))?.length > 0 && (
															<Grid item sm={12} md={6} lg={2.4}>
																<FormControl fullWidth>
																	<FormLabel>
											Union Agreement <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='unionAgreement'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuSelect
																				{...field}
																				placeholder='Select Agreement'
																				options={getUnionAgreement(
																					contractForm.watch('unionType')
																				)}
																			/>
																		)}
																	/>
																	{contractForm.formState?.errors?.unionAgreement?.message && (
																		<FormHelperText error={true}>
																			{contractForm.formState.errors.unionAgreement.message}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
														)}
													</Grid>
												</div>
											)}
											{/* Main Form Start */}
											<div className={styles.contractFormBlock}>
												<div className={styles.bgWhite} style={{padding:'20px'}}>
													<tr>
														<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
													</tr>
													<Grid item container columnSpacing={3}>
														<Grid item md={6} >
															<Box sx={{pt:4}}>
																<h2 style={{textAlign:'left'}}>EXHIBIT A-1</h2>
																<h4 style={{textAlign:'left'}}>2022 STANDARD SAG-AFTRA EMPLOYMENT <br></br> CONTRACT FOR COMMERCIALS </h4>
															</Box>
														</Grid>
														<Grid item md={6}>
															<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
																<tr>
																	<td>Ad Agency<sup>*</sup></td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='adAgencyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='ad_agency'
																							{...field}
																							placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							defaultFilter={AD_AGENCY_FILTER}
																							isClearable={true}
																							isDisabled={props?.isPrint}
																							onChange={(e: any) => {
																								field.onChange(e);
																								setJPCAuthorizer();
																								// contractForm.setValue('adAgencyAddressId', null, { shouldDirty: true});
																							}}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{mt:1.3}}>
																								<AddCompanyInline
																									title='Company'
																									companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.adAgencyId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors?.adAgencyId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td>Producer{ !props?.isPrint && (
																		<Tooltip
																			placement='right-end'
																			title={'Signatory'}
																		>
																			<HelpOutline fontSize='inherit' />
																		</Tooltip>)
																	} <sup></sup></td>
																	<td> 
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				key={`commercial-assetId
																				-${contractForm.watch(`commercials.${0}.assetId.value`)}
																				`}
																				control={contractForm.control}
																				name='producerCompanyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							key={`commercial-assetId
																							-${contractForm.watch(`commercials.${0}.assetId.value`)}
																							`}
																							{...field}
																							placeholder={!props?.isPrint ? 'Producer' : ''}
																							filterProperty='CompanyNames.Name'
																							APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																							optionGenerator={companyOptionGenerator}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('producerAddressId', null, { shouldDirty: true});
																								contractForm.setValue('producerEmail', '', { shouldDirty: true});
																								contractForm.trigger('producerAddressId');
																								contractForm.trigger('producerEmail');
																								contractForm.setValue('producerName','',{shouldDirty:true});
																								onAddressChange(contractForm.watch('producerAddressId.value') || '', 'producerAddress');
																							}}
																							isDisabled={props?.isPrint}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{/* {!props?.isPrint && (
																							<Box sx={{mt:0.5}}>
																								<AddCompanyInline
																									title='Company' />
																							</Box>
																						)} */}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.producerCompanyId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper-producerCompanyId'
																				>
																					{
																						contractForm.formState?.errors
																							?.producerCompanyId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td>Product</td>
																	<td>
																		<FormControl className={styles.tableFormsSelect} fullWidth >
																			<Controller
																				control={contractForm.control}
																				name='productId'
																				render={({ field }) => (
																					<LookAheadSelect
																						{...field}
																						isMulti
																						placeholder={!props?.isPrint ? 'Select Product' : ''}
																						key={`product-${contractForm.watch(
																							'advertiserId.value'
																						)}`}
																						filterProperty='ProductName'
																						APICaller={(filterModel) =>
																							getAllProducts(
																								filterModel,
																								contractForm.watch('advertiserId.value')
																							)
																						}
																						optionGenerator={productOptionGenerator}
																						isDisabled={
																							!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
																						}
																						defaultFilter={PRODUCT_FILTER}
																						dropDownsize={20}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																				)}
																			/>
																		</FormControl>
																	</td>
																</tr>

															</table>
															<div style={{ display: 'flex' }}>
																<Controller
																	control={contractForm.control}
																	name='isJPCAuthorizer'
																	render={({ field }) => (
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				key={`jpc-${contractForm.watch(
																					'adAgencyId.value'
																				)}-${contractForm.watch(
																					'advertiserId.value'
																				)}`}
																				value={`${field.value}`}
																				checked={field.value || false}
																				onChange={undefined}
																				type='checkbox'
																				style={{
																					marginTop: '3px',
																				}}
																			/>
																			<span>
																						JPC AUTHORIZER
																			</span>
																		</Box>
																	)}
																/>
															</div>
														</Grid>
													</Grid>
													<hr></hr>
													<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
														<tr>
															<td width={75}>Job #</td>
															<td width={250}>
																<FormControl fullWidth className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='jobTitle'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuTextbox
																				data-cy='job_name_textbox'
																				{...field}
																				disabled={props?.isPrint}
																				placeholder={!props?.isPrint ? 'Job' : ''}
																			/>
																		)}
																	/>
																	{/* {contractForm.formState.errors?.jobTitle
																					?.message && (
																					<FormHelperText
																						data-cy='errorHelper-job-title-name'
																						error={true}
																					>
																						{
																							contractForm.formState?.errors?.jobTitle
																								.message
																						}
																					</FormHelperText>
																				)} */}
																</FormControl>
															</td>
															<td width={300}>COMMERCIAL TITLE(S) AND Ad-ID®(s)<sup>*</sup></td>
															<td>
																{commercialFieldArray.fields.map((field, index) => (
																	<div key={`commercial-${field.id}`}>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`commercials.${index}.assetId`}
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='source-asset'
																							{...field}
																							key={`commercial-${contractForm.watch(`commercials.${index}.assetId`)}`}
																							placeholder={!props?.isPrint ? 'Commercial' : ''}
																							filterProperty='Title'
																							isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
																							defaultFilter={getCommercialFilters()}
																							APICaller={(filterModel) => getAllAssetForBasicDetail(filterModel, false)}
																							optionGenerator={
																								lookAheadAssetOptionGenerator
																							}
																							onChange={(e: any) => { handleCommercialChange(e?.value), field.onChange(e);
																								contractForm.setValue('commercialTitle',contractForm.watch(`commercials.${index}.assetId.label`), { shouldDirty: true });
																								handleProducerChange(index);
																							}}
																							isClearable={true}
																							cacheOptions
																							defaultOptions
																							dropDownsize={25}
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{mt: 1}}>
																								<AddAssetInline
																									title='Asset' />
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.commercials
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState.errors?.commercials[index]?.assetId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		{(contractForm.watch(`commercials.${index}.assetId`)) && (<div>
																			<span>AD-ID</span>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<NabuTextbox
																					value={getAssetAdIdByAssetIndex(index)}
																				/>
																			</FormControl>
																		</div>)}
																	</div>
																))}
																				
															</td>
																				
																		
														</tr>
													</table>
													<br></br>
													<table
														width={'100%'}
														border={1}
														cellPadding={0}
														cellSpacing={0}
														className={`${styles.contractFormsInner} keep-together`}
													>
														<tr style={{ background: '#808080' }}>
															<th className={styles.formTableBorder} colSpan={2}>
													Dates Worked
															</th>
															<th className={styles.formTableBorder} colSpan={2}>
													Work Time
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'space-around',
																	}}
																>
																	<span>From</span> <span>To</span>{' '}
																</div>
															</th>
															<th className={styles.formTableBorder} colSpan={2}>
													Meals
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'space-around',
																	}}
																>
																	<span>From</span> <span>To</span>{' '}
																</div>
															</th>
															<th className={styles.formTableBorder} colSpan={2}>
													Travel to Location
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'space-around',
																	}}
																>
																	<span>From</span> <span>To</span>{' '}
																</div>
															</th>
															<th className={styles.formTableBorder} colSpan={2}>
													Travel from Location
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'space-around',
																	}}
																>
																	<span>From</span> <span>To</span>{' '}
																</div>
															</th>
															<th className={styles.formTableBorder} colSpan={2}>
                                    FITTINGS, MAKEUP
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'space-around',
																	}}
																>
																	<span>From</span> <span>To</span>{' '}
																</div>
															</th>
															{!props?.isPrint && (
																<th style={{ textAlign: 'center' }}></th>
															)}
														</tr>
														{workDayFieldArray.fields.map((field, index) => (
															<tr key={`work-field-${field.id}`}>
																<td height={40} colSpan={2}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch(`workDays.${index}.date`) || '')}</p>
																		) :(<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.date`}
																			defaultValue=''
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Date' : ''}
																					onChange={(e) => {
																						field.onChange(e);
																						contractForm.setValue('dateAndHourEmployment', getLocaleDate(e) || '', { shouldDirty: true });
																					}}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.date?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.workTimeStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.workTimeStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.workTimeStartAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.workTimeEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeEndAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.workTimeEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.workTimeEndAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.mealTimeStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeStartAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.mealTimeStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.mealTimeStartAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.mealTimeEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeEndAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.mealTimeEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.mealTimeEndAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.travelTimeToLocationStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationStartAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.travelTimeToLocationStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.travelTimeToLocationStartAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.travelTimeToLocationEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationEndAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.travelTimeToLocationEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.travelTimeToLocationEndAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.travelTimeFromLocationStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationStartAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.travelTimeFromLocationStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.travelTimeFromLocationStartAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.travelTimeFromLocationEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationEndAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.travelTimeFromLocationEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.travelTimeFromLocationEndAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl className={styles.tableForms} fullWidth>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.makeUpTimeStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeStartAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.makeUpTimeStartAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.makeUpTimeStartAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{/* <Controller
																			control={contractForm.control}
																			name={`workDays.${index}.makeUpTimeEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/> */}
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
																		) : (<Controller
																			control={contractForm.control}
																			name={`workDays.${index}.makeUpTimeEndAt`}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disableOpenPicker={true}
																					disabled={!(contractForm.watch(`workDays.${index}.date`))}
																				/>
																			)}
																		/>)}
																		{/* {!!contractForm.formState?.errors?.workDays
																						?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.workDays[
																									index
																								]?.makeUpTimeEndAt?.message
																							}
																						</FormHelperText>
																					)} */}
																	</FormControl>
																</td>
																{!props?.isPrint && (
																	<>
																		<td style={{ display: 'flex' }}>
																			{workDayFieldArray.fields.length - 1 ===
																	index && (
																				<NabuButton
																					variant='text'
																					onClick={onAddWorkDay}
																				>
																					<Add />
																				</NabuButton>
																			)}
																			{workDayFieldArray?.fields.length > 1 && (
																				<NabuButton
																					variant='text'
																					onClick={() => onRemoveWorkDay(index)}
																				>
																					<DeleteForever color='error' />
																				</NabuButton>
																			)}
																		</td>
																	</>
																)}
															</tr>
														))}
													</table>
													<br></br>
													<Grid item container columnSpacing={3}>
														<Grid item md={6} sx={{pt:2}}>
															<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																<span><b>Multiple Tracking or Sweetening:</b></span>
																<Controller
																	control={contractForm.control}
																	name='isMultiTracking'
																	defaultValue={false}
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(true)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value={field.value + ''}
																					checked={field.value}
																					onChange={() => field.onChange(true)}
																					disabled={props?.isPrint}
																					type='checkbox'
																					style={{ marginTop: '2px' }}
																				/>
																				<span>did occur </span>
																			</Box>
																		</div>
																	)}
																/>
																<Controller
																	control={contractForm.control}
																	name='isMultiTracking'
																	defaultValue={false}
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(false)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					type='checkbox'
																					{...field}
																					value={field.value + ''}
																					checked={!field.value}
																					disabled={props?.isPrint}
																					onChange={() => field.onChange(false)}
																					style={{ marginTop: '2px' }}
																				/>
																				<span>did not occur </span>
																			</Box>
																		</div>
																	)}
																/>
															</div>
														</Grid>
														<Grid item md={6}>
															<Box sx={{float:'right'}}>
																<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																	<span>Performer’s Signature or Initials:</span>	 
																	<FormControl className={styles.tableForms}>
																		<NabuTextbox disabled={true} />
																	</FormControl>
																</div>
															</Box>
														</Grid>
													</Grid>
													<hr></hr>

													<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
														<span>Between</span> 
														<FormControl
															className={styles.tableForms}
															sx={{minWidth:'250px'}}
														>
															<Controller
																key={`commercial-assetId
																-${contractForm.watch(`commercials.${0}.assetId.value`)}
																`}
																control={contractForm.control}
																name='producerCompanyId'
																render={({ field }) => (
																	<>
																		<LookAheadSelect
																			key={`commercial-assetId
																			-${contractForm.watch(`commercials.${0}.assetId.value`)}
																			`}
																			{...field}
																			placeholder={!props?.isPrint ? 'Producer' : ''}
																			filterProperty='CompanyNames.Name'
																			APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																			optionGenerator={companyOptionGenerator}
																			isClearable={true}
																			onChange={(e: any) => {
																				field.onChange(e);
																				contractForm.setValue('producerAddressId', null, { shouldDirty: true});
																				contractForm.setValue('producerEmail', '', { shouldDirty: true});
																				contractForm.trigger('producerAddressId');
																				contractForm.trigger('producerEmail');
																				onAddressChange(contractForm.watch('producerAddressId.value') || '', 'producerAddress');
																			}}
																			isDisabled={props?.isPrint}
																			cacheOptions
																			defaultOptions
																			className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																		/>
																		{/* {!props?.isPrint && (
																			<Box sx={{mt:0.5}}>
																				<AddCompanyInline
																					title='Company' />
																			</Box>
																		)} */}
																	</>
																)}
															/>
															{contractForm.formState.errors?.producerCompanyId?.message && (
																<FormHelperText
																	error={true}
																	data-cy='errorHelper-producerCompanyId'
																>
																	{
																		contractForm.formState?.errors
																			?.producerCompanyId.message
																	}
																</FormHelperText>
															)}
														</FormControl>
														<span>Producer{ !props?.isPrint && (
															<Tooltip
																placement='right-end'
																title={'Signatory'}
															>
																<HelpOutline fontSize='inherit' />
															</Tooltip>)
														} , and</span> 
														<FormControl
															sx={{minWidth:'250px'}}
															className={styles.tableForms}
														>
															<Controller
																key={`performer-name
																-${contractForm.watch('performerId.value')}
																-${contractForm.watch('performerPhone')}-${contractForm.watch('performerEmail')}`}
																control={contractForm.control}
																name='performerId'
																render={({ field }) => (
																	<>
																		<LookAheadSelect
																			data-cy='name_select'
																			{...field}
																			key={`performer-name
																												-${contractForm.watch('performerId.value')}
																												-${contractForm.watch('performerPhone')}-${contractForm.watch('performerEmail')}`}
																			isClearable={true}
																			placeholder={!props?.isPrint ? 'Performer' : ''}
																			filterProperty='PersonName.FirstName'
																			APICaller={(
																				filterModel,
																				searchText
																			) =>
																				GetByWithFullNameSearchForBasicDetail(
																					filterModel,
																					searchText
																				)
																			}
																			isSearchText={true}
																			isDisabled={props?.isPrint}
																			optionGenerator={
																				performerOptionGenerator
																			}
																			defaultFilter={getPerformerFilter()}
																			onChange={(e: any) => {
																				field.onChange(e);
																				contractForm.setValue('performerPhone', '', { shouldDirty: true });
																				contractForm.setValue('performerEmail', '', { shouldDirty: true });
																				contractForm.setValue('performerZip', '', { shouldDirty: true });
																				contractForm.setValue('performerState', '', { shouldDirty: true });
																				contractForm.setValue('performerCity', '', { shouldDirty: true });
																				contractForm.trigger('performerPhone');
																				contractForm.trigger('performerEmail');
																				contractForm.setValue('performerAddressId', null, { shouldDirty: true });
																				contractForm.setValue('performerPrintName', '', { shouldDirty: true });
																				contractForm.setValue('performerAdAgencyId',null,{shouldDirty:true});
																				contractForm.setValue('performerAdAgencyAddressId',null,{shouldDirty:true});
																			}}
																			cacheOptions={false}
																			defaultOptions
																			className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																		/>
																		{!props?.isPrint && (
																			<Box sx={{mt:'-3px', height:'15px'}}>
																			
																				<AddPersonInline
																					title='Person'
																					onPersonCreated={() => ({})}
																					initialValues={{personType: ['TALENT'], isMinor: false}}
																				/>
																			
																			</Box>
																		)}
																	</>
																)}
															/>																							
															{contractForm.formState?.errors?.performerId?.message && (
																<FormHelperText
																	data-cy='errorHelper_first_session_date'
																	error={true}
																>
																	{
																		contractForm.formState?.errors?.performerId?.message
																	}
																</FormHelperText>
															)}
														</FormControl>
														<span>Performer<sup>*</sup></span>
														<span>Date</span>
														<FormControl
															className={styles.tableForms}
														>
															{props?.isPrint ? (
																<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerDate')) || ''}</p>
															) : (<Controller
																control={contractForm.control}
																name='producerDate'
																render={({ field }) => (
																	<NabuDatepicker
																		{...field}
																		disabled={props?.isPrint}
																		placeholder={!props?.isPrint ? 'Date' : ''}
																	/>
																)}
															/>)}
															{/* {contractForm.formState?.errors
																			?.producerDate?.message && (
																			<FormHelperText
																				data-cy='errorHelper_first_session_date'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.producerDate.message
																				}
																			</FormHelperText>
																		)} */}
														</FormControl>
													</div>
													<br></br>
													<div className='keep-together'>
														<p><b>Producer engages Performer and Performer agrees to perform services for Producer in Commercials as follows:</b></p>

														<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
															<tr>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<span>Commercial Title(s) and Ad-ID® No.(s)</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='commercialTitle'
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						placeholder={!props?.isPrint ? 'Commercial Title' : ''}
																						disabled={props?.isPrint}
																						value={contractForm.watch(`commercials.${0}.assetId.label`)}
																					/>
																				)}
																			/>
																		</FormControl>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox
																				value={getAssetAdIdByAssetIndex(0)}
																			/>
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
								# of Commercials
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '50%' }}
																	>
																		<NabuTextbox
																			disabled={true}
																			key={contractForm
																				.watch('commercials')
																				?.map((d) => d?.assetId)
																				?.join('-')}
																			value={contractForm
																				?.watch('commercials')
																				?.reduce(
																					(prev, cur) => {
																						const assetId = Number(cur?.assetId);
																						return prev + (isNaN(assetId) ? 0 : assetId);	
																					},
																					0
																				)}
																		/>
																	</FormControl>
																</td>
																<td>
								# of Tags
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='tags'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					type='number'
																					{...field}
																					placeholder={!props?.isPrint ? 'Tags' : ''}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																		{/* {contractForm.formState?.errors?.commercials
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState.errors?.commercials[
																								index
																							]?.tags?.message
																						}
																					</FormHelperText>
																				)} */}
																	</FormControl>
																</td>
																<td>
								# of Demos
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='demos'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					placeholder={!props?.isPrint ? 'Demos' : ''}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																		{/* {contractForm.formState?.errors?.commercials
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState.errors?.commercials[
																								index
																							]?.demos?.message
																						}
																					</FormHelperText>
																				)} */}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={300}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
								Such commercial(s) are to be produced by:
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='adAgencyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='ad_agency'
																							{...field}
																							placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							defaultFilter={AD_AGENCY_FILTER}
																							isClearable={true}
																							isDisabled={props?.isPrint}
																							onChange={(e: any) => {
																								field.onChange(e);
																								setJPCAuthorizer();
																								contractForm.setValue('adAgencyAddressId', null, { shouldDirty: true});
																							}}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{mt:1.3}}>
																								<AddCompanyInline
																									title='Company'
																									companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.adAgencyId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors?.adAgencyId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
									Address
																
																		<FormControl fullWidth className={styles.tableForms}>
																			<Controller
																				control={contractForm.control}
																				name='adAgencyAddressId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Select Address' : ''}
																							key={`agency-address-${contractForm.watch(
																								'adAgencyId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('adAgencyAddressId.value')){
																									contractForm.setValue('adAgencyAddressId', options[0], { shouldDirty: true});
																									contractForm.trigger('adAgencyAddressId', { shouldFocus: true});
																								}
																							}}
																							APICaller={(filterModel) =>
																								getAllAddressByCompany(
																									filterModel,
																									contractForm.watch('adAgencyId.value') ||
																				''
																								)
																							}
																							optionGenerator={
																								adAgencyAddressOptionGenerator
																							}
																							isDisabled={
																								!contractForm.watch('adAgencyId.value') ||
																		props?.isPrint
																							}
																							dropDownsize={50}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.trigger('adAgencyAddressId.value');
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{contractForm.watch('adAgencyId.value') &&
																		(!props?.isPrint)  && <div style={{float:'right'}}>
																							<Box sx={{mt:0.9}}>
																								<AddCompanyAddressInline
																									companyId={contractForm.watch('adAgencyId.value')}
																									title='ADDRESS'
																									isOpen={addressPopOver?.isAdAgencyAddressOpen || false}
																									event={addressPopOver?.event}
																									onAddressCreated={(d) => onAddressCreated(d, contractForm.watch('adAgencyId.value'), 'adAgencyAddressId')}
																									onClose={() => setAddressPopOver({ isAdAgencyAddressOpen: false })}
																									onPopOverOpen={(e) => setAddressPopOver({
																										isAdAgencyAddressOpen: true,
																										event: e?.currentTarget,
																									})}
																									isPersistent={true} />
																							</Box>
																						</div>}
																					</>
																	
																				)}
																			/>
																			{contractForm.formState.errors?.adAgencyAddressId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors?.adAgencyAddressId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
								acting as an agent for
																
																		<FormControl fullWidth className={styles.tableForms} >
																			<Controller
																				control={contractForm.control}
																				name='advertiserId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Advertiser' : ''}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={companyOptionGenerator}
																							defaultFilter={ADVERTISER_FILTER}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('commercials', [{} as any], { shouldDirty: true});
																								contractForm.setValue('productId', null, { shouldDirty: true});
																								contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
																								contractForm.setValue('producerAddressId',null,{shouldDirty:true});
																								contractForm.setValue('producerEmail','',{shouldDirty:true});
																								contractForm.setValue('producerName','',{shouldDirty:true});
																								setJPCAuthorizer();
																							}}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{mt:1.3}}>
																								<AddCompanyInline
																									title='Company'
																									companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.advertiserId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors?.advertiserId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<p>(Product(s))</p>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'nowrap', textAlign:'left'}}>
																
																		<FormControl fullWidth className={styles.tableForms} >
																			<Controller
																				control={contractForm.control}
																				name='productId'
																				render={({ field }) => (
																					<LookAheadSelect
																						{...field}
																						isMulti
																						placeholder={!props?.isPrint ? 'Select Product' : ''}
																						key={`product-${contractForm.watch(
																							'advertiserId.value'
																						)}`}
																						filterProperty='ProductName'
																						APICaller={(filterModel) =>
																							getAllProducts(
																								filterModel,
																								contractForm.watch('advertiserId.value')
																							)
																						}
																						optionGenerator={productOptionGenerator}
																						isDisabled={
																							!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
																						}
																						defaultFilter={PRODUCT_FILTER}
																						dropDownsize={20}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
																<td>
																	<p>City and State in which services rendered</p>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'nowrap', textAlign:'left'}}>
																		<FormControl
																			className={styles.tableForms}
																			style={{ width: '50%' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='serviceState'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						options={getStateOptions()}
																						disabled={props?.isPrint}
																						convertValueToUpperCase={false}
																						placeholder={!props?.isPrint ? 'Select State' : ''}
																					/>
																				)}
																			/>
																			{/* {contractForm?.formState?.errors?.serviceState
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper_location_state'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors?.serviceState
																									.message
																							}
																						</FormHelperText>
																					)} */}
																		</FormControl>
																		<FormControl
																			className={styles.tableForms}
																			style={{ width: '50%' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='serviceCity'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						disabled={props?.isPrint}
																						options={getCityOptions(
																							contractForm.watch('serviceState')
																						)}
																						convertValueToUpperCase={true}
																						placeholder={!props?.isPrint ? 'Select City' : ''}
																					/>
																				)}
																			/>
																			{/* {contractForm.formState?.errors?.serviceCity
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper_location_city'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors?.serviceCity
																									.message
																							}
																						</FormHelperText>
																					)} */}
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', textAlign:'left'}}>
								Place of Engagement
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				name='placeOfEngagement'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<LocationFreeTextSelect
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Place of Engagement' : ''}
																						/>
																					</>
																				)}
																			/>
																			{/* {contractForm.formState.errors?.placeOfEngagement
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper-job-title-name'
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.placeOfEngagement.message
																							}
																						</FormHelperText>
																					)} */}
																		</FormControl>
																	</div>
																</td>
															</tr>
														</table>
													</div>
													<br></br>
													<Grid item container columnSpacing={3}>
														<Grid item md={8}>
															<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} style={{ minHeight: '150px' }}>
																<tr>
																	<td>
																		<Grid item container columnSpacing={3}>
																			<Grid item md={3}>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('PRINCIPAL_PERFORMER')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='PRINCIPAL_PERFORMER'
																									checked={field.value === 'PRINCIPAL_PERFORMER'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Principal Performer</span>
																							</Box>
																						</div>
																					)}
																				/>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('SPECIALTY_ACT')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='SPECIALTY_ACT'
																									checked={field.value === 'SPECIALTY_ACT'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Specialty Act</span>
																							</Box>
																						</div>
																					)}
																				/>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('SINGER')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='SINGER'
																									checked={field.value === 'SINGER'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Singer</span>
																							</Box>
																						</div>
																					)}
																				/>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('SOLO_OR_DUO')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='SOLO_OR_DUO'
																									checked={field.value === 'SOLO_OR_DUO'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Solo or Duo Classification</span>
																							</Box>
																						</div>
																					)}
																				/>
																			</Grid>

																			<Grid item md={3}>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('STUNT_PERFORMER')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='STUNT_PERFORMER'
																									checked={field.value === 'STUNT_PERFORMER'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Stunt Performer</span>
																							</Box>
																						</div>
																					)}
																				/>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('STUNT_COORDINATOR')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='STUNT_COORDINATOR'
																									checked={field.value === 'STUNT_COORDINATOR'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Stunt Coordinator</span>
																							</Box>
																						</div>
																					)}
																				/>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('PILOT')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='PILOT'
																									checked={field.value === 'PILOT'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Pilot</span>
																							</Box>
																						</div>
																					)}
																				/>
																			</Grid>

																			<Grid item md={3}>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('GROUP_3_TO_5')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='GROUP_3_TO_5'
																									checked={field.value === 'GROUP_3_TO_5'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Group Singer 3-5</span>
																							</Box>
																						</div>
																					)}
																				/>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('GROUP_6_TO_8')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='GROUP_6_TO_8'
																									checked={field.value === 'GROUP_6_TO_8'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Group Singer 6-8</span>
																							</Box>
																						</div>
																					)}
																				/>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('GROUP_9_OR_MORE')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='GROUP_9_OR_MORE'
																									checked={field.value === 'GROUP_9_OR_MORE'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Group Singer 9+</span>
																							</Box>
																						</div>
																					)}
																				/>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('CONTRACTOR')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='CONTRACTOR'
																									checked={field.value === 'CONTRACTOR'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Contractor</span>
																							</Box>
																						</div>
																					)}
																				/>
																			</Grid>

																			<Grid item md={3}>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('DANCER_SOLO_OR_DUO')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='DANCER_SOLO_OR_DUO'
																									checked={field.value === 'DANCER_SOLO_OR_DUO'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Dancer - Solo or Duo</span>
																							</Box>
																						</div>
																					)}
																				/>
																				<Controller
																					control={contractForm.control}
																					name='performerTypes'
																					render={({ field }) => (
																						<div onClick={() => (!props?.isPrint) && field.onChange('DANCER_3_MORE')} style={{ userSelect: 'none' }}>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value='DANCER_3_MORE'
																									checked={field.value === 'DANCER_3_MORE'}
																									onChange={(e) => field.onChange(e.target.value)}
																									type='radio'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span>Dancer 3+</span>
																							</Box>
																						</div>
																					)}
																				/>
																			</Grid>
																		</Grid>
																	</td>
																</tr>
															</table>

														</Grid>
														<Grid item md={4}>
															<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
																<tr>
																	<td>	
																		<b>Check categories of non-exclusive use: </b>
																		<Controller
																			control={contractForm.control}
																			name='isSeasonalCommercial'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							type='checkbox'
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							disabled={props?.isPrint}
																							onChange={() => field.onChange(!field.value)}
																							style={{ marginTop: '3px' }}
																						/>
																						<span> Seasonal Commercial(s)</span></Box>
																				</div>
																			)}
																		/>
																		<Controller
																			control={contractForm.control}
																			name='isTestCommercial'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							type='checkbox'
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							disabled={props?.isPrint}
																							onChange={() => field.onChange(!field.value)}
																							style={{ marginTop: '3px' }}
																						/>
																						<span> Test or Test Market Commercial(s)</span></Box>
																				</div>
																			)}
																		/>
																		<Controller
																			control={contractForm.control}
																			name='isNonAirCommercial'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							type='checkbox'
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							disabled={props?.isPrint}
																							onChange={() => field.onChange(!field.value)}
																							style={{ marginTop: '3px' }}
																						/>
																						<span>Non-Air Commercial(s) </span></Box>
																				</div>
																			)}
																		/>
																		<Controller
																			control={contractForm.control}
																			name='isTraditionalDigitalCommercial'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							type='checkbox'
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							disabled={props?.isPrint}
																							onChange={() => field.onChange(!field.value)}
																							style={{ marginTop: '3px' }}
																						/>
																						<span>
																Traditional Digital Commercial(s) (no
																Streaming/Linear){' '}
																						</span></Box>
																				</div>
																			)}
																		/>
																		<Controller
																			control={contractForm.control}
																			name='isTheatricalExhibition'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							type='checkbox'
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							disabled={props?.isPrint}
																							onChange={() => field.onChange(!field.value)}
																							style={{ marginTop: '3px' }}
																						/>
																						<span>
																Theatrical/Industrial Exhibition (no Streaming/Linear){' '}
																						</span></Box>
																				</div>
																			)}
																		/>
																		<Controller
																			control={contractForm.control}
																			name='isTranslationServicesForLanguage'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							type='checkbox'
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							disabled={props?.isPrint}
																							onChange={() => field.onChange(!field.value)}
																							style={{ marginTop: '3px' }}
																						/>
																						<span>Foreign Language Translation Services </span></Box>
																				</div>
																			)}
																		/>
																	</td>
																</tr>
															</table>	
														</Grid>
													</Grid>
													<br></br>
													<Controller
														control={contractForm.control}
														name='isOnCamera'
														defaultValue={false}
														render={({ field }) => (
															<RadioGroup className={styles.radioGroupPrint}
																row
																{...field}
																onChange={() => field.onChange(!field.value)}
															>
																<FormControlLabel
																	value={true}
																	control={<Radio data-cy='On-Camera' />}
																	label=' On-Camera'
																	disabled={props?.isPrint}
																/>
																<FormControlLabel
																	value={false}
																	control={<Radio data-cy='On-Camera' />}
																	label='Off-Camera'
																	disabled={props?.isPrint}
																/>
															</RadioGroup>
														)}
													/>
													<br></br>
													<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} >
														<tr>
															<td width={150}>
									Part to be Played			
															</td>
															<td width={200}>
																<FormControl fullWidth className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='partPlayed'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuTextbox
																				data-cy='job_name_textbox'
																				{...field}
																				disabled={props?.isPrint}
																				placeholder={!props?.isPrint ? 'Part Played' : ''}
																			/>
																		)}
																	/>
																	{/* {contractForm.formState.errors?.partPlayed
																		?.message && (
																		<FormHelperText
																			data-cy='errorHelper-job-title-name'
																			error={true}
																		>
																			{
																				contractForm.formState?.errors?.partPlayed
																					.message
																			}
																		</FormHelperText>
																	)} */}
																</FormControl>
															</td>
															<td width={150}>
								Compensation{ !props?.isPrint && (
																	<Tooltip
																		placement='right-end'
																		title={'You can manually Enter the required option'}
																	>
																		<HelpOutline fontSize='inherit' />
																	</Tooltip>)
																}
															</td>
															<td width={200}>
																<FormControl fullWidth className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='compensation'
																		defaultValue='SCALE'
																		render={({ field }) => (
																			<Autocomplete
																				{...field}
																				options={field.value && !COMPENSATION_OPTIONS.find(e => e == field.value) ? [...COMPENSATION_OPTIONS, field.value] : COMPENSATION_OPTIONS}
																				disabled={props?.isPrint}
																				renderInput={(params) => (
																					<TextField {...params} placeholder={!props?.isPrint ? 'Compensation' : ''} />
																				)}
																				filterOptions={(options, params) => {
																					const { inputValue } = params;
																					const isExisting = options.some((option) => inputValue === option);
																					if (inputValue !== '' && !isExisting) {
																						options.push(inputValue?.toUpperCase() || '');
																					}
																					return options;
																				}}
																				onChange={(e, value) => {
																					contractForm.setValue('compensation', value?.toUpperCase() || '', { shouldDirty: true});																									
																				}}
																			/>
																		)}
																	/>
																	{/* {contractForm.formState.errors?.compensation
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.compensation
																										.message
																								}
																							</FormHelperText>
																						)} */}
																</FormControl>
																			
															</td>
															<td width={200}>
								Date(s) & Hour(s) of Employment
															</td>
															<td width={230}>
																<FormControl fullWidth className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='dateOfEmployment'
																		render={({ field }) => (
																			<NabuTextbox
																				data-cy='job_name_textbox'
																				{...field}
																				disabled={props?.isPrint}
																				placeholder={!props?.isPrint ? 'Date & Hour of Employment' : ''}
																			/>
																		)}
																	/>
																	{/* {contractForm.formState.errors?.nonEveningWearCount
																		?.message && (
																		<FormHelperText
																			data-cy='errorHelper-job-title-name'
																			error={true}
																		>
																			{
																				contractForm.formState?.errors
																					?.nonEveningWearCount.message
																			}
																		</FormHelperText>
																	)} */}
																</FormControl> 
															</td>
														</tr>
													</table>
													<br></br>
													<p><b>Check if:</b></p>
													<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
														<Controller
															control={contractForm.control}
															name='isFlightInsurancePayable'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '2px' }}
																		/>
																		<span>
																					Flight Insurance ($11.80) Payable{' '}
																		</span>
																	</Box>
																</div>
															)}
														/>
														<Controller
															control={contractForm.control}
															name='wardrobeFurnishedBy'
															defaultValue='none'
															render={({ field }) => (
																<>
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange('producer')}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value='producer'
																				checked={field.value === 'producer'}
																				onChange={(e) => field.onChange(e.target.value)}
																				type='radio'
																				disabled={props?.isPrint}
																				style={{ marginTop: '2px' }}
																			/>
																			<span>Wardrobe to be furnished by Producer</span>
																		</Box>
																	</div>

																	<div
																		onClick={() => (!props?.isPrint) && field.onChange('performer')}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value='performer'
																				checked={field.value === 'performer'}
																				onChange={(e) => field.onChange(e.target.value)}
																				type='radio'
																				disabled={props?.isPrint}
																				style={{ marginTop: '2px' }}
																			/>
																			<span>by Performer</span>
																		</Box>
																	</div>
																</>
															)}
														/>

														<Controller
															control={contractForm.control}
															name='isWorkInSmokeRequired'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>

																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '2px' }}
																		/>
																		<span>
																					Work in Smoke Required{' '}
																		</span>
																	</Box>
																</div>
															)}
														/>
													</div>
													<br></br>
													<p><b>If furnished by Performer:</b></p>
													<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
														<span>Number of costumes</span>
														<FormControl style={{maxWidth:170}} className={styles.tableForms}>
															<Controller
																control={contractForm.control}
																name='numberOfCostumes'
																render={({ field }) => (
																	<NabuTextbox
																		data-cy='job_name_textbox'
																		{...field}
																		disabled={props?.isPrint}
																		type='number'
																		placeholder={!props?.isPrint ? 'Number of Costumes' : ''}
																	/>
																)}
															/>
															{/* {contractForm.formState.errors?.nonEveningWearCount
																		?.message && (
																		<FormHelperText
																			data-cy='errorHelper-job-title-name'
																			error={true}
																		>
																			{
																				contractForm.formState?.errors
																					?.nonEveningWearCount.message
																			}
																		</FormHelperText>
																	)} */}
														</FormControl>
														<span>@{NON_EVENING_WEAR_COST}</span>
														<FormControl style={{maxWidth:180}} className={styles.tableForms}>
															<Controller
																control={contractForm.control}
																name='nonEveningWearCount'
																render={({ field }) => (
																	<NabuTextbox
																		data-cy='job_name_textbox'
																		{...field}
																		disabled={props?.isPrint}
																		onChange={(e) => {
																			field.onChange(e);
																			calculateTotalWardrobeFee();
																		}}
																		type='number'
																		placeholder={!props?.isPrint ? 'Non Evening wear count' : ''}
																	/>
																)}
															/>
															{/* {contractForm.formState.errors?.nonEveningWearCount
																		?.message && (
																		<FormHelperText
																			data-cy='errorHelper-job-title-name'
																			error={true}
																		>
																			{
																				contractForm.formState?.errors
																					?.nonEveningWearCount.message
																			}
																		</FormHelperText>
																	)} */}
														</FormControl>
														<span>@{EVENING_WEAR_COST}</span>
														<FormControl style={{maxWidth:160}} className={styles.tableForms}>
															<Controller
																control={contractForm.control}
																name='eveningWearCount'
																render={({ field }) => (
																	<NabuTextbox
																		data-cy='job_name_textbox'
																		{...field}
																		disabled={props?.isPrint}
																		onChange={(e) => {
																			field.onChange(e);
																			calculateTotalWardrobeFee();
																		}}
																		type='number'
																		placeholder={!props?.isPrint ? '# of Evening Wear' : ''}
																	/>
																)}
															/>
															{/* {contractForm.formState.errors?.eveningWearCount
																		?.message && (
																		<FormHelperText error={true}>
																			{
																				contractForm.formState?.errors
																					?.eveningWearCount.message
																			}
																		</FormHelperText>
																	)} */}
														</FormControl>
														<span>Total Wardrobe Fee $</span>
														<FormControl style={{maxWidth:170}} className={styles.tableForms}>
															<Controller
																control={contractForm.control}
																name='totalWardrobeFee'
																render={({ field }) => (
																	<NabuTextbox
																		{...field}
																		disabled={true}
																		placeholder={!props?.isPrint ? 'Total Wardrobe Fee' : ''}
																		onChange={(e) => {
																			if (
																				new RegExp(/^\d*\.?\d*$/).test(
																					e?.target?.value || ''
																				) &&
																			Number(e?.target?.value) >= 0
																			) {
																				field.onChange(e);
																			} else {
																				const value: string =
																				e?.target?.value ?? '';
																				const num = value
																					?.match(/^\d*\.?\d*/)
																					?.at(0);
																				if (!isNaN(Number(num || ''))) {
																					field.onChange(Number(num || ''));
																				} else {
																					field.onChange(0);
																				}
																			}
																		}}
																	/>
																)}
															/>
															{/* {contractForm.formState.errors?.totalWardrobeFee
																		?.message && (
																		<FormHelperText
																			data-cy='errorHelper-job-title-name'
																			error={true}
																		>
																			{
																				contractForm.formState?.errors
																					?.totalWardrobeFee.message
																			}
																		</FormHelperText>
																	)} */}
														</FormControl>
													</div>
													<br></br>
													<p>
						This contract is subject to all of the terms and conditions of the applicable Commercials Contract. The standard provisions on page 2 hereof are a part of this
contract. If this contract provides for compensation at SAG-AFTRA minimum, no additions, changes or alterations may be made in this form other than
those which are more favorable to the Performer than herein provided. If this contract provides for compensation above the SAG-AFTRA minimum, additions
may be agreed to between Producer and Performer which do not conflict with the provisions of the SAG-AFTRA Commercials Contract, provided that such
additional provisions are separately set forth under &quot;Special Provisions&quot; hereof and signed by the Performer
													</p>
													<br></br>
													<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
														<tr>
															<td colSpan={4}>
																<b>	Until Performer shall otherwise direct in writing, Performer authorizes Producer to make all payments to which Performer may be entitled hereunder as follows:</b>
															</td>
														</tr>
														<tr>
															<td width={200}>
																<Controller
																	control={contractForm.control}
																	name='isPerformerAtAddress'
																	// defaultValue={true}
																	render={({ field }) => (
																		<div
																			onClick={() => {
																				(!props?.isPrint) && field.onChange(!field.value);
																				contractForm.trigger('performerAddressId');
																				contractForm.setValue('isPerformerAtAgentRepresentativeAddress', !contractForm.watch('isPerformerAtAddress'));
																			}}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>

																				<input
																					{...field}
																					value={field.value + ''}
																					checked={field.value}
																					onChange={(e) => {
																						field.onChange(e);
																						contractForm.trigger('performerAddressId');
																						contractForm.setValue('isPerformerAtAgentRepresentativeAddress', !contractForm.watch('isPerformerAtAddress'));
																					}}
																					type='checkbox'
																					disabled={props?.isPrint}
																					style={{ marginTop: '2px' }}
																				/>
																				<span>
																					<b>To Performer at</b>
																				</span>
																			</Box>
																		</div>
																	)}
																/>
															</td>
															<td>
																<FormControl
																	fullWidth
																	className={styles.tableForms}
																>
																	<Controller
																		key={`performer-printName-${contractForm.watch(
																			'performerId'
																		)}`}
																		control={contractForm.control}
																		name='performerPrintName'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuTextbox
																				key={`performer-printName-${contractForm.watch(
																					'performerId'
																				)}`}
																				{...field}
																				placeholder={!props?.isPrint ? 'Performer' : ''}
																				disabled={true}
																				value={contractForm.watch('performerId.label')}
																			/>
																		)}
																	/>
																	{/* {contractForm.formState?.errors?.commercials
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState.errors?.commercials[
																								index
																							]?.demos?.message
																						}
																					</FormHelperText>
																				)} */}
																</FormControl>
															</td>
	
															<td colSpan={2}>
																<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																	<span>Address</span>
																	<FormControl className={styles.tableForms} fullWidth>
																		<Controller
																			control={contractForm.control}
																			key={`select_Address_${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Address' : ''}
																						key={`performer-address-${contractForm.watch(
																							'performerId.value'
																						)}-`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('performerAddressId.value')){
																								contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('performerAddressId', { shouldFocus: true});																						
																								onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByPerson(
																								filterModel,
																								contractForm.watch(
																									'performerId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							addressOptionGenerator
																						}
																						dropDownsize={50}
																						isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					<Box sx={{mt:1.3}}>
																						{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																					<AddPersonAddressInline
																						personId={contractForm.watch('performerId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isPerformerAddressOpen || false}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPersonAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isPerformerAddressOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isPerformerAddressOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																						}

																					</Box>
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors?.performerAddressId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState.errors
																						?.performerAddressId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</div>
															</td>
														</tr>
														<tr>
															<td>
																<Controller
																	control={contractForm.control}
																	name='isPerformerAtAgentRepresentativeAddress'
																	defaultValue={false}
																	render={({ field }) => (
																		<div
																			onClick={() => {
																				(!props?.isPrint) && field.onChange(!field.value);
																				contractForm.trigger('performerAdAgencyId');
																				contractForm.trigger('performerAdAgencyAddressId');
																				contractForm.setValue('isPerformerAtAddress', !contractForm.watch('isPerformerAtAgentRepresentativeAddress'));
																			}}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>

																				<input
																					{...field}
																					value={field.value + ''}
																					checked={field.value}
																					onChange={(e) => {
																						field.onChange(e);
																						contractForm.trigger('performerAdAgencyId');
																						contractForm.trigger('performerAdAgencyAddressId');
																						contractForm.setValue('isPerformerAtAddress', !contractForm.watch('isPerformerAtAgentRepresentativeAddress'));
																					}}
																					type='checkbox'
																					disabled={props?.isPrint}
																					style={{ marginTop: '2px' }}
																				/>
																				<span>
																					<b>
																						To Performer c/o
																					</b>
																				</span>
																			</Box>
																		</div>
																	)}
																/>
															</td>
															<td>
																<FormControl className={styles.tableForms} fullWidth>
																	<Controller
																		key={`agency-${contractForm.watch('performerId.value')}`}
																		control={contractForm.control}
																		name='performerAdAgencyId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					{...field} 
																					key={`agency-${contractForm.watch('performerId.value')}`}
																					placeholder='Agency'
																					filterProperty='CompanyNames.Name'																				
																					APICaller={(filter) => {
																						filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																						return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																					}}
																					optionGenerator={companyOptionGenerator}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.setValue('performerAdAgencyAddressId', null, { shouldDirty: true});
																						onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
																						contractForm.trigger('performerAdAgencyAddressId');
																					}}
																					onOptionLoaded={(options) => {
																						if(options?.length && !contractForm.watch('performerAdAgencyId.value')){
																							contractForm.setValue('performerAdAgencyId', options[0], { shouldDirty: true});
																							onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
																							contractForm.trigger('performerAdAgencyId', { shouldFocus: true});
																						}
																					}}
																					cacheOptions
																					isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																					defaultOptions
																					dropDownsize={25}
																					isClearable={true}
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																				{!props?.isPrint && contractForm.watch('performerId.value') && (<AddTalentAgentInline
																					onCompanyCreated={(createdAgent) => contractForm.setValue('performerAdAgencyId', createdAgent, { shouldDirty: true})}
																					onClose={() => setCompanyPopoverInline({ isOpen: false})}
																					onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
																					event={companyPopoverInline?.event}
																					isOpen={companyPopoverInline.isOpen}
																					personId={contractForm.watch('performerId.value') || ''}
																				/>)}
																			</>
																		)}
																	/>
																	{contractForm.formState.errors?.performerAdAgencyId?.message && (
																		<FormHelperText
																			key={`performer-ad-${contractForm.watch('performerAdAgencyId.value')}`}
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors
																					?.performerAdAgencyId?.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</td>
															
															<td colSpan={2}>
																<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																	<span>Address</span>
																	<FormControl className={styles.tableForms} fullWidth>
																		<Controller
																			control={contractForm.control}
																			key={`performer-ad-address-${contractForm.watch(
																				'performerAdAgencyId.value'
																			)}-${contractForm.watch('performerAdAgencyPhoneId')}`}
																			name='performerAdAgencyAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Address' : ''}
																						key={`performer-ad-address-${contractForm.watch(
																							'performerAdAgencyId.value'
																						)}-${contractForm.watch('performerAdAgencyPhoneId')}`}
																						filterProperty='Line1'
																						isDisabled={props?.isPrint || !contractForm.watch('performerAdAgencyId.value')}
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('performerAdAgencyAddressId.value')){
																								contractForm.setValue('performerAdAgencyAddressId', options[0], { shouldDirty: true});
																								onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
																								contractForm.trigger('performerAdAgencyAddressId', { shouldFocus: true});
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByCompany(
																								filterModel,
																								contractForm.watch(
																									'performerAdAgencyId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							agentAddressOptionGenerator
																						}
																						dropDownsize={50}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('performerAdAgencyPhoneId', '', { shouldDirty: true});
																							contractForm.trigger('performerAdAgencyPhoneId');
																							onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
																						}}
																				
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{contractForm.watch('performerAdAgencyId.value') &&
																		(!props?.isPrint)  && <Box sx={{mt:1.3}}>
																						<AddCompanyAddressInline
																							companyId={contractForm.watch('performerAdAgencyId.value')}
																							title='ADDRESS'
																							isOpen={addressPopOver?.performerAdAgencyAddressOpen || false}
																							event={addressPopOver?.event}
																							onAddressCreated={(d) => {
																								onPerformerAdAgencyAddressCreated(d);
																						
																							}}
																							onClose={() => setAddressPopOver({ performerAdAgencyAddressOpen: false })}
																							onPopOverOpen={(e) => setAddressPopOver({
																								performerAdAgencyAddressOpen: true,
																								event: e?.currentTarget,
																							})}
																							isPersistent={true} />
																					</Box>}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors?.performerAdAgencyAddressId?.message && (
																			<FormHelperText
																				data-cy='errorHelper_?.addressId'
																				error={true}
																			>
																				{
																					contractForm.formState.errors
																						?.performerAdAgencyAddressId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</div>
															</td>
														</tr>
														<tr>
															<td colSpan={4}>
																<b>	All notices to Performer shall be sent to the address designated above for payments and, if Performer desires, to one other address as follows:</b>
															</td>
														</tr>
														<tr>
															<td>
									To
															</td>
															<td>
																<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder='Name'/></FormControl> 
															</td>
															<td colSpan={2}>
																<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																	<span>Address</span>
																	<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder='Address'/></FormControl> 
																</div>
															</td>
														</tr>
														<tr>
															<td>
								All notices to Producer shall be addressed as follows: To Producer at
															</td>
															<td colSpan={3}>
																<FormControl
																	className={styles.tableForms}
																	fullWidth
																>
																	<Controller
																		control={contractForm.control}
																		key={`producer-address-cr-${contractForm.watch(
																			'producerCompanyId.value'
																		)}`}
																		name='producerAddressId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Select Address' : ''}
																					key={`producer-address-${contractForm.watch(
																						'producerCompanyId.value'
																					)}-${contractForm.watch(
																						'producerEmail'
																					)}`}
																					filterProperty='Line1'
																					sortField='IsPrimary'
																					sortDescending={true}
																					onOptionLoaded={(options) => {
																						if(options?.length && !contractForm.watch('producerAddressId.value')){
																							contractForm.setValue('producerAddressId', options[0], { shouldDirty: true});
																							contractForm.trigger('producerAddressId', { shouldFocus: true});	
																							onAddressChange(contractForm.watch('producerAddressId.value') || '', 'producerAddress');																					
																						}
																					}}
																					APICaller={(filterModel) =>
																						getAllAddressByCompany(
																							filterModel,
																							contractForm.watch(
																								'producerCompanyId.value'
																							) || ''
																						)
																					}
																					optionGenerator={
																						producerAddressOptionGenerator
																					}
																					isDisabled={
																						!contractForm.watch(
																							'producerCompanyId.value'
																						) || props?.isPrint
																					}
																					dropDownsize={50}
																					isClearable={true}
																					isSearchText={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.setValue('producerEmail', '', { shouldDirty: true});
																						contractForm.trigger('producerEmail');
																						onAddressChange(contractForm.watch('producerAddressId.value') || '', 'producerAddress');
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																				{
																					<Box sx={{mt:1.3}}>
																						{(contractForm.watch('producerCompanyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('producerCompanyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isProducerAddressOpen && addressPopOver?.for == 'ProducerCompany-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onProducerAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isProducerAddressOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isProducerAddressOpen: true,
																									event: e?.currentTarget,
																									for: 'ProducerCompany-address'
																								})}
																								isPersistent = {true} />
																						}
																					</Box>
																				}
																			</>
																		)}
																	/>
																</FormControl>
																{/* <	FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder='Address'/></FormControl>  */}
															</td>
														</tr>
													</table>
													<br></br>
													<p>Employer of Record for income tax and unemployment insurance purposes is: Highland Talent Payments Inc. 5885 Landerbrook Dr, Cleveland, OH 44124</p>

													<br></br>
													<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
														<tr>
															<td width={350}>
		PRODUCER{ !props?.isPrint && (
																	<Tooltip
																		placement='right-end'
																		title={'Signatory'}
																	>
																		<HelpOutline fontSize='inherit' />
																	</Tooltip>)
																}  SAG-AFTRA before signing this contract. (NAME OF COMPANY)
															</td>
															<td width={300}>
																<FormControl
																	key={`producer-name-${contractForm.watch(
																		'producerCompanyId.value'
																	)}`}
																	className={styles.tableForms}
																>
																	<Controller
																		key={`producer-name-${contractForm.watch(
																			'producerCompanyId.value'
																		)}`}
																		control={contractForm.control}
																		name='producerName'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuTextbox
																				{...field}
																				placeholder={!props?.isPrint ? 'Producer' : ''}
																				disabled={props?.isPrint}
																				value={contractForm.watch('producerCompanyId.label')}
																			/>
																		)}
																	/>
																</FormControl>
															</td>
															<td colSpan={2}>
		Performerr has the right to consult with his/her representative or
		PRODUCER SAG-AFTRA before signing this contract.
															</td>
														</tr>
														<tr>
															<td>
								Producer Email 
															</td>
															<td>
																<FormControl
																	className={styles.tableForms}
																	fullWidth
																>
																	<Controller
																		control={contractForm.control}
																		name='producerEmail'
																		defaultValue=''
																		render={({ field }) => (
																			<>
																				<NabuSelect
																					{...field}
																					key={`select-em-${contractForm.watch(
																						'producerAddressId.value'
																					)}-${contractForm.watch('producerEmail')}`}
																					placeholder={!props?.isPrint ? 'Select Email' : ''}
																					convertValueToUpperCase={false}
																					enableEmptySelection={true}
																					disabled={props?.isPrint || !contractForm.watch('producerAddressId.value')}
																					options={getCompanyEmailOptions(contractForm.watch('producerAddressId.value'))}
																					handleDefaultSelection={() => !contractForm.watch('producerEmail')}
																				/>
																				{
																					<Box sx={{mt:1.3}}>
																						{(contractForm.watch('producerAddressId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																							onSubmit={onProducerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																							event={emailPopOver?.event}
																						/>
																						}
																					</Box>	
																				}
																			</>
																		)}
																	/>
																</FormControl>
															</td>
															<td>
								Performer Email
															</td>
															<td>
																<FormControl
																	className={styles.emaillowerCase}
																	fullWidth
																>
																	<Controller
																		key={`select-${contractForm.watch('performerId.value')}-${contractForm.watch('performerEmail')}`}
																		control={contractForm.control}
																		name='performerEmail'
																		defaultValue=''
																		render={({ field }) => (
																			<>
																				<NabuSelect
																					{...field}
																					defaultCase={true}
																					key={`select-${contractForm.watch('performerId.value')}-${contractForm.watch('performerEmail')}`}
																					options={getPerformerEmailsOptions()}
																					disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																					convertValueToUpperCase={false}
																					placeholder={!props?.isPrint ? 'Select Email' : ''}
																					handleDefaultSelection={() => {
																						return !contractForm.watch('performerEmail');
																					}}
																				/>
																				{
																					<Box sx={{mt:1.3}}>
																						{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																						}
																					</Box>	
																				}
																			</>
																		)}
																	/>
																	{/* {contractForm?.formState?.errors?.performerEmail
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.performerEmail
																							.message
																					}
																				</FormHelperText>
																			)} */}
																</FormControl>
															</td>
														</tr>
														<tr>
															<td>
								By 
															</td>
															<td>
																<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder='' /></FormControl> 
															</td>
															<td>
								PERFORMER
															</td>
															<td>
																<FormControl fullWidth className={styles.tableForms}>
																	<Controller
																		key={`performer-printName-${contractForm.watch(
																			'performerId'
																		)}`}
																		control={contractForm.control}
																		name='performerPrintName'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuTextbox
																				key={`performer-printName-${contractForm.watch(
																					'performerId'
																				)}`}
																				{...field}
																				placeholder={!props?.isPrint ? 'Print Name' : ''}
																				disabled={true}
																				value={contractForm.watch('performerId.label')}
																			/>
																		)}
																	/>
																	{/* {contractForm.formState.errors?.performerPrintName
																					?.message && (
																					<FormHelperText
																						data-cy='errorHelper-performerPrintName'
																						error={true}
																					>
																						{
																							contractForm.formState?.errors
																								?.performerPrintName.message
																						}
																					</FormHelperText>
																				)} */}
																</FormControl>
															</td>
														</tr>
													</table>
													<br></br>
													<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
						Performer hereby certifies that he/she is 21 years of age or over (if under 21 years of age this contract must be signed below by a parent or guardian). I, the undersigned,
						hereby state that I am the 
														<FormControl sx={{minWidth:'350px'}} className={styles.tableForms}>
															<Controller
																control={contractForm.control}
																name='guardian'
																defaultValue=''
																render={({ field }) => (
																	<NabuTextbox
																		{...field}
																		placeholder={!props?.isPrint ? '(Mother,Father or Gaurdian)' : ''}
																		disabled={props?.isPrint}
																	/>
																)}
															/>
							
														</FormControl>
						of the above named Performer and do hereby consent and give my permission to this agreement.
													</div>
													<hr></hr>
													<br></br>
													<Box style={{width:'100%'}} justifyContent={'flex-end'} display={'flex'}>
														<div >
															<FormControl sx={{minWidth:'350'}} className={styles.tableForms}>
																<FormLabel>(Signature of Parent of Guardian)</FormLabel>
																<NabuTextbox placeholder='(Signature of Parent of Guardian)' />
															</FormControl> 
														</div>
													</Box>
													<br></br>
													<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
														<tr>
															<td colSpan={4} style={{ background: '#515151', color: '#FFF' }}>
																<p><b>SPECIAL PROVISIONS (including adjustments, if any, for Stunt Performers): </b></p>
															</td>
														</tr>
														<tr>
															<td colSpan={4}>
																<div className={styles.textareaSection}>
																	{
																		props?.isPrint ? (
																			<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvision')}</p>
																		) : (<div className={styles.textareaSection}>  
																			<Controller
																				control={contractForm.control}
																				name='specialProvision'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuBaseTextArea
																						className={styles.specialProvision}
																						data-cy='job_name_textbox'
																						{...field}
																						rows={5}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
																					/>
																				)}
																			/>
																			{/* {contractForm.formState.errors
																							?.specialProvision?.message && (
																							<FormHelperText
																								data-cy='errorHelper-job-title-name'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors
																										?.specialProvision.message
																								}
																							</FormHelperText>
																						)} */}
																		</div> )
																	}
																	{/* <NabuBaseTextArea 	className={styles.specialProvision} rows={5} /> */}
																</div>
															</td>
														</tr>
														<tr>
															<td colSpan={2}>
		Performer acknowledges that he or she has read all the terms and conditions in the
		Special Provisions above and hereby agress thereto
															</td>
															<td>
		(Signature of Performer)
															</td>
															<td>
																<FormControl fullWidth className={styles.tableForms}>
																	<NabuTextbox placeholder='Signature' />
																</FormControl> 
															</td>
														</tr>
														<tr>
															<td>
								Title(s) and Ad-ID(s) Cont&apos;d
															</td>
															<td colSpan={3}>
																<FormControl
																	fullWidth
																	className={styles.tableForms}
																>
																	<Controller
																		control={contractForm.control}
																		name='commercialTitle'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuTextbox
																				{...field}
																				placeholder={!props?.isPrint ? 'Commercial Title' : ''}
																				disabled={props?.isPrint}
																				value={contractForm.watch(`commercials.${0}.assetId.label`)}
																			/>
																		)}
																	/>
																</FormControl>
																<FormControl
																	fullWidth
																	className={styles.tableForms}
																>
																	<NabuTextbox
																		value={getAssetAdIdByAssetIndex(0)}
																	/>
																</FormControl>
															</td>
														</tr>
													</table>

													<br></br>
													<h4 style={{textAlign:'center'}}><b>IMPORTANT PROVISIONS ON PAGE 2. PLEASE READ CAREFULLY</b></h4>
													<h3  style={{textAlign:'center'}}>STANDARD PROVISIONS</h3>
													<ol>
														<li>
															<b>RIGHT TO CONTRACT</b><br></br>
															<p>Performer states that to the best of his /her knowledge, his/her has not authorized the use of his/her name, likeness or identifiable voice in any
commercial advertising any competitive product or service during the term of permissible use of commercial(s) hereunder and that he/she is
free to enter into this Contract and to grant the rights and uses as herein set forth.</p>
															<br></br>
														</li>
														<li>
															<b> EXCLUSIVITY</b>
															<p>Performer states that since accepting employment in the commercial(s) covered by this Contract, he/she has not accepted employment in nor
authorized the use of his/her name or likeness or identifiable voice in any commercial(s) advertising any competitive product or service and that
he/she will not hereafter, during the term of permissible use of the commercial(s) for which he/she is employed hereunder, accept employment
in or authorize the use of his/her name or likeness or identifiable voice in any commercial(s) advertising any competitive product or service.
This paragraph shall not apply to off camera solo or duo singers or to group performers (other than name groups) or to performers employed
in- seasonal commercials under Section 41 of the SAG-AFTRA Commercials Contract.</p>
															<br></br>
														</li>
														<li>
															<b>OTHER USES (Strike &quot;A&quot; or &quot;B&quot; or both if such rights are not granted by Performer)</b>
															<p>(a) <u>Foreign Use</u></p>
															<p>Producer shall have the right to the foreign use of the commercial(s) produced hereunder for which Producer agrees to pay Performer not less
than the additional compensation provided for in the SAG-AFTRA Commercials Contract. Producer agrees to notify SAG-AFTRA in writing
promptly of any such foreign use.</p>
															<p>(b) <u>Theatrical & Industrial Use.</u></p>
															<p>Producer shall have the right to the commercial(s) produced hereunder for theatrical & industrial use as defined and for the period permitted in
the SAG-AFTRA Commercials Contract, for which Producer shall pay Performer not less than the additional compensation therein provided.</p>
															<br></br>
														</li>
														<li>
															<b>ARBITRATION</b>
															<p>All disputes and controversies of every kind and nature arising out of or in connection with this Contract shall be subject to arbitration as
								provided in Section 58 of the SAG-AFTRA Commercials Contract.</p>
															<br></br>
														</li>
														<li>
															<b>PRODUCER &apos;S RIGHTS</b>
															<p>Performer acknowledges that Performer has no right, title or interest of any kind or nature whatsoever in or to the commercial(s). A role owned
								or created by Producer belongs to Producer and not to Performer.</p>
															<br></br>
														</li>
														<li>
															<b>	CONFIDENTIALITY</b>
															<p>
								&apos;Confidential Information&apos; means trade secrets, confidential data, and other non-public confidential proprietary information (whether or not
labeled as confidential) including any and all financial terms of and products involved in the production and any and all scripts whether
communicated orally, in written form, or electronically. Confidential information does not include information that was lawfully in Performer&apos;s
possession prior to being disclosed in connection with the employment of Performer, is now, or hereafter becomes generally known to the
public, or that Performer rightfully obtained without restriction from a third party. Performer acknowledges that Performer has and will become
aware of certain Confidential Information. Unless otherwise required by law, Performer agrees that, without Producer&apos;s prior written approval,
Performer shall hold such Confidential Information in the strictest confidence and that Performer will not disclose such Confidential Information
to anyone (other than Performer&apos;s representatives in the course of their duties to Performer, which representatives shall be bound by the same
restrictions as set forth in this Agreement) or utilize such Confidential Information for Performer&apos;s benefit or for the benefit of a third party.
Notwithstanding the foregoing, nothing herein shall prohibit Performer from disclosing Confidential Information concerning Performer&apos;s wages,
hours, and other terms and conditions of employment as that term is defined under Section 7 of the National Labor Relations Act. For clarity,
except as set forth above, Producer may not demand or request that Performer execute any non-disclosure agreement that has not been
approved in advance and in writing by the Union.&quot; This provision is not intended to supersede any confidentiality provisions in celebrity agreements.
															</p>
														</li>
													</ol>
												</div>
											</div>
											{/* Main Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
									
											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
									
											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
									
											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
									
											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
								&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
								Cancel
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(contractForm.formState.isDirty ||
									packetControlForm.formState.isDirty) ||
									isAddingContract ||
									isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
							&nbsp;
									<NabuButton variant='filled' onClick={contractForm.handleSubmit(handleSaveAndDuplicate)} disabled={
										!(contractForm.formState.isDirty ||
									packetControlForm.formState.isDirty) ||
									isAddingContract ||
									isEditingContract
									}>
										{' '}
								Save & Duplicate <CopyAll />
									</NabuButton>
							&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)} noOfTalentsSelected={noOfTalentsSelected} setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} performerCategory={SagAftraTVPriHTPerformerTypeOptions} isUnion={true} />
			
		</>
	);
});

export default SagAftraTvPrincipal;
