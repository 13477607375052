import * as schema from 'yup';


const lookAHeadSchema = (field: string) =>  schema.object({
	value: schema.string().required(`${field} is required.`),
	label: schema.string().required(`${field} is required.`)
})
	.required(`${field} is required.`)
	.typeError(`${field} is required.`)
	.test('look-head-test', `${field} is required.`, (optionObj) => {
		return !!optionObj?.value?.length;
	});

const getRequiredSchema = (schema, field: string) => schema?.required(`${field} is required.`).typeError(`${field} is required.`);

const DATE_FILED_REQUIRED_SCHEMA = schema.mixed().required('Date is required.').typeError('Date is required.').notOneOf([null, ''], 'Date is required.');

const commercialsDetailSchema = schema.array().of(
	schema.object({
		assetId: lookAHeadSchema('Commercial Title')
	})
);

const workDaysDetailSchema = schema.array().of(
	schema.object({
		date: schema.string().required('Date is required.').typeError('Date is required.').notOneOf([null, ''], 'Date is required.'),
	})
).nullable();


export const StandardSagAftraEmployment2022Schema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	adAgencyId: lookAHeadSchema('Ad Agency'),
	performerId: lookAHeadSchema('Performer'),
	commercials: commercialsDetailSchema,
	serviceState: getRequiredSchema(schema.string(), 'Service State'),
	serviceCity: getRequiredSchema(schema.string(), 'Service City'),
	compensation: getRequiredSchema(schema.string(), 'Compensation'),
	// producerCompanyId: lookAHeadSchema('Producer'),
	performerAdAgencyId: schema.object().when('isPerformerAtAgentRepresentativeAddress', {
		is: true,
		then: lookAHeadSchema('Agent Representative'),
		otherwise: schema.object().nullable(),
	}),
	performerAdAgencyAddressId: schema.object().when('isPerformerAtAgentRepresentativeAddress', {
		is: true,
		then: lookAHeadSchema('Agency Address'),
		otherwise: schema.object().nullable(),
	}),
	// isPrincipalPerformer: schema.bool().test('performer-type-test', 'Please select at least one Performer Type.', function(){
	// 	if(!(this.parent?.isPrincipalPerformer||this.parent?.isSpecialtyAct || this.parent?.isGroup3To5 || this.parent?.isSignatureSoloOrDuo || this.parent?.isPilot||this.parent?.isStuntPerformer||this.parent?.isDancer||this.parent?.isGroup6To8||this.parent?.isGroupSignature3To5||this.parent?.isSignLanguageInterpreter||this.parent?.isStuntCoordinator||this.parent?.isSinger||this.parent?.isGroup9OrMore||this.parent?.isGroupSignature6To8||this.parent?.isSoloOrDuo||this.parent?.isContractor||this.parent?.isGroupSignature9OrMore)) return false;
	// 	return true;
	// }),
	performerAddressId: schema.object().when('isPerformerAtAddress', {
		is: true,
		then: lookAHeadSchema('Performer Address'),
		otherwise: schema.object().nullable(),
	}),
	performerType:getRequiredSchema(schema.string(),'Performer Type'),
	// isTestCommercial: schema.bool().test('performer-cat-test', 'Please select at least one Commercial Type.', function(){
	// 	if(!(this.parent?.isTestCommercial || this.parent?.isNonAirCommercial || this.parent?.isTheatricalExhibition || this.parent?.isSeasonalCommercial || this.parent?.isTraditionalDigitalCommercial||this.parent?.isWorkInSmokeRequired||this.parent?.isAddendumForRegion||this.parent?.isTranslationServicesForLanguage  )) return false;
	// 	return true;
	// }),
});

export const SagAftraSdEmpCtrComPriPerY22Schema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	// producerCompanyId: schema.object().when('isPaymentToPerformerCareOf', {
	// 	is: false,
	// 	then: lookAHeadSchema('Producer'),
	// 	otherwise: schema.object().nullable(),
	// }),
	performerId: lookAHeadSchema('Performer'),
	adAgencyId: lookAHeadSchema('Ad Agency'),
	advertiserId: lookAHeadSchema('Advertiser'),
	compensation: getRequiredSchema(schema.string(), 'Compensation'),
	engagementDate: DATE_FILED_REQUIRED_SCHEMA,
	adAgencyState: getRequiredSchema(schema.string(), 'State'),
	adAgencyCity: getRequiredSchema(schema.string(), 'City'),
	commercials: commercialsDetailSchema,
});

export const SagAftraSdEmpCtrIndEduFilmVideoPgmSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	// workDays: workDaysDetailSchema,
	// producerCompanyId: lookAHeadSchema('Producer'),
	performerId: lookAHeadSchema('Performer'),
	tentativeProgramTitle: getRequiredSchema(schema.string(), 'Tentative Program Title'),
	advertiserId: lookAHeadSchema('Advertiser'),
	term: getRequiredSchema(schema.string(), 'Term'),
	serviceState: getRequiredSchema(schema.string(), 'Service State'),
	serviceCity: getRequiredSchema(schema.string(), 'Service City'),
	offCameraSalaryForFirstHour: schema.string().when('classification', {
		is: 'OFF-CAMERA',
		then: getRequiredSchema(schema.string(), 'Off Camera Salary For First Hour'),
		otherwise: schema.string().nullable(),
	}),
	onCameraSalary: schema.string().when('classification', {
		is: 'OFF-CAMERA',
		then: schema.string().nullable(),
		otherwise: getRequiredSchema(schema.string(), 'On Camera Salary For First Hour'),
	}),
	categoryType:getRequiredSchema(schema.string(),'Category Type')
});

export const SagAftraSdExtComEx2Y22Schema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	adAgencyId: lookAHeadSchema('Ad Agency'),
	performerId: lookAHeadSchema('Performer'),
	// workDays: workDaysDetailSchema,
	commercials: commercialsDetailSchema,
	engagementDate: DATE_FILED_REQUIRED_SCHEMA,
	placeOfEngagement: getRequiredSchema(schema.string(), 'Place Of Engagement'),
	// producerCompanyId: lookAHeadSchema('Producer'),
	performerAddressId: schema.object().when('isPaymentAtExtraPerformerAddress', {
		is: true,
		then: lookAHeadSchema('Performer Address'),
		otherwise: schema.object().nullable(),
	}),
	performerAdAgencyId: schema.object().when('isPaymentAtPerformerAgencyAddress', {
		is: true,
		then: lookAHeadSchema('Performer Ad Agency'),
		otherwise: schema.object().nullable(),
	}),
	performerAdAgencyAddressId: schema.object().when('isPaymentAtPerformerAgencyAddress', {
		is: true,
		then: lookAHeadSchema('Performer Ad Agency Address'),
		otherwise: schema.object().nullable(),
	}),
});

export const SagAftraSdAudComSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	// workDays: workDaysDetailSchema,
	adAgencyId: lookAHeadSchema('Ad Agency'),
	performerId: lookAHeadSchema('Performer'),
	commercials: commercialsDetailSchema,
	serviceState: getRequiredSchema(schema.string(), 'Service State'),
	serviceCity: getRequiredSchema(schema.string(), 'Service City'),
	compensation: getRequiredSchema(schema.string(), 'Compensation'),
	// producerCompanyId: lookAHeadSchema('Producer'),
	performerAddressId: schema.object().when('isPaymentAtPerformerAddress', {
		is: true,
		then: lookAHeadSchema('Performer Address'),
		otherwise: schema.object().nullable(),
	}),
	performerAdAgencyId: schema.object().when('isPaymentAtPerformerAgencyAddress', {
		is: true,
		then: lookAHeadSchema('Performer Ad Agency'),
		otherwise: schema.object().nullable(),
	}),
	performerAdAgencyAddressId: schema.object().when('isPaymentAtPerformerAgencyAddress', {
		is: true,
		then: lookAHeadSchema('Performer Ad Agency Address'),
		otherwise: schema.object().nullable(),
	}),
});

export const SagAftraSdAcsAudComY22Schema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	jobTitle: getRequiredSchema(schema.string(), 'Job Title'),
	adAgencyId: lookAHeadSchema('Ad Agency'),
	performerId: lookAHeadSchema('Performer'),
	commercials: commercialsDetailSchema,
	advertiserId: lookAHeadSchema('Advertiser'),
	serviceState: getRequiredSchema(schema.string(), 'Service State'),
	serviceCity: getRequiredSchema(schema.string(), 'Service City'),
	placeOfEngagement: getRequiredSchema(schema.string(), 'Place Of Engagement'),
	compensation: getRequiredSchema(schema.string(), 'Compensation'),
	dateAndHourEmployment: getRequiredSchema(schema.string(), 'Date And Hour Employment'),
	// producerCompanyId: lookAHeadSchema('Producer'),	
	// workDays: workDaysDetailSchema,
});

export const StandardSagAftraCOEDSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	// producerCompanyId: lookAHeadSchema('Producer'),
	performerId: lookAHeadSchema('Performer'),
});

export const StandardSagAftraTvPrincipalSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	adAgencyId: lookAHeadSchema('Ad Agency'),
	commercials: commercialsDetailSchema,
	performerId: lookAHeadSchema('Performer'),
	// producerCompanyId: lookAHeadSchema('Producer'),
});

export const SagAftraAudioComHTComSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	commercials: commercialsDetailSchema,
	performerId: lookAHeadSchema('Performer'),
	// producerCompanyId: lookAHeadSchema('Producer'),
});

export const SagAftraTvExtraHTSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	commercials: commercialsDetailSchema,
	performerId: lookAHeadSchema('Performer'),
	// producerCompanyId: lookAHeadSchema('Producer'),
});

export const StandardTeamSagAftraSdAudComSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	adAgencyId: lookAHeadSchema('Ad Agency'),
	commercials: commercialsDetailSchema,
	performerId: lookAHeadSchema('Performer'),
	// producerCompanyId: lookAHeadSchema('Producer'),
});

export const nonUnionPerformerSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	agencyJobTitle: getRequiredSchema(schema.string(), 'Agency Job'),
	adAgencyId: lookAHeadSchema('Ad Agency'),
	performerId: lookAHeadSchema('Performer'),
	performerCategory: getRequiredSchema(schema.string(),'Performer Category'),
	commercials: schema.array().of(
		schema.object({
			assetId: lookAHeadSchema('Asset-Id')
		})
	),
	advertiserId: lookAHeadSchema('Advertiser'),
	// contractPayrollVendorId: lookAHeadSchema('Payroll Vendor'),
	location: getRequiredSchema(schema.string(), 'location'),
	sessionState: getRequiredSchema(schema.string(), 'State'),
	sessionCity: getRequiredSchema(schema.string(), 'City'),
	sessionWorkDays: getRequiredSchema(schema.string(), 'Session Work Days'),
	sessionTravelDate: getRequiredSchema(schema.string(), 'Travel Date'),
	sessionFittingDate: getRequiredSchema(schema.string(), 'Fitting Date'),
	sessionFee: getRequiredSchema(schema.string(), 'Session Fee'),
	sessionFeeHour: getRequiredSchema(schema.string(), 'Session Fee Hour'),
	// date: getRequiredSchema(schema.string(), 'Date'),
	useFee: getRequiredSchema(schema.string(), 'Use Fee'),
	agentFee: getRequiredSchema(schema.string(), 'Agent Fee'),
	term: getRequiredSchema(schema.string(), 'Term'),
	allMediaUseRightDetail: getRequiredSchema(schema.string(), 'Media Use'),
	mediaTerritoryDetail: getRequiredSchema(schema.string(), 'Territory'),
	// mediaType: getRequiredSchema(schema.array(), 'Media Type'),
	mediaType: schema.array().of(schema.string()).min(1,'Media Type is Required').required('Media Type is Required')
	// placeOfEngagement: getRequiredSchema(schema.string(), 'Place Of Engagement'),
	// compensation: getRequiredSchema(schema.string(), 'Compensation'),
	// dateAndHourEmployment: getRequiredSchema(schema.string(), 'Date And Hour Employment'),
	// producerCompanyId: lookAHeadSchema('Producer'),	
	// workDays: workDaysDetailSchema,
});

export const nonUnionTalentAgreementExtraSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	agencyJobTitle: getRequiredSchema(schema.string(), 'Agency Job'),
	adAgencyId: lookAHeadSchema('Ad Agency'),
	performerId: lookAHeadSchema('Performer'),
	performerCategory: getRequiredSchema(schema.string(),'Performer Category'),
	commercials: schema.array().of(
		schema.object({
			assetId: lookAHeadSchema('Asset-Id')
		})
	),
	advertiserId: lookAHeadSchema('Advertiser'),
	location: getRequiredSchema(schema.string(), 'location'),
	sessionState: getRequiredSchema(schema.string(), 'State'),
	sessionCity: getRequiredSchema(schema.string(), 'City'),
	sessionWorkDays: getRequiredSchema(schema.string(), 'Session Work Days'),
	sessionTravelDate: getRequiredSchema(schema.string(), 'Travel Date'),
	sessionFittingDate: getRequiredSchema(schema.string(), 'Fitting Date'),
	sessionFee: getRequiredSchema(schema.string(), 'Session Fee'),
	sessionFeeHour: getRequiredSchema(schema.string(), 'Session Fee Hour'),
	useFee: getRequiredSchema(schema.string(), 'Use Fee'),
	agentFee: getRequiredSchema(schema.string(), 'Agent Fee'),
	term: getRequiredSchema(schema.string(), 'Term'),
	allMediaUseRightDetail: getRequiredSchema(schema.string(), 'Media Use'),
	mediaTerritoryDetail: getRequiredSchema(schema.string(), 'Territory'),
	mediaType: schema.array().of(schema.string()).min(1,'Media Type is Required').required('Media Type is Required')
});

type TGeneralSchema = { isWorkDay: boolean };
export const ContractFormGeneralSchema = ( includes?: TGeneralSchema) => schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	workDays: includes?.isWorkDay ? workDaysDetailSchema : schema.array().nullable(),
});

export const ContractStatusFrom = schema.object({
	status: getRequiredSchema(schema.string(), 'Status'),
});


const TalentRecord = schema.array().of(
	schema.object<any>({
		talentName: lookAHeadSchema('Talent Name'),
		// perfCategory: getRequiredSchema(schema.string(), 'PerfCategory'),
		// role: getRequiredSchema(schema.string(), 'Role'),
		// agent: lookAHeadSchema('Agent'),
		// rateOfPay: getRequiredSchema(schema.string(), 'RateOfPay'),
	})
);

export const TalentDetailsFormSchema = schema.object({
	talentRecords: TalentRecord,
});

export const expiryDateFormSchema = schema.object({
	expirationDate: schema.date().required('Expiry Date is required field').typeError('Expiry Date is required field'),
});

export const MainFormSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	payrollVendorId: lookAHeadSchema('Payroll Vendor'),
});

export const QuickFormSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	payrollVendorId: lookAHeadSchema('Payroll Vendor'),
	performer:getRequiredSchema(schema.string(),'Performer'),
	role:getRequiredSchema(schema.string(),'Role'),
	assetLength:schema.number().notRequired(),
});

export const BulkFormSchema = schema.object({
	contractDisplayName: getRequiredSchema(schema.string(), 'Contract Name'),
	payrollVendorId: lookAHeadSchema('Payroll Vendor'),
	talentRecords: schema.array().of(
		schema.object({
			role: getRequiredSchema(schema.string(),'role'),
			performer: getRequiredSchema(schema.string(),'performer'),
			assetLength: schema.number().notRequired().typeError('Asset Length should be of format MM:SS'),
		})
	)
});

export const BankTypesSchema = schema.object({
	userType:schema.string().required('UserType is Required'),
	bank:schema.string()
		.when('userType', {
			is: (val) => val != null && val !== '',
			then: schema.string().required('Bank is Required'),
			otherwise: schema.string().notRequired(),
		}),
});