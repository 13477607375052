import {
	getBaseUrl
} from '@/common/utils/utils';
import style from './addPerson.module.scss';
import AddIcon from '@mui/icons-material/Add';

interface Props {
	title: string;
}

const AddAssetInline = (props: Props) => {

	const baseURL = getBaseUrl(window.location.href);
	const handleCreateAgent = () => {
		const link = document.createElement('a');
		link.href = `${baseURL}/#/add/asset`;
		link.target = '_blank';
		link.click();
	};

	return (
		<>
			<div
				onClick={handleCreateAgent}
				className={style.addIcon}
			>
				<AddIcon/>{props.title ? props.title : 'Asset'}
			</div>
		</>
	);
};

export default AddAssetInline;
