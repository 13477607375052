import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { DateFormat } from '@/common/constants/constants';
import styles from './datepicker.module.scss';
import { debounce } from '@/common/utils/utils';

// eslint-disable-next-line react/display-name
export const NabuDatepicker = forwardRef((props: any, ref: any) => {
	const dataCy = props?.name?.split('.');
	const [value, setValue] = useState<any>('');
	const [open, setOpen] = useState(false);
	const [ticks, setTicks] = useState<any>();

	const formatDate = useMemo(
		() => (date: any) => {
			if (date) {
				return date.toLocaleDateString('en-US');
			}
			return DateFormat;
		},
		[]
	);

	useEffect(() => {
		if (props.value) {
			const date = new Date(props.value);
			const userTimezoneOffset = date.getTimezoneOffset() * 60000;

			if (!isNaN(date as any)) {
				setValue(new Date(date.getTime() + userTimezoneOffset) || '');
				setTicks(new Date().getTime());
			} else {
				setValue('');
				setTicks(new Date().getTime());
			}
		} else {
			setValue('');
			setTicks(new Date().getTime());
		}
	}, [props.value]);

	const handleChange = useMemo(() => (date: any) => {
		const userTimezoneOffset = date.getTimezoneOffset() * 60000;

		if (date && !isNaN(date)) {
			date.setHours(0, 0, 0, 0);
			setValue(date);
			props.onChange && props.onChange(new Date(date.getTime() - userTimezoneOffset).toISOString() || '');
			setOpen(false); // Close after date selection
			setTicks(new Date().getTime());
		} else {
			setValue('');
			props.onChange && props.onChange('');
			setTicks(new Date().getTime());
		}
	}, [props]);

	const handleInputChange = useMemo(() => (date) => {
		if (date && !isNaN(date)) {
			const inputYear = parseInt(date.getFullYear().toString().slice(-2));
			const currentYear = new Date().getFullYear();
			const currentCentury = Math.floor(currentYear / 100) * 100;
			let fullYear;

			if (props.isDOB) {
				if (inputYear <= currentYear % 100) {
					fullYear = currentCentury + inputYear;
				} else {
					fullYear = currentCentury - 100 + inputYear;
				}
			} else {
				const cutoffYear = (currentYear % 100) + (currentCentury - 100);
				if (inputYear > cutoffYear) {
					fullYear = currentCentury - 100 + inputYear;
				} else {
					fullYear = currentCentury + inputYear;
				}
			}

			const selectedDate: any = new Date(date);
			selectedDate.setFullYear(fullYear);

			if (!isNaN(selectedDate)) {
				handleChange(selectedDate);
			}
		} else {
			setValue('');
		}
	}, [handleChange, props]);

	const handleDebounceInputChange = useCallback(
		debounce(handleInputChange, 300),
		[handleInputChange]
	);

	const handleClear = useCallback(() => {
		setValue('');
		props.onChange && props.onChange('');
		setTicks(new Date().getTime());
	}, [props]);

	const handleOpen = useMemo(() => () => setOpen(true), []);
	const handleClose = useMemo(() => () => setOpen(false), []);
	const handleYearChange = useMemo(() => () => setOpen(true), []);

	return (
		<div data-cy={dataCy?.length ? `date_picker_${dataCy[dataCy.length - 1]}` : 'date_picker'}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					aria-label={props?.name}
					label={props.label}
					key={`${props?.value}-${ticks}`}
					{...props}
					onChange={handleChange}
					className={`${styles.datepicker} ${styles.datepicker_length}`}
					value={!(value || '')?.toString()?.trim() ? undefined : value}
					ref={ref}
					open={open}
					onOpen={handleOpen}
					onClose={handleClose}
					onYearChange={handleYearChange}
					slotProps={{
						field: { clearable: true, onClear: handleClear, onChange: handleDebounceInputChange },
					}}
					minDate={props.minDate ?? undefined}
					maxDate={props.maxDate ?? undefined}
					renderInput={(params: any) => (
						<TextField
							variant="standard"
							{...params}
							onClick={handleOpen}
							value={!(value || '')?.toString()?.trim() ? undefined : formatDate(value)}
						/>
					)}
				/>
			</LocalizationProvider>
		</div>
	);
});