import { FieldErrors, FieldValues } from 'react-hook-form';
import useNotification from './useNotification';
/**
 * Custom hook to scroll to a form field based on validation errors.
 *
 * @template TForm - The type of the form values.
 * @returns An object containing the scroll function.
 *
 * @example
 * const { scroll } = useScrollToField<MyFormValues>();
 * 	const onSubmitError = (data) => {
 * 	scroll(data);
 * 	//... rest of the code
 * 	};
 *
 * @function
 * @name scroll
 *
 * @param {FieldErrors<TForm>} errors - The object containing validation errors. The object keys are used for scrolling. 
 * It is necessary that the field must have the attribute of 'aria-label' or 'name' with the value of the object key.
 */
const useScrollToField = <TForm extends FieldValues>() => {
	const notification = useNotification();
	/**
	 * Function to scroll to the first field with validation error.
	 *
	 * @param {FieldErrors<TForm>} errors - The object containing validation errors. The object keys are used for scrolling. 
	 * It is necessary that the field must have the attribute of 'aria-label' or 'name' with the value of the object key.
	 * @returns {void}
	*/
	const scroll = (errors: FieldErrors<TForm>) => {
		const errorsArray = Object.keys(errors);
		if (errorsArray.length) {			
			let elementRef = document.getElementById(errorsArray[0]) as HTMLInputElement;
			
			if(!elementRef)	
				elementRef = elementRef ?? document.querySelectorAll<HTMLInputElement>(`[aria-label^="${errorsArray[0]}"]`)[0];
			
			if(!elementRef)	
				elementRef = elementRef ?? document.querySelectorAll<HTMLInputElement>(`[name^="${errorsArray[0]}"]`)[0];
			
			if(!elementRef)	
				elementRef = elementRef ?? document.querySelectorAll<HTMLInputElement>(`[aria-label="${errorsArray[0]}"]`)[0];
			
			if (elementRef) {
				elementRef.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				});
				elementRef.focus();
				elementRef.click();
			}
		}
	};

	/**
	 * Handles form submission errors by scrolling to the first error field and displaying a notification.
	 *
	 * @template TForm - The type of the form data.
	 * @param {FieldErrors<TForm>} errors - The errors object containing validation errors for the form fields.
	 */
	const onSubmitError = (errors: FieldErrors<TForm>) => {
		scroll(errors);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};
	return { scroll, onSubmitError };
};

export default useScrollToField;