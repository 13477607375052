import React, { forwardRef } from 'react';
import { Switch } from '@mui/joy';
import { FormControl, FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Switch as AntiSwith } from '@mui/material';

// eslint-disable-next-line react/display-name
export const NabuSwitch = forwardRef((props: any, ref: any) => {
	return (
		<>
			<FormControl required={props.required} error={props.error} sx={{ width: 240 }}>
				<FormLabel id="select-field-demo-label" htmlFor="select-field-demo-button">
					{props.label}
				</FormLabel>
				<Switch
					aria-label={props?.name}
					{...props}
					ref={ref}
					color={props.color}
					size={props.size}
					variant={props.variant}
				/>
			</FormControl>
		</>
	);
});

export const NabuAntSwitch = styled(AntiSwith)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
		boxSizing: 'border-box',
	},
}));