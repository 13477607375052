import { Chip, ChipProps } from '@mui/material';
import styles from './chip.module.scss';



export const NabuChip =(props: ChipProps) => {

	return (
		<>
			<Chip className={styles.chip} label={props.label} variant="outlined" {...props} />
		</>
	);
};